import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/Table';
import SimpleCard from '../../components/templates/SimpleCard';
import { orderActions } from '../../redux/actions/orderActions';
import toIDR from '../../utils/helpers/toIDR';
import styles from './detail.module.scss'

const DetailTab = ({ index }) => {
  const dispatch = useDispatch();
  const { key, item, title, totalAmount, totalFee } = useSelector(state => state.order.tabs[index]);

  const getDetail = useCallback(
    () => dispatch(orderActions.getDetail(key, index)),
    [dispatch, key, index]
  )

  useEffect(() => {
    getDetail()
  }, [getDetail])

  const columns = useMemo(() => ([
    {
      title: 'Bank', key: 'bank'
    },
    {
      title: 'No Rekening', key: 'bankAccount'
    },
    {
      title: 'Nama', key: 'fullName'
    },
    {
      title: 'Nominal (Rp)', key: 'amount', render: v => toIDR(v ?? 0)
    },
    {
      title: 'Email', key: 'email'
    },
    {
      title: 'Berita', key: 'description'
    },
    {
      title: 'Status', key: 'status'
    },
  ]), [])

  return !item ? null
    :
    <SimpleCard className="mb-24">
      <p className="font-500 mb-12">ID Transaksi: {title}</p>
      <hr />
      <Table
        data={item}
        config={{
          columns,
          withIndex: true,
          pagination: false,
          sort: key
        }}
        renderTotal={() => (
          <ul className={styles.total}>
            <li>
              Total Nominal: <b>{toIDR(totalAmount)}</b>
            </li>
            <li>
              Total Biaya: <b>{toIDR(totalFee)}</b>
            </li>
            <li>
              Total: <b>{toIDR(totalAmount + totalFee)}</b>
            </li>
          </ul>
        )}
      />
    </SimpleCard>
}

export default DetailTab
