import pakaiClass from 'pakai-class'
import React, { cloneElement, useState } from 'react'
import styles from "./badge.module.scss"
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper';

const RoundBadge = ({
  icon,
  title,
  variant,
  active,
  popperPlacement
}) => {
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const [showTooltip, setShowTooltip] = useState(false)
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: popperPlacement ?? 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ]
  });
  return (
    <div
      ref={setReferenceElement}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      className={pakaiClass(styles.root, active && styles.active, variant && styles[variant])}
    >
      <div className={styles.icon}>
        {icon && cloneElement(icon)}
      </div>
      {
        ReactDOM.createPortal(
          showTooltip &&
          <div
            className={styles.tooltipRoundBadge}
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}
          >
            {title}
          </div>,
          document.querySelector('body')
        )
      }
    </div>
  )
}

export default RoundBadge
