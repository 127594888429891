import axios from "axios";
import paginate from "../../utils/helpers/paginate";

export const REFUND_LIST_URL = "refund-transactions/list";
export const DETAIL_URL = id => `refund-transactions/${id}/detail`;
export const REQUEST_REFUND_URL = id => `refund-transactions/${id}/request`;
export const APPROVE_URL = id => `refund-transactions/${id}/approve`;
export const REJECT_URL = id => `refund-transactions/${id}/reject`;

const refundService = {
  list: ({ page, limit, sort }) => {
    let url = `${REFUND_LIST_URL}?${paginate(limit, page, sort)}`;
    return axios.get(url)
  },
  detail: (id) => axios.get(DETAIL_URL(id)),
  approve: (id) => axios.post(APPROVE_URL(id)),
  reject: (remark, id) => {
    let url = REJECT_URL(id);
    if (remark) {
      url += `?rejectReason=${remark}`;
    }
    return axios.post(url)
  },
  requestRefund: (id) => {
    return axios.post(REQUEST_REFUND_URL(id))
  }
}

export default refundService
