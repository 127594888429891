import axios from "axios";

export const LOGIN_URL = "admins/login";
export const ME_URL = "admins/me";
export const REFRESH_TOKEN_URL = "admins/refresh";
export const RESET_PASSWORD_URL = token => `admins/reset-password/${token}`;
export const CHECK_RESET_PASSWORD_URL = token => `admins/check-reset-password/${token}`;

const authService = {
  login: (data) =>
    axios.post(LOGIN_URL, data),
  me: () =>
    axios.get(ME_URL),
  resetPassword: (data, token) =>
    axios.post(RESET_PASSWORD_URL(token), data),
  refreshToken: (refreshToken) =>
    axios.post(REFRESH_TOKEN_URL, { refreshToken }),
  checkResetPassword: (token) => axios.get(CHECK_RESET_PASSWORD_URL(token)),
}

export default authService
