import React, { forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react';
import FormField from './FormField';
import styles from './forms.module.scss'
import Button from '../Button';
import { ClosedEye, Eye } from '../../assets/icons';
import { IMaskInput } from 'react-imask';
import FormField2 from './FormField2';
import pakaiClass from 'pakai-class';

const TextField = memo(forwardRef((
  {
    id,
    name,
    value,
    label,
    onChange,
    onFocus,
    onBlur,
    error,
    helperText,
    placeholder,
    multiline,
    autoComplete = "new-password",
    spellCheck = false,
    readOnly,
    disabled,
    row,
    className,
    inputProps,
    textareaProps,
    animate = true,
    password,
    format,
    imaskprops,
    variant,
    loading,
    tooltip,
    showTooltip,
    popperPlacement
  },
  ref
) => {
  const inputRef = useRef()
  const [focus, setFocus] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const componentInput = useMemo(() => {
    if (multiline) return <textarea
      {...textareaProps}
      id={id || name}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      readOnly={readOnly}
      autoComplete={autoComplete}
      spellCheck={spellCheck}
      onBlur={(e) => {
        setFocus(false)
        if (typeof onBlur === 'function') onBlur(e)
      }}
      onFocus={e => {
        setFocus(true)
        if (typeof onFocus === 'function') onFocus(e)
      }}
      ref={(el) => {
        inputRef.current = el;
        if (typeof ref === 'function') ref(el);
      }}
    />
    if (format) return <IMaskInput
      {...imaskprops}
      mask={format}
      value={value}
      unmask={true}
      onAccept={onChange}
      placeholder={placeholder}
      disabled={disabled}
      onBlur={(e) => {
        setFocus(false)
        if (typeof onBlur === 'function') onBlur(e)
      }}
      onFocus={e => {
        setFocus(true)
        if (typeof onFocus === 'function') onFocus(e)
      }}
      inputRef={el => inputRef.current = el}
    />
    return <input
      {...inputProps}
      onBlur={(e) => {
        setFocus(false)
        if (typeof onBlur === 'function') onBlur(e)
      }}
      onFocus={e => {
        setFocus(true)
        if (typeof onFocus === 'function') onFocus(e)
      }}
      id={id || name}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      readOnly={readOnly}
      autoComplete={autoComplete}
      spellCheck={spellCheck}
      type={password && !showPassword ? 'password' : 'text'}
      ref={(el) => {
        inputRef.current = el;
        if (typeof ref === 'function') ref(el);
      }}
    />
  }, [multiline, value, disabled, autoComplete, id, name, onBlur, onFocus, onChange, placeholder, readOnly, ref, spellCheck, textareaProps, inputProps, password, showPassword, format, imaskprops])

  const EyeIcon = useMemo(() => showPassword ? ClosedEye : Eye, [showPassword])

  const FormFieldTag = variant === 'outlined' ? FormField2 : FormField

  useEffect(() => {
    if (inputRef.current?.value) setIsFilled(true)
    else setIsFilled(false)
  }, [inputRef.current?.value])

  return (
    <FormFieldTag
      disabled={disabled}
      className={className}
      id={id || name}
      row={row}
      label={label}
      error={error}
      helperText={helperText}
      shrink={isFilled || value ? false : !focus}
      animate={animate}
      isPassword={password}
      onClickLabel={() => inputRef?.current?.focus()}
      loading={loading}
      tooltip={tooltip}
      showTooltip={typeof showTooltip === undefined ? focus : showTooltip}
      popperPlacement={popperPlacement}
    >
      <div className={styles.inputWrapper}>
        <div className={pakaiClass(styles.formControl, loading && styles.formControlLoading)}>
          {componentInput}
          {
            password &&
            <Button type="button" className={styles.eyeBtn} onClick={() => setShowPassword(!showPassword)}>
              <EyeIcon />
            </Button>
          }
        </div>
      </div>
    </FormFieldTag>
  )
}));

export default TextField;
