import React from 'react'

export const ChevronLeft = ({ className, size = 24 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      fillOpacity="1"
      d="M13.36 17a1 1 0 01-.72-.31l-3.86-4a1 1 0 010-1.4l4-4a1.004 1.004 0 111.42 1.42L10.9 12l3.18 3.3a1 1 0 010 1.41 1 1 0 01-.72.29z"
    ></path>
  </svg>
)

export const ChevronRight = ({ className, size = 24 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      fillOpacity="1"
      d="M10.5 17a1 1 0 01-.71-1.71L13.1 12 9.92 8.69a1 1 0 111.42-1.41l3.86 4a1 1 0 010 1.4l-4 4a1 1 0 01-.7.32z"
    ></path>
  </svg>
)

export const ChevronDown = ({ className, size = 24 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M7.482 10.002a1 1 0 011.71-.71l3.29 3.31 3.31-3.18a1 1 0 111.41 1.42l-4 3.86a1 1 0 01-1.4 0l-4-4a.999.999 0 01-.32-.7z"
    ></path>
  </svg>
)

export const Dot = ({ size = 2, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 2 2"
  >
    <circle cx="1" cy="1" r="1" fill="currentColor" fillOpacity="1"></circle>
  </svg>
)

export const LoadingDot = ({ size = 20, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 12 12"
  >
    <circle cx="6" cy="6" r="6" fill="currentColor"></circle>
  </svg>
)

export const Dashboard = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M.793.786a1 1 0 00-.293.707v11.015a1 1 0 001 1h8a1 1 0 001-1v-11a1 1 0 00-1-1l-8-.015a1 1 0 00-.707.293zM22.5.493l-8 .014a1 1 0 00-1 1v5a1 1 0 001 1h8a1 1 0 001-1V1.493a1 1 0 00-1-1zM10.207 23.214a1 1 0 00.293-.707v-5a1 1 0 00-1-1h-8a1 1 0 00-1 1v4.985a1 1 0 001 1l8 .014a1 1 0 00.707-.292zM22.5 10.508h-8a1 1 0 00-1 1v11a1 1 0 001 1l8-.014a1 1 0 001-1V11.508a1 1 0 00-1-1z"
    ></path>
  </svg>
)

export const Wallet = ({ size = 24, className }) => (
  <svg
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      fill="currentColor"
      d="M19.625 18.5a3.75 3.75 0 010-7.5h2.25a.25.25 0 00.25-.25v-2.5a2.5 2.5 0 00-2.5-2.5h-17a2.5 2.5 0 00-2.5 2.5v13a2.5 2.5 0 002.5 2.5h17a2.5 2.5 0 002.5-2.5v-2.5a.25.25 0 00-.25-.25h-2.25z"
    ></path>
    <path
      fill="currentColor"
      d="M22.625 12.5h-3a2.25 2.25 0 000 4.5h3a1.506 1.506 0 001.25-1.5V14a1.505 1.505 0 00-1.25-1.5zM19.812 1.176a1.22 1.22 0 00-1.5-.884L5.44 3.757a.25.25 0 00.065.491l14.76.022a.25.25 0 00.243-.311l-.696-2.783z"
    ></path>
  </svg>
)

export const Exit = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      fillOpacity="1"
      d="M17.508 18.5a1 1 0 00-1 1v.5a.5.5 0 01-.5.5h-5.5a.5.5 0 01-.5-.5V4a.5.5 0 01.5-.5h5.5a.5.5 0 01.5.5v1a1 1 0 102 0V2.5a1 1 0 00-1-1h-7.5v-1A.501.501 0 009.4.012l-9 2a.5.5 0 00-.392.488v19a.5.5 0 00.392.489l9 2a.506.506 0 00.559-.274.5.5 0 00.05-.216v-1h7.5a1 1 0 001-1v-2a1 1 0 00-1-.999zm-10-6a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
    ></path>
    <path
      fill="currentColor"
      fillOpacity="1"
      d="M23.546 11.668l-4.875-3.25a1 1 0 00-1.554.832V11h-4.125a1.5 1.5 0 100 3h4.125v1.75a1 1 0 001.554.832l4.875-3.25a1 1 0 000-1.664z"
    ></path>
  </svg>
)

export const KTP = ({ width = 32, height = 23, className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 32 23"
  >
    <path
      fill="currentColor"
      d="M32 2.595v17.297c0 .703-.257 1.31-.77 1.824-.514.514-1.122.77-1.825.77H2.595c-.694 0-1.3-.256-1.818-.77C.259 21.203 0 20.595 0 19.892V2.595C0 1.9.259 1.295.777.777S1.901 0 2.595 0h26.81c.703 0 1.311.259 1.825.777.513.518.77 1.124.77 1.818zM13.986 15.297c0-.585-.038-1.126-.114-1.621a6.609 6.609 0 00-.392-1.44c-.185-.463-.455-.828-.811-1.094-.356-.266-.786-.399-1.29-.399-.712.685-1.546 1.027-2.5 1.027-.992 0-1.83-.342-2.514-1.027-.505 0-.937.133-1.297.399-.36.266-.631.63-.811 1.095-.18.463-.309.943-.385 1.439a10.646 10.646 0 00-.115 1.621c0 .577.166 1.07.5 1.48.333.41.734.615 1.202.615h6.838c.469 0 .867-.205 1.196-.615.33-.41.493-.903.493-1.48zm-2.04-7.094c0-.856-.3-1.588-.899-2.196a2.934 2.934 0 00-2.169-.912c-.846 0-1.572.304-2.175.912a3.005 3.005 0 00-.906 2.196c0 .838.302 1.556.906 2.155.603.6 1.329.899 2.175.899.847 0 1.57-.3 2.17-.899.598-.599.898-1.317.898-2.155zm16.392 7.662v-1.027a.485.485 0 00-.156-.365.503.503 0 00-.358-.149H16.527a.503.503 0 00-.358.149.485.485 0 00-.155.365v1.027c0 .144.051.266.155.365.104.099.223.148.358.148h11.297c.135 0 .255-.05.358-.148.104-.1.156-.221.156-.365zm-6.135-4.095v-1.027a.485.485 0 00-.156-.365.503.503 0 00-.358-.148h-5.162a.503.503 0 00-.358.148.485.485 0 00-.155.365v1.027a.5.5 0 00.155.379c.104.099.223.148.358.148h5.162c.135 0 .255-.05.358-.148a.5.5 0 00.156-.379zm6.135 0v-1.027a.485.485 0 00-.156-.365.503.503 0 00-.358-.148H24.73a.485.485 0 00-.5.513v1.027c0 .153.047.28.142.379.094.099.214.148.358.148h3.094c.135 0 .255-.05.358-.148a.5.5 0 00.156-.379zm0-4.094V6.649a.485.485 0 00-.156-.365.503.503 0 00-.358-.149H16.527a.503.503 0 00-.358.149.485.485 0 00-.155.365v1.027c0 .144.051.268.155.371a.492.492 0 00.358.156h11.297a.492.492 0 00.358-.156.507.507 0 00.156-.371z"
    ></path>
  </svg>
)

export const Upload = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.6 20.4a1.2 1.2 0 011.2-1.2h14.4a1.2 1.2 0 010 2.4H4.8a1.2 1.2 0 01-1.2-1.2zM7.55 8.05a1.2 1.2 0 010-1.697l3.6-3.6a1.2 1.2 0 011.697 0l3.6 3.6a1.2 1.2 0 01-1.697 1.697L13.2 6.497V15.6a1.2 1.2 0 11-2.4 0V6.497L9.248 8.049a1.2 1.2 0 01-1.697 0z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export const Admins = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <g fill="currentColor" fillOpacity="1" clipPath="url(#clip0)">
      <path d="M18 14.5c-.908 0-1.804.208-2.62.608a.5.5 0 00-.205.711A8.933 8.933 0 0116.5 20.5a.5.5 0 00.5.5h6.5a.5.5 0 00.5-.5 6 6 0 00-6-6zM17.944 14a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5zM7.5 12.5a4.75 4.75 0 100-9.5 4.75 4.75 0 000 9.5zM15 20.5a7.5 7.5 0 00-15 0 .5.5 0 00.5.5h14a.5.5 0 00.5-.5z"></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H24V24H0z"></path>
      </clipPath>
    </defs>
  </svg>
)

export const Eye = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      fillOpacity="1"
      d="M3.948 13.318a1 1 0 01-1.896-.634c-.02.06 0-.002 0-.002.036-.11.077-.218.124-.324.08-.19.198-.452.36-.762.328-.62.844-1.446 1.6-2.272C5.67 7.654 8.176 6 12 6c3.826 0 6.332 1.654 7.862 3.324.817.896 1.48 1.921 1.96 3.034.04.094.07.17.09.226.008.016.028.12.048.22.018.092.034.18.04.196 0 0 .168.666-.684.948a1 1 0 01-1.264-.628v-.006l-.014-.032a7.356 7.356 0 00-.344-.752 8.95 8.95 0 00-1.306-1.854C17.168 9.346 15.174 8 12 8s-5.168 1.346-6.388 2.676a8.953 8.953 0 00-1.59 2.45 4.362 4.362 0 00-.06.156l-.014.036z"
    ></path>
    <path
      fill="currentColor"
      fillOpacity="1"
      d="M12 10a4 4 0 100 8 4 4 0 000-8zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
    ></path>
  </svg>
)

export const ClosedEye = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      fillOpacity="1"
      d="M3.948 8.682a1 1 0 00-1.896.636c.036.11.077.218.124.324.08.19.198.452.36.761.328.62.844 1.447 1.6 2.272C5.67 14.345 8.176 16 12 16c3.826 0 6.332-1.654 7.862-3.324a10.946 10.946 0 001.96-3.033c.04-.094.07-.17.09-.226.008-.016.028-.12.048-.22.018-.092.034-.18.04-.196 0 0 .168-.666-.684-.948a1 1 0 00-1.264.628v.006l-.014.032c-.1.256-.215.508-.344.752a8.95 8.95 0 01-1.306 1.853C17.168 12.653 15.174 14 12 14s-5.168-1.346-6.388-2.676a8.953 8.953 0 01-1.59-2.45 4.384 4.384 0 01-.06-.155l-.014-.036z">
    </path>
  </svg>
)

export const Checked = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1 1 0 111.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"
    ></path>
  </svg>
)

export const Close = ({ size = 12, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      fill="currentColor"
      d="M8.41 7l4.3-4.29a1.004 1.004 0 10-1.42-1.42L7 5.59l-4.29-4.3a1.004 1.004 0 00-1.42 1.42L5.59 7l-4.3 4.29a1 1 0 00.325 1.639 1 1 0 001.095-.219L7 8.41l4.29 4.3a1.001 1.001 0 001.639-.325 1 1 0 00-.22-1.095L8.41 7z"
    ></path>
  </svg>
);

export const Transaction = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#clip0)">
      <path
        fill="currentColor"
        d="M21.561 5.854L16.146.439A1.511 1.511 0 0015.086 0H3.5A1.5 1.5 0 002 1.5v21A1.5 1.5 0 003.5 24h18a.5.5 0 00.5-.5V6.914a1.49 1.49 0 00-.439-1.06zM9.817 11.183l-3.37-1.26A2.55 2.55 0 015 7.455 2.529 2.529 0 017.289 4.92a.25.25 0 00.211-.247V4a1 1 0 012 0v.625a.25.25 0 00.25.25H11a1 1 0 110 2H7.875C7 6.875 7 7.311 7 7.455a.9.9 0 00.183.612l3.37 1.26A2.55 2.55 0 0112 11.8a2.529 2.529 0 01-2.289 2.53.249.249 0 00-.211.247v.673a1 1 0 11-2 0v-.625a.25.25 0 00-.25-.25H6a1 1 0 010-2h3.125c.875 0 .875-.436.875-.58a.9.9 0 00-.183-.612zM18.5 14.75a.75.75 0 110 1.5h-5a.75.75 0 110-1.5h5zM14.75 11a.75.75 0 01.75-.75h3a.75.75 0 110 1.5h-3a.75.75 0 01-.75-.75zm3.75 8.25a.75.75 0 110 1.5h-10a.75.75 0 110-1.5h10z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H24V24H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export const Logs = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 384 512">
    <path d="M365.3 93.38l-74.63-74.64C278.6 6.742 262.3 0 245.4 0H64C28.65 0 0 28.65 0 64l.006 384c0 35.34 28.65 64 64 64H320c35.2 0 64-28.8 64-64V138.6c0-16.9-6.7-33.2-18.7-45.22zM336 448c0 8.836-7.164 16-16 16H64.02c-8.838 0-16-7.164-16-16L48 64.13c0-8.836 7.164-16 16-16h160V128c0 17.67 14.33 32 32 32h79.1v288zM96 280c0 13.3 10.8 24 24 24h144c13.3 0 24-10.7 24-24s-10.7-24-24-24H120c-13.2 0-24 10.8-24 24zm168 72H120c-13.2 0-24 10.8-24 24s10.75 24 24 24h144c13.25 0 24-10.75 24-24s-10.7-24-24-24z"></path>
  </svg>
);

export const Injection = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M256 417.13c-16.42 0-32.84-5.06-46.86-15.19L0 250.86V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V250.86L302.86 401.94c-14.02 10.12-30.44 15.19-46.86 15.19zm237.61-254.18c-8.85-6.94-17.24-13.47-29.61-22.81V96c0-26.51-21.49-48-48-48h-77.55c-3.04-2.2-5.87-4.26-9.04-6.56C312.6 29.18 279.2-.35 256 0c-23.2-.35-56.59 29.17-73.41 41.44-3.17 2.3-6 4.36-9.04 6.56H96c-26.51 0-48 21.49-48 48v44.14c-12.37 9.33-20.76 15.87-29.61 22.81A47.995 47.995 0 000 200.72v10.65l96 69.35V96h320v184.72l96-69.35v-10.65c0-14.74-6.78-28.67-18.39-37.77zM264 136h-16c-4.42 0-8 3.58-8 8v16.12c-23.62.63-42.67 20.55-42.67 45.07 0 19.97 12.98 37.81 31.58 43.39l45 13.5c5.16 1.55 8.77 6.78 8.77 12.73 0 7.27-5.3 13.19-11.8 13.19h-28.11c-4.56 0-8.96-1.29-12.82-3.72-3.24-2.03-7.36-1.91-10.13.73l-11.75 11.21c-3.53 3.37-3.33 9.21.57 12.14 9.1 6.83 20.08 10.77 31.37 11.35V336c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-16.12c23.62-.63 42.67-20.54 42.67-45.07 0-19.97-12.98-37.81-31.58-43.39l-45-13.5c-5.16-1.55-8.77-6.78-8.77-12.73 0-7.27 5.3-13.19 11.8-13.19h28.11c4.56 0 8.96 1.29 12.82 3.72 3.24 2.03 7.36 1.91 10.13-.73l11.75-11.21c3.53-3.37 3.33-9.21-.57-12.14-9.1-6.83-20.08-10.77-31.37-11.35V144c0-4.42-3.58-8-8-8z"
    ></path>
  </svg>
);

export const SendEmail = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#clip0)">
      <path
        fill="currentColor"
        d="M23.82 1.116a.5.5 0 00-.512-.078l-23 9.5a.5.5 0 00.01.927l6.332 2.46a.502.502 0 00.468-.057l8.4-5.974a.5.5 0 01.637.768l-7 6.745a.5.5 0 00-.153.36V22.5a.5.5 0 00.931.252l3.167-5.425a.25.25 0 01.337-.093l5.826 3.2a.5.5 0 00.73-.332l4-18.5a.508.508 0 00-.173-.486z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H24V24H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export const XLSX = ({ size = 32, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="currentColor"
      d="M27.887 11.73h-.72V8.256c0-.022-.004-.043-.006-.066a.578.578 0 00-.14-.38L21.236 1.2l-.004-.004a.577.577 0 00-.117-.098.618.618 0 00-.154-.072l-.032-.01A.586.586 0 0020.794 1H6.578c-.649 0-1.176.528-1.176 1.176v9.554h-.72c-.93 0-1.682.753-1.682 1.682v8.744c0 .928.753 1.682 1.682 1.682h.72v5.986c0 .648.527 1.176 1.176 1.176H25.99c.649 0 1.176-.528 1.176-1.176v-5.986h.72c.93 0 1.682-.753 1.682-1.682v-8.744a1.68 1.68 0 00-1.68-1.681zM6.578 2.177h13.627v6.02c0 .325.264.588.589.588h5.196v2.947H6.578V2.176zm11.918 16.04c-1.155-.402-1.91-1.041-1.91-2.053 0-1.188.991-2.096 2.633-2.096.784 0 1.363.165 1.775.351l-.35 1.27a3.312 3.312 0 00-1.456-.33c-.681 0-1.012.309-1.012.67 0 .444.393.64 1.29.98 1.229.455 1.807 1.095 1.807 2.076 0 1.166-.898 2.157-2.808 2.157-.795 0-1.58-.206-1.971-.423l.32-1.3a4.02 4.02 0 001.744.433c.723 0 1.104-.3 1.104-.753 0-.434-.33-.682-1.166-.981zm-2.662 1.6v1.322h-4.347V14.18h1.58v5.637h2.767zM6.44 21.139H4.644l2.013-3.52-1.94-3.438h1.806l.609 1.27c.206.423.361.763.526 1.156h.02c.166-.444.3-.754.475-1.156l.589-1.27h1.796l-1.962 3.396 2.065 3.562H8.825l-.63-1.26c-.258-.484-.423-.846-.62-1.249h-.02c-.145.403-.32.765-.537 1.25l-.578 1.26zm19.55 8.367H6.578v-5.667H25.99v5.667zm.01-8.367l-.63-1.26c-.257-.484-.422-.845-.619-1.248h-.02c-.144.403-.32.764-.537 1.249l-.578 1.26H21.82l2.013-3.522-1.941-3.437h1.806l.61 1.27c.206.423.36.763.526 1.156h.02c.166-.444.3-.754.475-1.156l.589-1.27h1.796l-1.961 3.396 2.064 3.562h-1.816z"
    ></path>
  </svg>
);

export const ProgrammingLaptop = ({ size = 32, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="currentColor"
      d="M18.621 25.108a.335.335 0 00.154-.437l-.827-1.794a2.978 2.978 0 011.459-3.944c1.016-.466 6.34.3 8 .555a.332.332 0 00.373-.41l-1.113-4.477V2.02a2 2 0 00-2-2H7.333a2 2 0 00-2 2v12.592l-1.12 4.477a.336.336 0 00.215.396.331.331 0 00.161.014c1.66-.267 6.956-1.054 8-.571a2.976 2.976 0 011.467 3.95l-.83 1.789a.335.335 0 00.153.437c.372.187.698.455.954.784a.335.335 0 00.267.133h2.8a.33.33 0 00.267-.133c.257-.327.583-.593.954-.78zM8.001 12.02v-8a1.333 1.333 0 011.332-1.333h13.334A1.333 1.333 0 0124 4.02v8a1.333 1.333 0 01-1.333 1.333H9.333A1.333 1.333 0 018 12.02z"
    ></path>
    <path
      fill="currentColor"
      d="M12.235 22.041a.977.977 0 00-.478-1.296c-2.053.096-4.1.3-6.133.612-.416-.013-1.067-.012-1.296.479l-2.72 4.622A3.252 3.252 0 003.2 30.773l1.972.908a3.254 3.254 0 003.127-.223c1.754-1.133 4.234-3.191 4.234-3.191a.782.782 0 00-.604-1.429l-1.6.735a.332.332 0 01-.467-.244.338.338 0 01.025-.199l2.348-5.089zM27.673 21.836c-.225-.49-.88-.492-1.296-.479a58.46 58.46 0 00-6.133-.612.977.977 0 00-.477 1.296l2.343 5.09a.336.336 0 01-.244.468.332.332 0 01-.198-.026l-1.6-.735a.782.782 0 00-.602 1.428s2.48 2.06 4.235 3.192a3.254 3.254 0 003.127.223l1.972-.908a3.252 3.252 0 001.6-4.315l-2.727-4.622z"
    ></path>
  </svg>
);

export const CheckedCircle = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#clip0)">
      <path
        fill="currentColor"
        d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.01 1.01 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1.001 1.001 0 111.61 1.183z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="currentColor" d="M0 0H24V24H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export const InfoCircle = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#clip0)">
      <path
        fill="currentColor"
        d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm.25 5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm2.25 13.5h-4a1 1 0 010-2h.75a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-.75a1 1 0 010-2h1a2 2 0 012 2v4.75a.25.25 0 00.25.25h.75a1 1 0 010 2z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H24V24H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export const Refresh = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M10.819 4.936a7.24 7.24 0 017.1 2.252 1.25 1.25 0 001.872-1.657A9.737 9.737 0 0010.243 2.5a10.269 10.269 0 00-7.365 7.11.249.249 0 01-.271.178l-1.033-.13a.49.49 0 00-.474.219.5.5 0 00-.019.526l2.476 4.342a.5.5 0 00.373.248h.062a.5.5 0 00.359-.152l3.477-3.593a.5.5 0 00-.3-.844l-1.878-.232a.25.25 0 01-.2-.333 7.7 7.7 0 015.369-4.903zM23.906 14.1a.501.501 0 00.015-.526l-2.5-4.33a.5.5 0 00-.796-.093l-3.456 3.614a.5.5 0 00.3.842l1.848.22a.249.249 0 01.219.223.254.254 0 01-.013.11 7.69 7.69 0 01-5.369 4.9 7.243 7.243 0 01-7.1-2.252 1.25 1.25 0 10-1.872 1.656 9.74 9.74 0 009.549 3.03 10.26 10.26 0 007.369-7.12.25.25 0 01.27-.18l1.058.128a.43.43 0 00.06 0 .5.5 0 00.418-.222z"
    ></path>
  </svg>
);

export const IosCheckMark = ({ size = 72, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 72 72"
  >
    <path
      fill="currentColor"
      d="M50.99 27.127l-2.474-2.546a.532.532 0 00-.394-.168.51.51 0 00-.394.168L30.572 41.864l-6.244-6.244a.555.555 0 00-.394-.168.555.555 0 00-.393.168l-2.504 2.503a.56.56 0 000 .802l7.875 7.875c.507.506 1.125.802 1.646.802.745 0 1.392-.549 1.631-.774h.014l18.802-18.9c.197-.239.197-.59-.015-.801z"
    ></path>
    <path
      fill="currentColor"
      d="M36 10.688c6.764 0 13.12 2.63 17.902 7.41 4.78 4.782 7.41 11.138 7.41 17.902 0 6.764-2.63 13.12-7.41 17.902-4.782 4.78-11.138 7.41-17.902 7.41-6.764 0-13.12-2.63-17.902-7.41-4.78-4.782-7.41-11.138-7.41-17.902 0-6.764 2.63-13.12 7.41-17.902 4.782-4.78 11.138-7.41 17.902-7.41zm0-3.938C19.842 6.75 6.75 19.842 6.75 36S19.842 65.25 36 65.25 65.25 52.158 65.25 36 52.158 6.75 36 6.75z"
    ></path>
  </svg>
);

export const Verification = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#clip0)">
      <path
        fill="currentColor"
        fillOpacity="1"
        d="M11.334 23.858c.357.19.805.189 1.161-.002C22.218 18.634 22.91 8.43 22.9 5.527a1.2 1.2 0 00-.723-1.096L12.408.104a1.22 1.22 0 00-.987 0l-9.7 4.328A1.201 1.201 0 001 5.515c-.04 2.887.54 13.114 10.333 18.343zM7.919 9.949l2.783 2.783 5.21-5.21 1.716 1.716-6.926 6.926-4.499-4.499L7.92 9.95z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H24V24H0z"></path>
      </clipPath>
    </defs>
  </svg>
)

export const User = ({ size = 32, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="currentColor"
      d="M16 16a5.332 5.332 0 005.333-5.333A5.332 5.332 0 0016 5.334a5.332 5.332 0 00-5.334 5.333A5.332 5.332 0 0016 16zm8.52 3.413c-2.24-1.146-5.147-2.08-8.52-2.08-3.374 0-6.28.934-8.52 2.08a3.96 3.96 0 00-2.147 3.547v2.373c0 .734.6 1.334 1.333 1.334h18.667c.733 0 1.333-.6 1.333-1.334V22.96c0-1.493-.813-2.867-2.146-3.547z"
    ></path>
  </svg>
)

export const Calendar = ({ size = 20, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="currentColor"
      d="M17.7 6c.2 0 .3.1.3.3V16c0 .2-.1.3-.3.3H2.3c-.2 0-.3-.1-.3-.3V6.3c0-.2.1-.3.3-.3h15.4zm0-2H2.3C1 4 0 5 0 6.3V16c0 1.3 1 2.3 2.3 2.3h15.4c1.3 0 2.3-1 2.3-2.3V6.3C20 5 19 4 17.7 4z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M4.7 2.8V5M15.3 2.8V5M4.4 8.3h4.3"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.2 9.3h-.9c-.3 0-.5-.2-.5-.5v-.9c0-.3.2-.5.5-.5h.9c.3 0 .5.2.5.5v.9c0 .3-.2.5-.5.5zM15.8 15.3h-.9c-.3 0-.5-.2-.5-.5v-.9c0-.3.2-.5.5-.5h.9c.3 0 .5.2.5.5v.9c0 .2-.3.5-.5.5zM15.8 9.3h-.9c-.3 0-.5-.2-.5-.5v-.9c0-.3.2-.5.5-.5h.9c.3 0 .5.2.5.5v.9c0 .3-.3.5-.5.5zM12.2 15.3h-.9c-.3 0-.5-.2-.5-.5v-.9c0-.3.2-.5.5-.5h.9c.3 0 .5.2.5.5v.9c0 .2-.2.5-.5.5zM8.6 15.3h-1c-.3 0-.5-.2-.5-.5v-.9c0-.3.2-.5.5-.5h.9c.3 0 .5.2.5.5v.9c.1.2-.1.5-.4.5z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export const Sync = ({ size = 30, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      fill="currentColor"
      d="M13.6 9.113a6.033 6.033 0 015.916 1.877 1.042 1.042 0 001.56-1.38 8.114 8.114 0 00-7.957-2.527 8.558 8.558 0 00-6.137 5.925.207.207 0 01-.226.149l-.861-.109a.41.41 0 00-.395.183.416.416 0 00-.016.438l2.063 3.619a.416.416 0 00.311.206.343.343 0 00.052 0 .416.416 0 00.3-.126l2.897-2.995a.416.416 0 00-.25-.703l-1.565-.193a.208.208 0 01-.167-.278 6.417 6.417 0 014.474-4.086zM24.505 16.75a.418.418 0 00.013-.438l-2.084-3.608a.415.415 0 00-.312-.204.406.406 0 00-.351.126l-2.88 3.012a.417.417 0 00.25.701l1.54.184a.208.208 0 01.172.278 6.407 6.407 0 01-4.475 4.083 6.036 6.036 0 01-5.916-1.877 1.042 1.042 0 10-1.56 1.38 8.116 8.116 0 007.957 2.525A8.551 8.551 0 0023 16.978a.21.21 0 01.225-.149l.882.106a.38.38 0 00.05 0 .417.417 0 00.348-.185z"
    ></path>
  </svg>
)

export const Edit = ({ size = 30, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      fill="currentColor"
      d="M16.614 17.5c-.16.16-.35.286-.56.37l-2.946 1.18a1.688 1.688 0 01-1.797-.368 1.667 1.667 0 01-.37-1.798l1.178-2.944c.084-.21.21-.4.37-.56l4.906-4.907a.209.209 0 00-.145-.356H7.807a2.598 2.598 0 00-2.599 2.598v11.47a2.598 2.598 0 002.599 2.598h11.47a2.597 2.597 0 002.598-2.597v-9.441a.208.208 0 00-.248-.204.21.21 0 00-.107.056L16.614 17.5z"
    ></path>
    <path
      fill="currentColor"
      d="M20.473 7.75a.428.428 0 00-.589 0l-6.51 6.512a.424.424 0 00-.093.14l-1.179 2.947a.417.417 0 00.093.45.432.432 0 00.45.091l2.945-1.178a.417.417 0 00.14-.093l6.512-6.51a.417.417 0 000-.59L20.473 7.75zM24.304 8.045a1.667 1.667 0 000-2.357 1.707 1.707 0 00-2.356 0l-.885.885a.442.442 0 00-.035.052.409.409 0 00-.087.242.417.417 0 00.122.295l1.77 1.767a.402.402 0 00.541.033.382.382 0 00.049-.033l.881-.884z"
    ></path>
  </svg>
)

export const Delete = ({ size = 30, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      fill="currentColor"
      d="M21.25 12.492H8.75a.417.417 0 00-.417.416v10.417A1.666 1.666 0 0010 24.992h10a1.666 1.666 0 001.667-1.667V12.908a.417.417 0 00-.417-.416zm-7.708 9.583a.625.625 0 01-1.25 0v-7.188a.625.625 0 111.25 0v7.188zm4.166 0a.625.625 0 01-1.25 0v-7.188a.625.625 0 111.25 0v7.188zM22.435 9.042A9.833 9.833 0 0019 8.15a4.12 4.12 0 00-8.006 0c-1.155.091-2.286.38-3.343.854a1.62 1.62 0 00-.984 1.393.833.833 0 00.833.845h15a.833.833 0 00.833-.821 1.562 1.562 0 00-.898-1.379zM14.98 6.675a2.403 2.403 0 012.186 1.307 37.23 37.23 0 00-4.334 0 2.362 2.362 0 012.148-1.307z"
    ></path>
  </svg>
)

export const Spinner = ({ size = 24, className, strokeWidth = 3.6 }) => (
  <svg
    className={className}
    viewBox="22 22 44 44"
    width={size}
    height={size}
  >
    <circle cx="44" cy="44" r="20.2" fill="none" strokeWidth={strokeWidth} stroke="currentColor" />
  </svg>
)

export const Texture = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path fill="currentColor" d="M19.51 3.08L3.08 19.51c.09.34.27.65.51.9.25.24.56.42.9.51L20.93 4.49c-.19-.69-.73-1.23-1.42-1.41zM11.88 3L3 11.88v2.83L14.71 3h-2.83zM5 3c-1.1 0-2 .9-2 2v2l4-4H5zm14 18c.55 0 1.05-.22 1.41-.59.37-.36.59-.86.59-1.41v-2l-4 4h2zm-9.71 0h2.83L21 12.12V9.29L9.29 21z"></path>
  </svg>
)

export const OpenInNew = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path fill="currentColor" d="M19 19H5V5h7V3H5a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
  </svg>
)

export const Bank = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M496 128v16a8 8 0 01-8 8h-24v12c0 6.627-5.373 12-12 12H60c-6.627 0-12-5.373-12-12v-12H24a8 8 0 01-8-8v-16a8 8 0 014.941-7.392l232-88a7.996 7.996 0 016.118 0l232 88A8 8 0 01496 128zm-24 304H40c-13.255 0-24 10.745-24 24v16a8 8 0 008 8h464a8 8 0 008-8v-16c0-13.255-10.745-24-24-24zM96 192v192H60c-6.627 0-12 5.373-12 12v20h416v-20c0-6.627-5.373-12-12-12h-36V192h-64v192h-64V192h-64v192h-64V192H96z"
    ></path>
  </svg >
)

export const Write = ({ size = 30, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 576 512"
  >
    <path
      fill="currentColor"
      d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
    ></path>
  </svg>
);

export const Minus = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path fill="currentColor" d="M19 13H5v-2h14v2z"></path>
  </svg>
)

export const Business = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path fill="currentColor" d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"></path>
  </svg>
)

export const DocumentChecked = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path fill="currentColor" d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"></path>
  </svg>
)

export const FormFilled = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path fill="currentColor" d="M14 10H2v2h12v-2zm0-4H2v2h12V6zM2 16h8v-2H2v2zm19.5-4.5L23 13l-6.99 7-4.51-4.5L13 14l3.01 3 5.49-5.5z"></path>
  </svg>
)

export const VerifiedUser = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path fill="currentColor" d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"></path>
  </svg>
)

export const FileDownload = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 384 512"
  >
    <path
      fill="currentColor"
      d="M348.5 232.1l-148 148.4c-4.7 4.7-12.3 4.7-17 0l-148-148.4c-4.7-4.7-4.7-12.3 0-17l19.6-19.6c4.8-4.8 12.5-4.7 17.1.2l93.7 97.1V44c0-6.6 5.4-12 12-12h28c6.6 0 12 5.4 12 12v248.8l93.7-97.1c4.7-4.8 12.4-4.9 17.1-.2l19.6 19.6c4.9 4.7 4.9 12.3.2 17zM372 428H12c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h360c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12z"
    ></path>
  </svg>
)

export const Times = ({ size = 24, className }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 320 512"
  >
    <path
      fill="currentColor"
      d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
    ></path>
  </svg>
)
