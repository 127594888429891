import React, { useEffect, useState } from 'react'
import MainButton from '../components/templates/MainButton'
import { toAbsoluteUrl } from '../utils/helpers/pathHelper'
import registerStyles from './Auth/auth.module.scss'
import layoutStyles from '../layout/layout.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router';
import { adminActions } from '../redux/actions/adminActions'
import adminService from '../axios/services/adminService'
import SimpleCard from '../components/templates/SimpleCard'

const VerificationAccount = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const { verifyAcc } = useSelector(state => state.admin);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const token = params.token;

    setIsLoading(true)
    if (token) {
      adminService.verify(token)
        .then(() => dispatch(adminActions.verifyAccount(token)))
        .catch(() => { })
        .finally(() => setIsLoading(false))
    }
    return () => null
  }, [dispatch, params])

  return (
    <div className={layoutStyles.authLayout}>
      {
        verifyAcc &&
        <SimpleCard loading={isLoading} className="mb-24">
          <p className="font-size-20 text-primary font-500">Akun Anda sudah terverifikasi!</p>
          <img className={registerStyles.successImg} src={toAbsoluteUrl('/assets/media/others/success.png')} alt="" />
          <p className={registerStyles.successText}>
            Silahkan masuk ke akun kamu,
           
          </p>
          <div className={registerStyles.formWrapper}>
            <div className={registerStyles.form}>
              <MainButton href='/login'>
                Masuk ke Beranda
              </MainButton>
            </div>
          </div>
        </SimpleCard>
      }
      {
        !verifyAcc &&
        <SimpleCard loading={isLoading}>
          <p className="font-size-20 text-danger font-500 mb-24">Token telah kedaluwarsa!</p>
          <p className={registerStyles.successText}>
            Silahkan masuk ke akun kamu
          </p>
          <div className={registerStyles.formWrapper}>
            <div className={registerStyles.form}>
              <MainButton href='/login'>
                Masuk ke Beranda
              </MainButton>
            </div>
          </div>
        </SimpleCard>
      }
    </div >
  )
}

export default VerificationAccount
