import React, { Children, cloneElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from "./tabs.module.scss";
import { Transition } from 'react-transition-group';
import { Close } from '../../assets/icons';
import LoadingSpinner from '../Loadings/LoadingSpinner';
import pakaiClass from 'pakai-class';

const duration = 100;

const defaultStyle = {
  transition: `transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0, transform: 'translateY(-5px)' },
  entered: { opacity: 1 },
  exiting: { opacity: 0, transform: 'translateY(5px)', position: 'absolute', top: 0, left: 0, width: '100%', height: 0 },
}

export const Tabs = ({ onClick, children, activeKey }) => {
  const nodeRef = useRef()
  const navRef = useRef();
  const [indicatorPosition, setIndicatorPosition] = useState(0)

  useEffect(() => {
    navRef.current.scroll({
      left: indicatorPosition,
      behavior: 'smooth'
    })
  }, [indicatorPosition])

  return (
    <div className={styles.tabsRoot}>
      <nav ref={navRef} className={styles.tabNav}>
        {Children.map(children, (child, i) =>
          child ?
            cloneElement(child, {
              index: i,
              onClick: child.props?.onClick ?? onClick,
              active: child.props?.active ?? activeKey === i,
              setIndicatorPosition: setIndicatorPosition
            })
            :
            null
        )}
      </nav>
      <div className={styles.tabContent}>
        {Children.map(children, (child, i) =>
          child ?
            <Transition
              nodeRef={nodeRef}
              in={child.props?.active ?? activeKey === i}
              timeout={duration}
              appear={true}
              unmountOnExit
            >
              {state =>
                <div
                  ref={nodeRef}
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}>
                  {child.props.children}
                </div>
              }
            </Transition>
            :
            null
        )}
      </div>
    </div>
  )
}

export const Tab = ({
  onClick,
  title,
  index,
  active,
  isLoading,
  onClose,
  setIndicatorPosition
}) => {
  const tabRef = useRef();

  useLayoutEffect(() => {
    if (active) {
      setIndicatorPosition(tabRef.current.offsetLeft - tabRef.current.parentElement.offsetLeft)
    }
  }, [active, setIndicatorPosition])

  return (
    <div
      ref={tabRef}
      className={pakaiClass(styles.tabWrapper, active && styles.active)}
    >
      <button
        onClick={() => onClick(index)}
        className={styles.tabTitle}
        title={title}
      >
        <p className={pakaiClass(typeof onClose === 'function' && styles.dynamic)}>
          {title}
        </p>
      </button>
      {
        typeof onClose === 'function' &&
        <button onClick={onClose} className={styles.tabAction}>
          <div className={pakaiClass(styles.loadingCloseWrapper, isLoading && styles.loading)}>
            {isLoading && <LoadingSpinner size={20} strokeWidth={2.6} />}
            <div className={styles.closeIcon}>
              <Close />
            </div>
          </div>
        </button>
      }
    </div>
  )
}
