import { put, takeLatest, call, delay } from "redux-saga/effects";
import auditLogService from "../../axios/services/auditLogService";
import { authActionTypes } from "../actions/authActions";
import { auditLogActions, auditLogTypes } from "../actions/auditLogActions";
import { endOfMonth, startOfMonth } from "date-fns";

const initialState = {
  activeTabKey: 0,
  tabs: [],
  items: [],
  tableConfig: {
    isLoading: false,
    totalData: 0,
    limit: 10,
    type: "AUDIT_LOG",
    sort: "time|desc",
    page: 1,
    filter: {
      fromDate: startOfMonth(new Date()),
      toDate: endOfMonth(new Date()),
    }
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case auditLogTypes.LOAD_REQUESTED: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          isLoading: true,
        }
      }
    }
    case auditLogTypes.LOADED: {
      return {
        ...state,
        items: action.payload.data.data,
        tableConfig: {
          ...state.tableConfig,
          totalData: action.payload.data.count,
          isLoading: false,
        }
      };
    }
    case auditLogTypes.SET_TABLE_CONFIG: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          [action.key]: action.value
        }
      };
    }
    case auditLogTypes.SET_TABLE_CONFIG_FILTER: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          filter: {
            ...state.tableConfig.filter,
            [action.key]: action.value
          },
          page: 1
        }
      };
    }
    case authActionTypes.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export function* saga() {
  yield takeLatest(auditLogTypes.LOAD_REQUESTED, function* loadData({ tableConfig }) {
    yield delay(300);
    try {
      const fromDate = tableConfig.filter.fromDate ? tableConfig.filter.fromDate : null;
      const toDate = tableConfig.filter.toDate ? tableConfig.filter.toDate : null;
      let data
      if (tableConfig.type === "AUDIT_LOG") {
        const { data: result } = yield call(auditLogService.list, {
          page: tableConfig.page,
          limit: tableConfig.limit,
          sort: tableConfig.sort,
          fromDate,
          toDate
        });
        data = result
      } else {
        const { data: result } = yield call(auditLogService.listError, {
          page: tableConfig.page,
          limit: tableConfig.limit,
          sort: tableConfig.sort,
          fromDate,
          toDate
        });
        data = result
      }
     
      yield put(auditLogActions.loaded(data));
    } catch (error) {
      // console.log('error reached', error);
    }
  });
}
