import React from "react";
import { ToastContainer } from 'react-toastify';
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import BaseRoutes from "./BaseRoutes";
// import Toast from "./components/Toast";
import 'react-toastify/dist/ReactToastify.css';

const App = ({ store, persistor, basename }) => {
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={'loading'}>
          <Router basename={basename}>
            <BaseRoutes />
            <ToastContainer
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover={false}
            />
          </Router >
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
