export const authActionTypes = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  REQUEST_USER: "REQUEST_USER",
  USER_LOADED: "USER_LOADED",
  REQUEST_REFRESH_TOKEN: "REQUEST_REFRESH_TOKEN",
  TOKEN_REFRESHED: "TOKEN_REFRESHED",
  STORE_API_REQUEST: "STORE_API_REQUEST",
  PENDING_API_REQUEST_EXECUTED: "PENDING_API_REQUEST_EXECUTED"
};

export const authActions = {
  login: (admin, accessToken, refreshToken) => ({ type: authActionTypes.LOGIN, admin, accessToken, refreshToken }),
  logout: () => ({ type: authActionTypes.LOGOUT }),
  requestUser: () => ({ type: authActionTypes.REQUEST_USER }),
  userLoaded: (admin) => ({ type: authActionTypes.USER_LOADED, admin }),
  requestRefreshToken: (refreshToken) => ({ type: authActionTypes.REQUEST_REFRESH_TOKEN, refreshToken }),
  tokenRefreshed: (user, accessToken, refreshToken) => ({ type: authActionTypes.TOKEN_REFRESHED, user, accessToken, refreshToken }),
  storeApiRequest: (config, resolve, reject) => ({ type: authActionTypes.STORE_API_REQUEST, apiRequest: { config, resolve, reject } }),
  pendingApiRequestExecuted: () => ({ type: authActionTypes.PENDING_API_REQUEST_EXECUTED })
};
