import pakaiClass from 'pakai-class'
import React from 'react'
import Button from '../Button'
import TextField from '../Forms/TextField'
import Modal, { ModalHead, ModalWrapper } from '../Modal'
import styles from './templates.module.scss'
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingDots from '../Loadings/LoadingDots'

const schema = yup.object().shape({
  rejectReason: yup.string().required('Remark tidak boleh kosong')
})

const RejectDialog = ({
  show,
  onClose,
  onCancel,
  onSubmit,
  modalTitle,
  loading
}) => {
  const { register, handleSubmit, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const { rejectReason } = watch(['rejectReason'])

  return (
    <Modal in={show} onClose={onClose}>
      <ModalWrapper className={styles.rejectDialog}>
        {
          loading &&
          <LoadingDots className={styles.loading} />
        }
        <ModalHead title={modalTitle} onClose={onClose} />
        <p className={styles.modalBody}>Apakah anda yakin menolak pengajuan ini? Tulis alasan singkat dibawah ini :</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="outlined"
            className="mb-12"
            name="rejectReason"
            ref={register}
          />
          <div className={styles.buttonWrapper}>
            <Button
              type="submit"
              className={pakaiClass(styles.confirmCancel, styles.confirm)}
              disabled={!Boolean(rejectReason)}
            >
              Tolak
            </Button>
            <Button
              type="button"
              className={pakaiClass(styles.confirmCancel, styles.cancel)}
              onClick={onCancel}
            >
              Batal
            </Button>
          </div>
        </form>
      </ModalWrapper>
    </Modal>
  )
}

export default RejectDialog
