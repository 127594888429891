class UserRoleTypes {
  static SUPERVISOR = 'SUPERVISOR';
  static OPERATOR = 'OPERATOR';
  static RETAIL = 'RETAIL';

  static getStr(en) {
    switch (en) {
      case UserRoleTypes.SUPERVISOR:
        return 'Manajer';
      case UserRoleTypes.OPERATOR:
        return 'Staf';
      case UserRoleTypes.RETAIL:
        return 'RETAIL';
      default:
        return 'Unknown';
    }
  }

  static getModel(en) {
    switch (en) {
      case UserRoleTypes.SUPERVISOR:
        return 'SUPERVISOR';
      case UserRoleTypes.OPERATOR:
        return 'OPERATOR';
      case UserRoleTypes.RETAIL:
        return 'RETAIL';
      default:
        return '';
    }
  }
}

export { UserRoleTypes };
