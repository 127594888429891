import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '../../components/Tabs/Tabs';
import { orderActions } from '../../redux/actions/orderActions';
import DetailTab from './DetailTab';
import MainTab from './MainTab';

const OrderPage = () => {
  const dispatch = useDispatch();
  const { activeTabKey, tabs } = useSelector(state => state.order);

  return (
    <div>
      <Tabs
        activeKey={activeTabKey}
        onClick={key => dispatch(orderActions.changeTab(key))}
      >
        <Tab title="Order">
          <MainTab />
        </Tab>
        {tabs.map((row, key) =>
          <Tab
            key={row.key}
            title={row.title}
            isLoading={row.loading}
            onClose={() => dispatch(orderActions.closeTab(row.key))}
            onClick={() => dispatch(orderActions.changeTab(row.key))}
            active={activeTabKey === row.key}
          >
            <DetailTab index={key} />
          </Tab>
        )}
      </Tabs>
    </div>
  )
}

export default OrderPage
