import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OpenInNew } from '../../assets/icons'
import Table from '../../components/Table'
import ButtonIcon from '../../components/templates/ButtonIcon'
import SimpleCard from '../../components/templates/SimpleCard'
import { adminActions } from '../../redux/actions/adminActions'
import templateStyles from '../../components/templates/templates.module.scss'
import TextField from '../../components/Forms/TextField'
// import { UserRoleTypes } from '../../utils/enums/userRoleTypes'
import { AdminRole } from '../../utils/enums/adminRoles'
import format from 'date-fns/format'
import AdminStatuses from './AdminStatuses'
import AdminStep from './AdminStep'

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    items,
    tableConfig: {
      isLoading,
      page,
      totalData,
      limit,
      sort,
      filter
    }
  } = useSelector(state => state.admin);

  const load = useCallback(
    () => {
      const tConfig = {
        limit,
        page,
        sort,
        filter
      }
      return dispatch(adminActions.loadRequested(tConfig))
    },
    [dispatch, limit, page, sort, filter],
  )

  const setTableConfig = useCallback(
    key => value => dispatch(adminActions.setTableConfig(key, value)),
    [dispatch]
  )
  const setTableConfigFilter = useCallback(
    key => value => dispatch(adminActions.setTableConfigFilter(key, value)),
    [dispatch]
  )

  useEffect(() => {
    load()
  }, [load])

  const columns = useMemo(() => ([
    {
      title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm:ss')
    },
    {
      title: 'Nama', key: 'fullName'
    },
    {
      title: 'No Handphone', key: 'phoneNumber'
    },
    {
      title: 'Email', key: 'email'
    },
    {
      title: 'Peran', key: 'role', render: v => AdminRole.getStr(v)
    },
    { title: 'Step', key: 'status', render: (v, row) => <AdminStep status={v} /> },
    { title: 'Status', key: 'status', render: (v, row) => <AdminStatuses status={v} /> },
    {
      title: '', key: '_id', render: (v, row) => (
        <div className={templateStyles.buttonGroup}>
          <ButtonIcon
            icon={<OpenInNew />}
            onClick={() => dispatch(adminActions.createTab(v, row.fullName))}
          />
        </div>
      )
    }
  ]), [dispatch]);

  const excelColumns = [
    { title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm:ss') },
    { title: 'Nama', key: 'fullName' },
    { title: 'No Handphone', key: 'phoneNumber' },
    { title: 'Email', key: 'email' },
    { title: 'Peran', key: 'role', render: v => AdminRole.getStr(v) },
    { title: 'Status', key: 'status', render: (v, row) => <AdminStatuses admin={row} forExcel /> },
  ];

  return (
    <div>
      <SimpleCard>
        <Table
          data={items}
          excel={{
            columns: excelColumns,
            filename: 'Table Admins',
            api: {
              apiResponseKey: "data",
              service: adminActions.loadRequested(limit, page, sort, filter),
            },
          }}
          onReload={load}
          onChangePage={setTableConfig('page')}
          onSort={setTableConfig('sort')}
          config={{
            loading: isLoading,
            columns,
            total: totalData,
            limit,
            sort,
            currentPage: page,
            showRender: (from, to, total) => `${from}-${to} dari ${total} User`
          }}
        >
          <div className="row mb-12">
            <div className="col-md-3">
              <TextField
                variant="outlined"
                label="Cari"
                placeholder="Ketik Sesuatu..."
                value={filter.search}
                onChange={e => setTableConfigFilter('search')(e.target.value)}
              />
            </div>
          </div>
        </Table>
      </SimpleCard>
    </div >
  )
}

export default MainTab
