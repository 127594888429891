import { put, takeLatest, call, delay } from "redux-saga/effects";
import transactionService from "../../axios/services/transactionService";
import { combineBy } from "../../utils/helpers/combineBy";
import { authActionTypes } from "../actions/authActions";
import { transactionActions, transactionTypes } from "../actions/transactionActions.js";

const initialState = {
  activeTabKey: 0,
  tabs: [],
  items: [],
  totalAmount: '',
  totalFee: '',
  tableConfig: {
    isLoading: false,
    totalData: 0,
    limit: 10,
    sort: "createdAt|desc",
    page: 1,
    filter: {
      search: '',
      bank: '',
      companyId: '',
    }
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case transactionTypes.LOAD_REQUESTED: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          isLoading: true,
        }
      }
    }
    case transactionTypes.LOADED: {
      return {
        ...state,
        items: action.payload.data.transactions,
        totalAmount: action.payload.data.totalAmount,
        totalFee: action.payload.data.totalFee,
        tableConfig: {
          ...state.tableConfig,
          totalData: action.payload.data.count,
          isLoading: false,
        }
      };
    }
    case transactionTypes.SET_TABLE_CONFIG: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          [action.key]: action.value
        }
      };
    }
    case transactionTypes.SET_TABLE_CONFIG_FILTER: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          filter: {
            ...state.tableConfig.filter,
            [action.key]: action.value
          },
          page: 1
        }
      };
    }
    case authActionTypes.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export function* saga() {
  yield takeLatest(transactionTypes.LOAD_REQUESTED, function* loadData({ tableConfig }) {
    yield delay(300);
    try {
      const search = combineBy([
        tableConfig.filter && typeof tableConfig.filter === 'object' && tableConfig.filter.status ? `status|${tableConfig.filter.status}` : null,
        tableConfig.filter.search ? 
        `bank|${tableConfig.filter.search},bankAccount|${tableConfig.filter.search},fullName|${tableConfig.filter.search},email|${tableConfig.filter.search},info|${tableConfig.filter.search},description|${tableConfig.filter.search}`
        : null
      ], ',');
      const bank = tableConfig.filter.bank;
      const companyId = tableConfig.filter.companyId;

      const { data } = yield call(transactionService.list, {
        page: tableConfig.page,
        limit: tableConfig.limit,
        sort: tableConfig.sort,
        search,
        bank,
        companyId,
      });
      yield put(transactionActions.loaded(data));
    } catch (error) {
      // console.log('error reached', error);
    }
  });
}
