import pakaiClass from 'pakai-class'
import React from 'react'
import { LoadingDot } from '../../assets/icons'
import styles from './loadings.module.scss'

const LoadingDots = ({
  className,
  size
}) => {
  return (
    <div className={pakaiClass(
      styles.loadingDotsRoot,
      className
    )}>
      <LoadingDot size={size} className={styles.dot} />
      <LoadingDot size={size} className={styles.dot} />
      <LoadingDot size={size} className={styles.dot} />
    </div>
  )
}

export default LoadingDots
