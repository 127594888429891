import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form'
import PopUp from '../../components/PopUp'
import SimpleCard from '../../components/templates/SimpleCard'
import forgotPasswordService from '../../axios/services/forgotPasswordService';
import TextField from '../../components/Forms/TextField';
import MainButton from '../../components/templates/MainButton';

const schema = yup.object().shape({
  email: yup.string().email("Format Email salah").required('Email tidak boleh kosong'),
})

function ForgotPassword({ show, setShow }) {
  const { register, handleSubmit, errors, setValue, unregister, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      email: ''
    }
  });
  const { email } = watch(["email"])

  const onSubmit = (values) => {
    const email = values.email
    forgotPasswordService
      .forgotPassword({ email })
      .then(() => {
        setShow(false)
      })
      .finally(() => { })
  }

  useEffect(() => {
    register('email')
    return () => {
      unregister('email')
    }
  }, [register, unregister])

  return (
    <PopUp
      in={show}
      onClose={() => setShow(false)}
    >
      <SimpleCard
        className='relative'
        title="Lupa Password"
      >
        <button
          onClick={() => setShow(false)}
          className='absolute right-0 mr-24'
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-12'>
            <p className='font-700'>Lupa Kata Sandi</p>
          </div>
          <br />
          <TextField
            className="mb-24"
            label="Masukan email"
            value={email}
            onChange={e => setValue('email', e.target.value, { shouldValidate: true })}
            error={errors.email?.message}
            helperText={errors.email?.message}
          />
          <p className='mb-12 text-dark-gray font-size-14'>Kami akan mengirimkan link untuk mengubah password ke alamat email anda</p>
          <br />
          <MainButton
            type="submit"
            className=""
          >
            Kirim
          </MainButton>
        </form>
      </SimpleCard>
    </PopUp>
  )
}

export default ForgotPassword
