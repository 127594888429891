import { format } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Delete, Edit } from '../../../assets/icons'
import Button from '../../../components/Button'
import Modal, { ModalWrapper } from '../../../components/Modal'
import Table from '../../../components/Table'
import ButtonIcon from '../../../components/templates/ButtonIcon'
import SimpleCard from '../../../components/templates/SimpleCard'
import templateStyles from '../../../components/templates/templates.module.scss'
import { depositTutorialsActions } from '../../../redux/actions/depositTutorialsActions'
import styles from './depositTutorials.module.scss'
import pakaiClass from 'pakai-class'
import depositTutorialsService from '../../../axios/services/depositTutorialsService'

const MainTab = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false)
  const [selectedId, setSelectedId] = useState(null)

  const {
    items,
    tableConfig: {
      isLoading,
      page,
      totalData,
      limit,
      sort,
      filter,
    }
  } = useSelector(state => state.depositTutorials);

  const load = useCallback(
    () => {
      const tConfig = {
        limit,
        page,
        sort,
        filter
      }
      return dispatch(depositTutorialsActions.loadRequested(tConfig))
    },
    [dispatch, limit, page, sort, filter],
  )

  const setTableConfig = useCallback(
    key => value => dispatch(depositTutorialsActions.setTableConfig(key, value)),
    [dispatch],
  )

  const deleteItem = () => {
    depositTutorialsService.delete(selectedId)
      .then(() => {
        setShow(false)
        load()
      })
      .catch(() => { })
  }

  useEffect(() => {
    load()
  }, [load])

  const columns = useMemo(() => {
    const showConfirmDelete = (id) => {
      setShow(true)
      setSelectedId(id);
    }

    return [
      { title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm') },
      { title: 'Nama', key: 'name', },
      { title: 'Virtual Akun', key: 'prefix', },
      { title: 'Minimum', key: 'minimum', },
      { title: 'Biaya', key: 'fee', },
      {
        title: '', key: '_id', render: (v, row) => (
          <div className={templateStyles.buttonGroup}>
            <ButtonIcon
              color="danger"
              icon={<Delete />}
              onClick={() => showConfirmDelete(v)}
            />
            <ButtonIcon
              color="edit"
              icon={<Edit />}
              onClick={() => dispatch(depositTutorialsActions.createTab(v, row.name))}
            />
          </div>
        )
      }
    ]
  }, [dispatch]);

  return (
    <div>
      <SimpleCard>
        <Table
          data={items}
          onReload={load}
          onChangePage={setTableConfig('page')}
          onSort={setTableConfig('sort')}
          config={{
            loading: isLoading,
            columns,
            total: totalData,
            limit,
            sort,
            currentPage: page,
            // showRender: (from, to, total) => `${from}-${to} dari ${total} Bank`
          }}
        ></Table>
        <Modal onClose={() => setShow(false)} in={show}>
          <ModalWrapper>
            <div className="text-center mb-12">
              <h3>Apakah anda yakin ingin menghapus item ini?</h3>
            </div>
            <div>
              <Button
                className={pakaiClass(styles.confirmButton, "mb-8")}
                onClick={deleteItem}
              >Hapus</Button>
              <Button
                className={styles.cancelButton}
                onClick={() => setShow(false)}
              >Batal</Button>
            </div>
          </ModalWrapper>
        </Modal>
      </SimpleCard>
    </div>
  )
}

export default MainTab