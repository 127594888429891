import { combineReducers } from 'redux'
import { all } from "redux-saga/effects";
import * as auth from "./auth";
import * as example from "./example";
import * as user from "./user";
import * as order from "./order";
import * as corporate from "./corporate";
import * as banks from "./banks";
import * as injection from "./injection";
import * as admin from "./admin";
import * as auditLog from "./auditLog";
import * as dttot from "./dttot";
import * as refund from "./refund";
import * as deduction from "./deduction";
import * as transaction from "./transaction";
import * as mutation from "./mutation";
import * as adminApproval from "./adminApproval";
import * as depositTutorials from "./depositTutorials";

export const reducers = combineReducers({
  auth: auth.reducer,
  example: example.reducer,
  user: user.reducer,
  order: order.reducer,
  corporate: corporate.reducer,
  banks: banks.reducer,
  injection: injection.reducer,
  admin: admin.reducer,
  auditLog: auditLog.reducer,
  dttot: dttot.reducer,
  refund: refund.reducer,
  deduction: deduction.reducer,
  transaction: transaction.reducer,
  mutation: mutation.reducer,
  adminApproval: adminApproval.reducer,
  depositTutorials: depositTutorials.reducer,
});

export function* sagas() {
  yield all([
    auth.saga(),
    example.saga(),
    user.saga(),
    order.saga(),
    corporate.saga(),
    banks.saga(),
    injection.saga(),
    admin.saga(),
    auditLog.saga(),
    dttot.saga(),
    refund.saga(),
    deduction.saga(),
    transaction.saga(),
    mutation.saga(),
    adminApproval.saga(),
    depositTutorials.saga(),
  ]);
}
