import React, { useEffect } from 'react'
import TextField from '../../components/Forms/TextField'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectField from '../../components/Forms/SelectField';
import { AdminRoleOpts } from '../../utils/enums/adminRoles';
import styles from './user.module.scss'
import MainButton from '../../components/templates/MainButton';
import { schemaChangePassword, schemaCreate, schemaUpdate } from './schema';

const Form = ({ onSubmit, initialValue, readOnly, type, textBtnSubmit }) => {
  const getSchema = () => {
    let schema = null
    if (type === 'create') schema = schemaCreate;
    if (type === 'update') schema = schemaUpdate;
    if (type === 'changePassword') schema = schemaChangePassword;
    return schema;
  }

  const { register, handleSubmit, watch, unregister, setValue, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getSchema()),
    defaultValues: initialValue,
  });

  const {
    fullName,
    phoneNumber,
    email,
    role,
    password,
    confirmPassword,
  } = watch(["fullName", "phoneNumber", "email", "role", "password", "confirmPassword"])

  useEffect(() => {
    register('role')

    return () => {
      unregister('role')
    }
  }, [register, unregister])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {
        type !== 'changePassword'
        &&
        <TextField
          className="mb-24"
          label="Nama Lengkap"
          ref={register}
          name="fullName"
          readOnly={readOnly}
          error={errors.fullName?.message}
          helperText={errors.fullName?.message}
        />
      }
      {
        type !== 'changePassword'
        &&
        <TextField
          className="mb-24"
          label="No Handphone"
          ref={register}
          name="phoneNumber"
          readOnly={readOnly}
          error={errors.phoneNumber?.message}
          helperText={errors.phoneNumber?.message}
        />
      }
      {
        type !== 'changePassword'
        &&
        <TextField
          className="mb-24"
          label="Email"
          ref={register}
          name="email"
          readOnly={readOnly}
          error={errors.email?.message}
          helperText={errors.email?.message}
        />
      }
      {
        type !== 'changePassword'
        &&
        <SelectField
          value={role}
          onChange={v => setValue('role', v?.value, { shouldValidate: true })}
          options={AdminRoleOpts}
          label="Peran"
          isDisabled={readOnly}
          className="mb-24"
        />
      }
      <div className={styles.btnWrapper}>
        <MainButton
          className={styles.mainButton}
          type="submit"
          disabled={
            !(
              fullName
                && phoneNumber
                && email
                && role
            )
            || readOnly
          }
        >
          {textBtnSubmit || 'Buat'}
        </MainButton>
      </div>
    </form>
  )
}

export default Form
