import pakaiClass from 'pakai-class'
import React from 'react'
import LoadingDots from '../Loadings/LoadingDots'
import styles from './templates.module.scss'

const SimpleCard = ({
  children,
  className,
  loading
}) => {
  return (
    <div className={pakaiClass(
      styles.simpleCard,
      className
    )}>
      {
        loading &&
        <LoadingDots className={styles.loading} />
      }
      {children}
    </div>
  )
}

export default SimpleCard
