const moduleKey = 'MUTATION';

export const mutationTypes = {
  LOAD_REQUESTED: moduleKey + "_LOAD_REQUESTED",
  LOADED: moduleKey + "_LOADED",
  SET_TABLE_CONFIG: moduleKey + "_SET_TABLE_CONFIG",
  SET_TABLE_CONFIG_FILTER: moduleKey + "_SET_TABLE_CONFIG_FILTER"
};

export const mutationActions = {
  loadRequested: (tableConfig) => ({ type: mutationTypes.LOAD_REQUESTED, tableConfig }),
  loaded: (data) => ({ type: mutationTypes.LOADED, payload: { data } }),
  setTableConfig: (key, value) => ({ type: mutationTypes.SET_TABLE_CONFIG, key, value }),
  setTableConfigFilter: (key, value) => ({ type: mutationTypes.SET_TABLE_CONFIG_FILTER, key, value })
};
