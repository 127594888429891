import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import depositTutorialsService from '../../../axios/services/depositTutorialsService';
import SimpleCard from '../../../components/templates/SimpleCard';
import { depositTutorialsActions } from '../../../redux/actions/depositTutorialsActions';
import Form from './Form';


const CreateTab = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = (values) => {
    const data = {
      ...values,
      logo: values.logo[0].name,
      tutorials: [
        ...values.tutorials
      ]
    }


    setIsLoading(true)
    depositTutorialsService.create(data)
      .then(() => dispatch(depositTutorialsActions.changeTab(0)))
      .catch(() => setIsLoading(false))
  }

  return (
    <SimpleCard loading={isLoading}>
      <p className="font-500 mb-12">Buat Deposit Tutorials</p>
      <hr />
      <Form onSubmit={onSubmit} />
    </SimpleCard>
  )
}

export default CreateTab
