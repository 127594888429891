import React from 'react'
import {
  faClipboardCheck,
  faShield,
  faListCheck,
  faMasksTheater
} from "@fortawesome/free-solid-svg-icons";
import RoundBadge from '../../components/Badge/RoundBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';

const CorporateStatuses = ({ status = {}, forExcel }) => {
  const getPresume = () => (
    forExcel
      ? (status?.presume ? <span>{status.presumeDescription}, </span> : <span>Tidak Diduga, </span>)
      :
      <RoundBadge
        title={status?.presume ? status.presumeDescription : 'Tidak Diduga'}
        active={status.verifiedAt || status.rejectReason}
        variant={status.verifiedAt ? "primary" : "danger"}
        icon={<FontAwesomeIcon icon={faMasksTheater} size="xs" />}
      />
  );

  return (
    <div className="d-flex align-items-center justify-content-center">
      {getPresume()}
    </div>
  )
}

export default CorporateStatuses;