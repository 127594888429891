import { endOfMonth, startOfMonth } from "date-fns";
import { put, takeLatest, call, delay } from "redux-saga/effects";
import mutationService from "../../axios/services/mutationService";
import { authActionTypes } from "../actions/authActions";
import { mutationActions, mutationTypes } from "../actions/mutationActions";

const initialState = {
  activeTabKey: 0,
  tabs: [],
  items: [],
  tableConfig: {
    isLoading: false,
    totalData: 0,
    limit: 10,
    sort: "createdAt|desc",
    page: 1,
    filter: {
      fromDate: startOfMonth(new Date()),
      toDate: endOfMonth(new Date()),
      type: '',
      companyId: ''
    }
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case mutationTypes.LOAD_REQUESTED: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          isLoading: true,
        }
      }
    }
    case mutationTypes.LOADED: {
      return {
        ...state,
        items: action.payload.data.transactions,
        tableConfig: {
          ...state.tableConfig,
          totalData: action.payload.data.count,
          isLoading: false,
        }
      };
    }
    case mutationTypes.SET_TABLE_CONFIG: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          [action.key]: action.value
        }
      };
    }
    case mutationTypes.SET_TABLE_CONFIG_FILTER: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          filter: {
            ...state.tableConfig.filter,
            [action.key]: action.value
          },
          page: 1
        }
      };
    }
    case authActionTypes.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export function* saga() {
  yield takeLatest(mutationTypes.LOAD_REQUESTED, function* loadData({ tableConfig }) {
    yield delay(300);
    try {
      const fromDate = tableConfig.filter.fromDate ? tableConfig.filter.fromDate : null;
      const toDate = tableConfig.filter.toDate ? tableConfig.filter.toDate : null;
      const type = tableConfig.filter.type;
      const companyId = tableConfig.filter.companyId;

      const { data } = yield call(mutationService.list, {
        page: tableConfig.page,
        limit: tableConfig.limit,
        sort: tableConfig.sort,
        fromDate,
        toDate,
        type,
        companyId
      });
      yield put(mutationActions.loaded(data));
    } catch (error) {
      // console.log('error reached', error);
    }
  });
}
