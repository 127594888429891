const moduleKey = 'REFUND';

export const refundTypes = {
  LOAD_REQUESTED: moduleKey + "_LOAD_REQUESTED",
  LOADED: moduleKey + "_LOADED",
  SET_TABLE_CONFIG: moduleKey + "_SET_TABLE_CONFIG",
  SET_TABLE_CONFIG_FILTER: moduleKey + "_SET_TABLE_CONFIG_FILTER",
  CHANGE_TAB: moduleKey + '_CHANGE_TAB',
  CLOSE_TAB: moduleKey + '_CLOSE_TAB',
  CREATE_TAB: moduleKey + '_CREATE_TAB',
  GET_DETAIL_REQUESTED: moduleKey + '_GET_DETAIL_REQUESTED',
  GET_DETAIL_LOADED: moduleKey + '_GET_DETAIL_LOADED'
};

export const refundActions = {
  loadRequested: (tableConfig) => ({ type: refundTypes.LOAD_REQUESTED, tableConfig }),
  loaded: (data) => ({ type: refundTypes.LOADED, payload: { data } }),
  setTableConfig: (key, value) => ({ type: refundTypes.SET_TABLE_CONFIG, key, value }),
  setTableConfigFilter: (key, value) => ({ type: refundTypes.SET_TABLE_CONFIG_FILTER, key, value }),
  changeTab: (key) => ({ type: refundTypes.CHANGE_TAB, key }),
  closeTab: (key) => ({ type: refundTypes.CLOSE_TAB, key }),
  createTab: (key, title) => ({ type: refundTypes.CREATE_TAB, key, title }),
  getDetail: (id, index) => ({ type: refundTypes.GET_DETAIL_REQUESTED, id, index }),
  getDetailLoaded: (data, index) => ({ type: refundTypes.GET_DETAIL_LOADED, payload: { data }, index })
};
