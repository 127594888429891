import pakaiClass from 'pakai-class'
import React from 'react'
import { FileDownload } from '../../assets/icons'
import styles from './templates.module.scss'

const FileCard = ({ filename, url, className, title }) => {
  return (
    <div className={pakaiClass(styles.fileCard, className)}>
      <a href={url} target="_blank" rel="noreferrer">
        <FileDownload size="14" />
        <span className="font-500 ml-6">{title}</span>
        <span className="ml-6">{filename}</span>
      </a>
    </div>
  )
}

export default FileCard
