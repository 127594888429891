import axios from "axios";

export const FORGOT_PASSWORD_URL = "admins/forgot-password";

const forgotPasswordService = {
  forgotPassword: (email) => {
    return axios.post(FORGOT_PASSWORD_URL, email)
  }
}

export default forgotPasswordService
