import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import bankService from '../../axios/services/bankService';
import SimpleCard from '../../components/templates/SimpleCard';
import { bankActions } from '../../redux/actions/bankActions';
import Form from './Form';


const UpdateTab = ({ index }) => {
  const dispatch = useDispatch()
  const { key, item } = useSelector(state => state.banks.tabs[index]);
  const [isLoading, setIsLoading] = useState(false)

  const getDetail = useCallback(
    () => dispatch(bankActions.getDetail(key, index)),
    [dispatch, key, index]
  )

  useEffect(() => {
    getDetail()
  }, [getDetail])

  const onSubmit = (values) => {
    const data = { ...values }
    if (values.logo) data.logo = values.logo[0]

    setIsLoading(true)
    bankService.update(data, key)
      .then(() => dispatch(bankActions.updateSuccess(index)))
      .catch(() => setIsLoading(false))
  }

  return (
    !item ? null
      :
      <SimpleCard loading={isLoading}>
        <p className="font-500 mb-12">Edit Bank</p>
        <hr />
        <Form
          onSubmit={onSubmit}
          initialValue={{
            ...item,
            logo: null
          }}
          bankId={key}
          previousImageUrl={item.logo?.url ?? ""}
        />
      </SimpleCard>
  )
}

export default UpdateTab
