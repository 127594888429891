import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';
import pakaiClass from 'pakai-class';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Times } from '../../assets/icons';
import adminApprovalService from '../../axios/services/adminApprovalService';
import Button from '../../components/Button';
import Modal, { ModalWrapper } from '../../components/Modal';
import StatusHistory from '../../components/StatusHistory';
import RejectDialog from '../../components/templates/RejectDialog';
import SimpleCard from '../../components/templates/SimpleCard';
import VerificationAction from '../../components/templates/VerificationAction';
import { adminApprovalActions } from '../../redux/actions/adminApprovalActions';
import AllStatus from '../../utils/enums/status';
import styles from './adminApproval.module.scss';

const DetailTab = ({ index }) => {
  const dispatch = useDispatch();
  const { key, item } = useSelector(state => state.adminApproval.tabs[index]);
  const [show, setShow] = useState(false);
  const [showDialog, setShowDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const getDetail = useCallback(
    () => dispatch(adminApprovalActions.getDetail(key, index)),
    [dispatch, key, index]
  )

  const approve = () => {
    setIsLoading(true)
    adminApprovalService.approve(key)
      .then(() => {
        dispatch(adminApprovalActions.closeTab(key))
      })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }

  const reject = (values) => {
    setIsLoading(true)
    adminApprovalService.reject(key, values)
      .then(() => {
        setShowDialog(false)
        getDetail()
      })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    getDetail()
  }, [getDetail])

  return !item ? null
    :
    <div className="row">
      <div className="col-md-6">
        <SimpleCard className="mb-24" >
          <p className="font-500 mb-12">Informasi Pengguna</p>
          <hr />
          <div className={styles.detail}>
            <div className={styles.field}>
              <span>Waktu Dibuat</span>
              <p>{format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm:ss')}</p>
            </div>
            <div className={styles.field}>
              <span>Terakhir Diubah Pada</span>
              <p>{format(new Date(item.updatedAt), 'yyyy-MM-dd HH:mm:ss')}</p>
            </div>
            <div className={styles.field}>
              <span>Kategori</span>
              <p>{item.data.category}</p>
            </div>
            <div className={styles.field}>
              <span>Status</span>
              <p>{AllStatus.getStrIdn(item.status)}</p>
            </div>
            <div className={styles.field}>
              <span>Deskripsi</span>
              <p>{item.description}</p>
            </div>
          </div>
        </SimpleCard>
        <SimpleCard>
          <p className="font-500 mb-12">Buka Pengguna Terblokir</p>
          <hr />
          {
            (item.status === "REJECTED" && item.rejectReason)
            &&
            <div>
              <div className="d-flex justify-content-between flex-wrap mb-16 font-size-14">
                <p className="mr-16 text-dark-gray">Alasan Penolakan :</p>
                <p>{item.rejectReason}</p>
              </div>
              <Button
                disabled
                className={pakaiClass(styles.verifyButton, styles.rejected)}
              >
                <Times />
                <span>Pengajuan ditolak</span>
              </Button>
            </div>
          }
          {
            item.status === AllStatus.PENDING
            &&
            <VerificationAction
              onApprove={() => setShow(true)}
              onReject={() => setShowDialog(true)}
            />
          }
          {
            item.status === AllStatus.APPROVED
            &&
            <Button
              disabled
              className={pakaiClass(styles.verifyButton, styles.approved)}
            >
              <FontAwesomeIcon className='mx-6' size="lg" icon={faCircleCheck} />
              <span>Pengajuan Disetujui</span>
            </Button>
          }
          <RejectDialog
            show={showDialog}
            onClose={() => setShowDialog(false)}
            onCancel={() => setShowDialog(false)}
            onSubmit={reject}
            modalTitle="Tolak Pengajuan User"
            loading={isLoading}
          />
          <Modal onClose={() => setShow(false)} in={show}>
            <ModalWrapper>
              <div className="text-center mb-12">
                <h3>Apakah anda yakin ingin membuka blokir pengguna ini?</h3>
              </div>
              <div>
                <Button
                  className={pakaiClass(styles.confirmButton, "mb-8")}
                  onClick={approve}
                >Ya</Button>
                <Button
                  className={styles.cancelButton}
                  onClick={() => setShow(false)}
                >Tidak</Button>
              </div>
            </ModalWrapper>
          </Modal>
        </SimpleCard>
      </div>
      <div className="col-md-6">
        <SimpleCard className="mb-24" >
          <p className="font-500 mb-12">Actor | Admin</p>
          <hr />
          <div className={styles.detail}>
            <div className={styles.field}>
              <span>Nama Lengkap</span>
              <p>{item?.actor?.fullName}</p>
            </div>
            <div className={styles.field}>
              <span>Email</span>
              <p>{item?.actor?.email}</p>
            </div>
            <div className={styles.field}>
              <span>Telepon</span>
              <p>{item?.actor?.phoneNumber}</p>
            </div>
            <div className={styles.field}>
              <span>Role</span>
              <p>{item?.actor?.role}</p>
            </div>
          </div>
        </SimpleCard>
        <StatusHistory
          statusHistories={item.statusHistories}
        />
      </div>
    </div>
}

export default DetailTab
