const moduleKey = 'TRANSACTIONS';

export const transactionTypes = {
  LOAD_REQUESTED: moduleKey + "_LOAD_REQUESTED",
  LOADED: moduleKey + "_LOADED",
  SET_TABLE_CONFIG: moduleKey + "_SET_TABLE_CONFIG",
  SET_TABLE_CONFIG_FILTER: moduleKey + "_SET_TABLE_CONFIG_FILTER"
};

export const transactionActions = {
  loadRequested: (tableConfig) => ({ type: transactionTypes.LOAD_REQUESTED, tableConfig }),
  loaded: (data) => ({ type: transactionTypes.LOADED, payload: { data } }),
  setTableConfig: (key, value) => ({ type: transactionTypes.SET_TABLE_CONFIG, key, value }),
  setTableConfigFilter: (key, value) => ({ type: transactionTypes.SET_TABLE_CONFIG_FILTER, key, value })
};
