import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import adminService from '../../axios/services/adminService';
import TextField from '../../components/Forms/TextField';
import StatusHistory from '../../components/StatusHistory';
import CollapseCard from '../../components/templates/CollapseCard';
import FileCard from '../../components/templates/FileCard';
import MainButton from '../../components/templates/MainButton';
import SimpleCard from '../../components/templates/SimpleCard';
import { StatusCard, StatusCardWrapper } from '../../components/templates/StatusCard';
import { adminActions } from '../../redux/actions/adminActions';
import { AdminRole } from '../../utils/enums/adminRoles';
import Form from './Form';
import styles from './user.module.scss'
import pakaiClass from 'pakai-class';
import axios from 'axios';

const DetailTab = ({ index }) => {
  const { admin } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { key, item } = useSelector(state => state.admin.tabs[index]);
  const [isLoading, setIsLoading] = useState(false)

  const getDetail = useCallback(
    () => dispatch(adminActions.getDetail(key, index)),
    [dispatch, key, index]
  )

  const getIsRoleHaveAccessToEdit = () => {
    let have = false;
    if (admin.role === AdminRole.SUPER_ADMIN) have = true;
    return have;
  }

  const onSubmit = (values) => {
    const data = { ...values }
    setIsLoading(true)
    adminService.update(data, item.id)
      .then(() => dispatch(adminActions.updateSuccess(index)))
      .catch(() => setIsLoading(false))
  }

  const onUpdate = () => {
    const data = { status: {active: !item.status.active}}
    setIsLoading(true)
    adminService.update(data, item.id)
      .then(() => dispatch(adminActions.updateSuccess(index)))
      .catch(() => setIsLoading(false))
  }

  const onSubmitChangePassword = (values) => {
    const data = { password: values.password }
    setIsLoading(true)
    adminService.update(data, item.id)
      .then(() => dispatch(adminActions.updateSuccess(index)))
      .catch(() => setIsLoading(false))
  }

  const generateNewPassword = async () => {
    console.log(item.id)
    const data = await axios.get("admins/generate-new-password/" + item.id)
    dispatch(adminActions.updateSuccess(index))
  }

  useEffect(() => {
    getDetail()

    return () => null
  }, [getDetail])

  return !item ? null
    :
    <div className="row">
      <div className="col-md-6">
        <SimpleCard className="mb-24">
          <p className="font-500 mb-12">Informasi Admin</p>
          <hr />
          <Form
            onSubmit={onSubmit}
            initialValue={{
              ...item,
            }}
            type="update"
            textBtnSubmit="Perbarui"
            readOnly={!getIsRoleHaveAccessToEdit()}
          />

          <div>
            {
              item.status.updatedAt &&
              <>
                <TextField
                  className="mb-24 mt-24"
                  readOnly
                  label="NIK"
                  value={item.identificationNumber}
                />
                <TextField
                  className="mb-24"
                  readOnly
                  label="Tempat Lahir"
                  value={item.placeOfBirth}
                />
                <TextField
                  className="mb-24"
                  readOnly
                  label="Tanggal Lahir"
                  value={format(new Date(item.dateOfBirth), 'd MMMM yyyy')}
                />
                <TextField
                  className="mb-24"
                  readOnly
                  label="Alamat Tempat Tinggal Terkini"
                  value={item.address}
                />
                <div className="mt-32">
                  <p className="font-500 mb-12">Berkas admin</p>
                  <hr />
                  <FileCard
                    className="mb-12"
                    title="Foto KTP"
                    filename={item.identificationArchive.idCard.file.name}
                    url={item.identificationArchive.idCard.file.url}
                  />
                  <FileCard
                    title="Foto Diri Dengan KTP"
                    filename={item.identificationArchive.selfPhoto.file.name}
                    url={item.identificationArchive.selfPhoto.file.url}
                  />
                </div>
              </>
            }
          </div>
          <p className="font-500 mb-12"></p>
          <MainButton
            className={pakaiClass([styles.mainButton, "mt-5"])}
            type="submit"
            onClick={()=>generateNewPassword()}
            disabled={false}
          >
            Kirim Password baru lewat email
          </MainButton>
        </SimpleCard>
        {/* <CollapseCard
          className=""
          renderHeader={
            <>
              <p>Ubah Kata Sandi</p>
              <hr />
            </>
          }
        >
          <div className="mb-24 mt-">
            <Form
              onSubmit={onSubmitChangePassword}
              initialValue={{
                ...item,
                password: null
              }}
              type="changePassword"
              textBtnSubmit="Ubah Kata Sandi"
              readOnly={!getIsRoleHaveAccessToEdit()}
            />
          </div>
        </CollapseCard> */}
      </div>
      <div className="col-md-6">
        <SimpleCard loading={isLoading} className="mb-24">
          <p className="font-500 mb-12">Status</p>
          <hr />
          <StatusCardWrapper>
            {/* <StatusCard
              title="Password Set"
              value={item.status.isPasswordSet}
            /> */}
            <StatusCard
              title="Aktif"
              value={item.status.active}
            />
            {/* <StatusCard
              title="Verified"
              value={item.status.verifiedAt}
            /> */}
          </StatusCardWrapper>
         <div style={{marginTop: "10px"}}>
         <MainButton
            className={styles.mainButton}
            type="submit"
            onClick={()=>onUpdate()}
            disabled={false}
          >
            {item.status.active ? 'Kunci admin': "Verifikasi Admin"}
          </MainButton>
         </div>
        </SimpleCard>
        <StatusHistory
          statusHistories={item.statusHistories}
        />
      </div>
    </div>
}

export default DetailTab
