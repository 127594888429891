import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../components/Table'
import SimpleCard from '../../components/templates/SimpleCard'
import { mutationActions } from '../../redux/actions/mutationActions'
import { format } from 'date-fns'
import toIDR from '../../utils/helpers/toIDR'
import DateField from '../../components/Forms/DateField'
import { TransactionOpts } from '../../utils/enums/transactionTypes'
import CompanySelect from '../../components/CompanySelect'
import SelectField from '../../components/Forms/SelectField'
import styles from './mutation.module.scss'
import idLocale from 'date-fns/locale/id'

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    items,
    tableConfig: {
      isLoading,
      page,
      totalData,
      limit,
      sort,
      filter
    }
  } = useSelector(state => state.mutation);

  const load = useCallback(
    () => {
      const tConfig = {
        limit,
        page,
        sort,
        filter
      }
      return dispatch(mutationActions.loadRequested(tConfig))
    },
    [dispatch, limit, page, sort, filter],
  )

  const setTableConfig = useCallback(
    key => value => dispatch(mutationActions.setTableConfig(key, value)),
    [dispatch]
  )

  const setTableConfigFilter = useCallback(
    key => value => dispatch(mutationActions.setTableConfigFilter(key, value)),
    [dispatch]
  )

  useEffect(() => {
    load()
  }, [load])

  const columns = useMemo(() => ([
    {
      title: 'Waktu Dibuat', key: 'createdAt', sortable: true, render: v => format(new Date(v), 'd MMM yyyy, HH:mm',  "PP", {
        locale: idLocale
    })
    },
    {
      title: 'Waktu Diperbaharui', key: 'updatedAt', sortable: true, render: v => format(new Date(v), 'd MMM yyyy, HH:mm', "PP", {
          locale: idLocale
      })
    },
    {
      title: 'Bank', key: 'bank'
    },
    {
      title: 'Nama', key: 'fullName'
    },
    {
      title: 'Type', key: 'type'
    },
    {
      title: 'Debit', key: 'amount', render: (v, row) => row.in_out === 'DEBIT' ? toIDR(v) : 0
    },
    {
      title: 'Kredit', key: 'amount', render: (v, row) => row.in_out === 'KREDIT' ? toIDR(v) : 0
    },
    {
      title: 'Saldo', key: 'balance', render: v => v ? toIDR(v) : 0
    },
    {
      title: 'Status', key: 'status'
    },
  ]), []);

  const excelColumns = [
    { title: 'Waktu Dibuat', key: 'createdAt', sortable: true, render: v => format(new Date(v), 'd MMM yyyy, HH:mm', "PP", {
      locale: idLocale
  })},
    { title: 'Bank', key: 'bank' },
    { title: 'Nama', key: 'fullName' },
    { title: 'Type', key: 'type' },
    { title: 'Debit', key: 'amount', render: (v, row) => row.in_out === 'DEBIT' ? toIDR(v) : 0 },
    { title: 'Kredit', key: 'amount', render: (v, row) => row.in_out === 'KREDIT' ? toIDR(v) : 0 },
    { title: 'Saldo', key: 'balance', render: v => v ? toIDR(v) : 0 },
    { title: 'Status', key: 'status' },
  ];

  const totalDebit = useMemo(() => {
    let debit = items.filter(v => v.in_out === "DEBIT");
    if (debit.length) return debit.reduce((a, b) => a + (b.amount + b.fee), 0);
    return 0
  }, [items]);

  const totalKredit = useMemo(() => {
    let kredit = items.filter(v => v.in_out === "KREDIT");
    if (kredit.length) return kredit.reduce((a, b) => a + (b.amount + b.fee), 0);
    return 0
  }, [items]);

  return (
    <div>
      <SimpleCard>
        <Table
          data={items}
          excel={{
            columns: excelColumns,
            filename: 'Table Mutation'
          }}
          onReload={load}
          onChangePage={setTableConfig('page')}
          onSort={setTableConfig('sort')}
          config={{
            loading: isLoading,
            columns,
            total: totalData,
            limit,
            sort,
            currentPage: page,
            showRender: (from, to, total) => `${from}-${to} dari ${total} Transaksi`
          }}
          renderTotal={() => (
            <ul className={styles.total}>
              <li>
                Total Debit: <b>{toIDR(totalDebit)}</b>
              </li>
              <li>
                Total Kredit: <b>{toIDR(totalKredit)}</b>
              </li>
            </ul>
          )}
        >
          <div className="row mb-12">
            <div className="col-md-3">
              <DateField
                variant="outlined"
                label="Dari Tanggal"
                onChange={e => setTableConfigFilter('fromDate')(e)}
                value={filter.fromDate}
              />
            </div>
            <div className="col-md-3">
              <DateField
                variant="outlined"
                label="Sampai Tanggal"
                value={filter.toDate}
                onChange={e => setTableConfigFilter('toDate')(e)}
              />
            </div>
            <div className="col-md-3">
              <SelectField
                variant="outlined"
                label="Filter Type"
                options={TransactionOpts}
                value={filter.type}
                onChange={v => setTableConfigFilter('type')(v?.value)}
                isClearable
              />
            </div>
            <div className="col-md-3">
              <CompanySelect
                variant="outlined"
                label="Filter Perusahaaan"
                value={filter.companyId}
                onChange={v => setTableConfigFilter('companyId')(v?.value)}
              />
            </div>
          </div>
        </Table>
      </SimpleCard>
    </div >
  )
}

export default MainTab
