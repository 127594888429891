import React, { forwardRef, useCallback, useState } from 'react'
import DatePicker from "react-datepicker";
import { Calendar } from '../../../assets/icons';
import Button from '../../Button';
import FormField from '../FormField';
import FormField2 from '../FormField2';
import styles from './dateField.module.scss'

const DateField = ({
  label,
  error,
  helperText,
  value,
  onChange,
  className,
  minDate,
  maxDate,
  onFocus,
  onBlur,
  dateFormat,
  variant
}) => {
  const [show, setShow] = useState(false)

  const handleChange = useCallback(
    (date) => {
      if (typeof onChange === 'function') onChange(date);
    },
    [onChange]
  )

  const FormFieldTag = variant === 'outlined' ? FormField2 : FormField

  return (
    <FormFieldTag
      className={className}
      label={label}
      error={error}
      helperText={helperText}
      shrink={value ? false : !show}
      animate
    >
      <DatePicker
        customInput={variant ? <CustomInput /> : null}
        onInputClick={() => setShow(true)}
        open={show}
        selected={value}
        onChange={handleChange}
        maxDate={maxDate}
        minDate={minDate}
        onBlur={e => {
          setShow(false)
          if (typeof onBlur === 'function') onBlur(e)
        }}
        onFocus={e => {
          setShow(true)
          if (typeof onFocus === 'function') onFocus(e)
        }}
        dateFormat={dateFormat ?? 'dd/MM/yyyy'}
        onSelect={() => setShow(false)}
        onClickOutside={() => setShow(false)}
      />
      {
        variant !== 'outlined' &&
        <Button type="button" className={styles.calendarBtn} onClick={() => setShow(true)}>
          <Calendar />
        </Button>
      }
    </FormFieldTag>
  )
}

const CustomInput = forwardRef(
  ({ value, onClick }, ref) => (
    <button type="button" className={styles.customInput} onClick={onClick} ref={ref}>
      <p>{value}</p>
      <Calendar />
    </button>
  ),
);

export default DateField
