import React from 'react'
import { Checked, Minus } from '../../assets/icons'
import styles from './templates.module.scss'

export const StatusCardWrapper = ({ children }) => {
  return (
    <div className={styles.statuses}>
      {children}
    </div>
  )
}

export const StatusCard = ({ title, value }) => (
  <div className={styles.status}>
    <p className={styles.title}>{title}</p>
    <div className={styles.iconWrapper}>
      {
        value ?
          <Checked className="text-primary" />
          :
          <Minus className="text-danger" />
      }
    </div>
  </div>
)
