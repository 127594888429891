import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import adminService from '../../axios/services/adminService';
import SimpleCard from '../../components/templates/SimpleCard';
import { adminActions } from '../../redux/actions/adminActions';
import Form from './Form';

const CreateTab = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (values) => {
    const data = {
      email: values.email,
      fullName: values.fullName,
      password: values.password,
      phoneNumber: values.phoneNumber,
      role: values.role
    }

    setIsLoading(true)
    adminService.create(data)
      .then(() => dispatch(adminActions.changeTab(0)))
      .catch(() => setIsLoading(false))
  }

  return (
    <SimpleCard loading={isLoading}>
      <p className="font-500 mb-12">Buat Admin Baru</p>
      <hr />
      <Form onSubmit={onSubmit} type='create' />
    </SimpleCard>
  )
}

export default CreateTab
