import axios from "axios";
import jsonToFormData from "../../utils/helpers/jsonToFormData";
import paginate from "../../utils/helpers/paginate";
export const LIST_URL = "banks/list";
export const CREATE_URL = "banks/create";
export const DETAIL_URL = id => `banks/${id}/detail`;
export const DELETE_URL = (id) => `banks/${id}/delete`;
export const UPDATE_URL = (id) => `banks/${id}/update`;
export const LIST_ARTAJASA_URL = "banks/artajasa-bank-type";

const bankService = {
  list: ({ page, limit, sort, search }) => {
    let url = `${LIST_URL}?${paginate(limit, page, sort)}`;
    if (search) {
      url += `&search=${search}`;
    }
    return axios.get(url)
  },
  detail: (id) => axios.get(DETAIL_URL(id)),
  create: (data) => {
    return axios.post(CREATE_URL, jsonToFormData(data))
  },
  update: (data, id) => {
    return axios.patch(UPDATE_URL(id), jsonToFormData(data))
  },
  delete: (id) => {
    return axios.post(DELETE_URL(id))
  },
  getArtajasa: () => axios.get(LIST_ARTAJASA_URL)
}

export default bankService
