import React, { useEffect, useCallback } from 'react';
import styles from './popUp.module.scss'
import ReactDOM from 'react-dom'
import { Transition } from 'react-transition-group';
import { ENTERED } from 'react-transition-group/Transition';

const PopUp = ({
  onClose,
  in: inProp,
  children,
  duration = 150
}) => {
  const body = document.body

  const handleClose = useCallback(() => {
    if (typeof onClose === 'function') onClose();
  }, [onClose])

  const onEscKeyDown = useCallback(e => {
    if (e.key !== "Escape") return;
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (inProp) window.addEventListener("keydown", onEscKeyDown, false);
    return () => {
      if (inProp) window.removeEventListener("keydown", onEscKeyDown, false);
    }
  }, [inProp, onEscKeyDown])

  useEffect(() => {
    if (inProp) body.classList.add('overflow-hidden')
    else body.classList.remove('overflow-hidden')
    return () => {
      if (inProp) body.classList.remove('overflow-hidden')
    }
  }, [body.classList, inProp])

  return ReactDOM.createPortal(
    <Transition
      in={inProp}
      timeout={duration}
      unmountOnExit={true}
      appear
    >
      {state => (
        <div className={styles.popUp}>
          <div className={styles.wrapper}
            style={{
              opacity: state === ENTERED ? 1 : 0,
              transition: `opacity ${duration}ms ease-in-out`
            }}
          >
            {children}
          </div>
          <div className={styles.backdrop} onClick={handleClose}
            style={{
              opacity: state === ENTERED ? .5 : 0,
              transition: `opacity ${duration}ms ease-in-out`
            }}
          />
        </div >
      )}
    </Transition>,
    body
  )
}

export default PopUp
