import axios from "axios";
import paginate from "../../utils/helpers/paginate";

export const MUTATION_LIST_URL = "transactions/mutation";

const mutationService = {
  list: ({ page, limit, sort, fromDate, toDate, type, companyId }) => {
    let url = `${MUTATION_LIST_URL}?${paginate(limit, page, sort)}`;
    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (companyId) {
      url += `&companyId=${companyId}`;
    }
    return axios.get(url)
  }
}

export default mutationService
