import React from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import MainLayout from "./MainLayout";
import VerificationAccount from "./views/VerificationAccount";
import AuthLayout from "./AuthLayout";
import Logout from "./views/Auth/Logout";
import UpdatePasswordSuccess from './views/UpdatePasswordSuccess'

export default function BaseRoutes() {
  const { admin, authToken } = useSelector(state => state.auth);

  return (
    <>
      <Switch>
        <Route exact path="/update-password-success" component={UpdatePasswordSuccess} />
        <Route exact path="/email/verify/:token" component={VerificationAccount} />
        <Route exact path="/logout" component={Logout} />
        {(!authToken || (authToken && admin)) &&
          <Route>
            {
              admin
                ?
                <MainLayout />
                :
                <AuthLayout />
            }
          </Route>
        }
      </Switch>
    </>
  );
}
