class OrderStatusTypes {
  static PENDING = 'PENDING';
  static PROCESSING = 'PROCESSING';
  static COMPLETED = 'COMPLETED';
  static CANCELED = 'CANCELED';
  static getStr(en) {
    switch (en) {
      case "PENDING":
        return 'Menunggu Persetujuan';
      case "PROCESSING":
        return 'Sedang Diproses';
      case "COMPLETED":
        return 'Selesai';
      case "CANCELED":
        return 'Dibatalkan';
      default:
        return '';
    }
  }
}
const OrderStatusOpts = [
  { value: OrderStatusTypes.PENDING, label: OrderStatusTypes.getStr(OrderStatusTypes.PENDING) },
  { value: OrderStatusTypes.PROCESSING, label: OrderStatusTypes.getStr(OrderStatusTypes.PROCESSING) },
  { value: OrderStatusTypes.COMPLETED, label: OrderStatusTypes.getStr(OrderStatusTypes.COMPLETED) },
  { value: OrderStatusTypes.CANCELED, label: OrderStatusTypes.getStr(OrderStatusTypes.CANCELED) }
];
export { OrderStatusTypes, OrderStatusOpts };
