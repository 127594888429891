import React, { useState } from 'react'
import TextField from '../../../components/Forms/TextField'
import MainButton from '../../../components/templates/MainButton'
import authStyles from '../auth.module.scss'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { passwordRegex } from '../../../utils/enums/regexTypes';
import useWindowSize from '../../../components/hooks/useWindowSize'
import { useHistory, useLocation } from 'react-router'
import authService from '../../../axios/services/authService'

const schema = yup.object().shape({
  password: yup.string().matches(passwordRegex, 'Kata sandi 8 digit kombinasi huruf & angka').required('Kata sandi tidak boleh kosong'),
  passwordConfirmation: yup.mixed().test(
    "matchPassword",
    "Kata sandi tidak sama",
    function (value) {
      if (!this.parent.password) return true
      return value === this.parent.password
    }
  ),
})

const NewPassword = () => {
  const query = new URLSearchParams(useLocation().search)
  const token = query.get("token");
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();

  const { width } = useWindowSize()
  const { register, handleSubmit, errors, watch, formState: { isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });
  const { password, passwordConfirmation } = watch(["password", "passwordConfirmation"])

  const onSubmit = (values) => {
    setLoading(true);
    authService.resetPassword(values, token)
      .then(() => {
        push('/update-password-success')
      })
      .catch(() => { })
      .finally(() => setLoading(false))
  }

  // const checkResetPassword = useCallback(() => {
  //   authService.checkResetPassword(token)
  //     .catch(() => push('/update-password-success'))
  //     .finally(() => setLoading(false))
  // }, [push, token])

  // useEffect(() => {
  //   checkResetPassword()
  // }, [checkResetPassword])

  return (
    <div>
      <h1 className="mb-16">Buat kata sandi baru akun Anda</h1>
      <p className="text-dark-gray">Silahkan buat kata sandi baru untuk akun anda dengan minimal 8 karakter.</p>
      <div className={authStyles.formWrapper}>
        <form onSubmit={handleSubmit(onSubmit)} className={`${authStyles.form} ${authStyles.registerForm}`}>
          <TextField
            password
            className="mb-24"
            ref={register}
            name="password"
            label="Kata Sandi"
            tooltip={(
              <>
                <p className="mb-12">Password harus memiliki :</p>
                <ul className={authStyles.listPassword}>
                  <li>Menggunakan huruf besar</li>
                  <li>Menggunakan huruf kecil</li>
                  <li>Menggunakan perpaduan angka</li>
                  <li>Menggunakan karakter khusus (!, ?, #, %)</li>
                </ul>
              </>
            )}
            popperPlacement={width <= 992 ? 'bottom-start' : 'right-start'}
            showTooltip={errors.password}
            error={errors.password}
          />
          <TextField
            password
            className="mb-32"
            ref={register}
            name="passwordConfirmation"
            label="Ulangi Kata Sandi"
            error={errors.passwordConfirmation?.message}
            helperText={errors.passwordConfirmation?.message}
          />
          <MainButton
            type="submit"
            disabled={!(passwordConfirmation && password && isValid) || loading}
          >
            Kirim
          </MainButton>
        </form>
      </div>
    </div>
  )
}

export default NewPassword
