import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import styles from './ripple.module.scss';


const useDebouncedRippleCleanUp = (rippleCount, cleanUpFunction) => {
  useEffect(() => {
    let bounce = null;
    if (rippleCount > 0) {
      clearTimeout(bounce);

      bounce = setTimeout(() => {
        cleanUpFunction();
        clearTimeout(bounce);
      }, 400);
    }

    return () => clearTimeout(bounce);
  }, [rippleCount, cleanUpFunction]);
};

const Ripple = forwardRef((_, ref) => {
  const [rippleArray, setRippleArray] = useState([]);

  useDebouncedRippleCleanUp(rippleArray.length, () => {
    setRippleArray([]);
  });


  useImperativeHandle(
    ref,
    () => ({
      addRipple(event) {
        const rippleContainer = event.currentTarget.getBoundingClientRect();
        const size =
          rippleContainer.width > rippleContainer.height
            ? rippleContainer.width
            : rippleContainer.height;
        const x = event.clientX - rippleContainer.x - size / 2;
        const y = event.clientY - rippleContainer.y - size / 2;
        const newRipple = {
          x,
          y,
          size
        };
        setRippleArray([...rippleArray, newRipple]);
      }
    })
  )

  return (
    <div className={styles.rippleRoot}>
      {rippleArray.length > 0 &&
        rippleArray.map((ripple, index) => {
          return (
            <span
              key={"span" + index}
              style={{
                top: ripple.y,
                left: ripple.x,
                width: ripple.size,
                height: ripple.size
              }}
            />
          );
        })}
    </div>
  );
});

export default Ripple;
