import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, takeEvery, select, call, delay } from "redux-saga/effects";
import { REHYDRATE } from 'redux-persist/lib/constants';
import { authActionTypes, authActions } from "../actions/authActions";
import authService from "../../axios/services/authService";
import axios from "axios";

const initialState = {
  admin: undefined,
  authToken: undefined,
  refreshToken: undefined
};

export const reducer = persistReducer(
  { storage, key: "reducer-auth", whitelist: ["authToken", "refreshToken"] },
  (state = initialState, action) => {
    switch (action.type) {
      case authActionTypes.LOGIN: {
        return {
          ...state,
          authToken: action.accessToken,
          refreshToken: action.refreshToken,
          admin: action.admin
        };
      }
      case authActionTypes.LOGOUT: {
        return initialState;
      }
      case authActionTypes.USER_LOADED: {
        return {
          ...state,
          admin: action.admin
        };
      }
      default:
        return state;
    }
  }
);

export function* saga() {
  // Automatically Request User when token retrieved by redux-persist from Storage
  yield takeLatest(REHYDRATE, function* rehydrateSaga({ payload: { authToken } }) {
    // console.log(payload)
    if (authToken) yield put(authActions.requestUser());
  });

  yield takeLatest(authActionTypes.REQUEST_USER, function* getUser() {
    yield delay(300)
    try {
      const { data: { admin } } = yield call(authService.me);
      yield put(authActions.userLoaded(admin));
    } catch (error) {
      // console.log('error reached', error);
    }
  });

  yield takeLatest(authActionTypes.REQUEST_REFRESH_TOKEN, function* refreshToken({ refreshToken }) {
    yield delay(300)
    try {
      // const tes = yield call(authService.refreshToken, refreshToken);

      // yield put(authActions.tokenRefreshed(partner, accessToken, newRefreshToken));
    } catch (error) {
      yield put(authActions.logout());
    }
  })

  yield takeEvery(authActionTypes.TOKEN_REFRESHED, function* recallPendingApiRequest() {
    const { pendingApiRequests } = yield select(state => state.auth);

    pendingApiRequests.forEach(({ config, resolve, reject }) => axios(config).then(resolve).catch(reject))

    yield put(authActions.pendingApiRequestExecuted())
  })
}
