import { put, takeLatest, call, delay, takeEvery } from "redux-saga/effects";
import adminApprovalService from "../../axios/services/adminApprovalService";
import { closeTab, createTab } from "../../utils/helpers/tabHelper";
import { authActionTypes } from "../actions/authActions";
import { adminApprovalActions, adminApprovalTypes } from "../actions/adminApprovalActions";

const initialState = {
  activeTabKey: 0,
  tabs: [],
  items: [],
  verifyAcc: false,
  tableConfig: {
    isLoading: false,
    totalData: 0,
    limit: 10,
    sort: "createdAt|desc",
    page: 1,
    filter: {
    }
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case adminApprovalTypes.LOAD_REQUESTED: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          isLoading: true,
        }
      }
    }
    case adminApprovalTypes.LOADED: {
      return {
        ...state,
        items: action.payload.data.userApprovals,
        tableConfig: {
          ...state.tableConfig,
          totalData: action.payload.data.count,
          isLoading: false,
        }
      };
    }
    case adminApprovalTypes.SET_TABLE_CONFIG: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          [action.key]: action.value
        }
      };
    }
    case adminApprovalTypes.SET_TABLE_CONFIG_FILTER: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          filter: {
            ...state.tableConfig.filter,
            [action.key]: action.value
          },
          page: 1
        }
      };
    }
    case adminApprovalTypes.CHANGE_TAB:
      return {
        ...state,
        activeTabKey: action.key,
      }
    case adminApprovalTypes.CLOSE_TAB: {
      const { newTabs, nextKey } = closeTab(state.tabs, action.key, state.activeTabKey);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: nextKey
      }
    }
    case adminApprovalTypes.CREATE_TAB: {
      const newTabs = createTab(state.tabs, action.key, action.title);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: action.key
      }
    }
    case adminApprovalTypes.GET_DETAIL_REQUESTED: {
      const clonedTabs = [...state.tabs];
      if (clonedTabs[action.index]) clonedTabs[action.index].loading = true;
      return {
        ...state,
        tabs: clonedTabs
      };
    }
    case adminApprovalTypes.GET_DETAIL_LOADED: {
      const clonedTabs = [...state.tabs];
      if (clonedTabs[action.index]) {
        clonedTabs[action.index].loading = false;
        clonedTabs[action.index].item = action.payload.data.approval;
      }
      return {
        ...state,
        tabs: clonedTabs
      };
    }
    case adminApprovalTypes.VERIFY_ACCOUNT: {
      return {
        ...state,
        verifyAcc: true
      }
    }
    case authActionTypes.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export function* saga() {
  yield takeLatest(adminApprovalTypes.LOAD_REQUESTED, function* loadData({ tableConfig }) {
    yield delay(300);
    try {
      const { data } = yield call(adminApprovalService.list, {
        page: tableConfig.page,
        limit: tableConfig.limit,
        sort: tableConfig.sort
      });
      yield put(adminApprovalActions.loaded(data));
    } catch (error) {
      // console.log('error reached', error);
    }
  });

  yield takeEvery(adminApprovalTypes.GET_DETAIL_REQUESTED, function* getDetail({ id, index }) {
    yield delay(300);
    try {
      const { data } = yield call(adminApprovalService.detail, id);
      yield put(adminApprovalActions.getDetailLoaded(data, index));
    } catch (error) {
      // console.log('error reached', error);
    }
  });
}
