import pakaiClass from "pakai-class";
import React, { useEffect, useState } from "react";
import styles from './tooltip.module.scss';

export default function Tooltip({ direction, show, children, content }) {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, 500);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  useEffect(() => {
    if (show) setActive(false);

    return () => null;
  }, [show]);

  return (
    <div
      className={styles.tooltipWrapper}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      onClick={hideTip}
    >
      {children}
      {active && (
        <div className={pakaiClass(styles.content, direction && styles[direction])}>
          {content}
        </div>
      )}
    </div>
  );
}
