import pakaiClass from 'pakai-class'
import React from 'react'
import Button from '../Button'
import styles from './templates.module.scss'

const MainButton = ({
  children,
  className,
  color = 'primary',
  ...props
}) => {
  return (
    <Button
      {...props}
      className={pakaiClass(styles.mainButton, color && styles[color], className)}
    >
      {children}
    </Button>
  )
}

export default MainButton
