import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import pakaiClass from 'pakai-class';
import styles from './table.module.scss';

const Reload = ({ onReload, className }) => {
  return (
    <button
      className={pakaiClass(styles.reload, 'mx-12 rounded-sm')}
      onClick={onReload}
    >
      <FontAwesomeIcon icon={faSyncAlt} />
    </button>
  )
}

export default Reload
