import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pakaiClass from 'pakai-class';
import React, { useRef, useState, Children, cloneElement } from 'react'
import { Link } from 'react-router-dom'
import { checkIsActive } from '../../utils/helpers/routerHelpers';
import styles from './sidebar.module.scss'

export const MenuList = ({ children }) => {
  return (
    <ul className={styles.menuNav}>
      {children}
    </ul>
  );
}

export const Li = ({ location, route, setOpen, index, isSublist }) => {
  const navRef = useRef()

  return (
    <li
      className={styles.menuItem}
      key={index}
    >
      <Link
        onClick={() => setOpen(false)}
        className={pakaiClass(
          styles.menuLink,
          checkIsActive(location, route.url) && styles.active
        )}
        to={route.url}
        ref={navRef}
      >
        <div className={styles.titleWrapper}>
          {isSublist ?? route.icon}
          {route.title}
        </div>
      </Link>
    </li>
  )
}

export const GroupMenuList = ({ location, route, children }) => {
  const [expand, setExpand] = useState(false);
  const navRef = useRef()
  const listGroupRef = useRef();

  return (
    <li
      className={styles.menuItem}
    >
      <div
        className={pakaiClass(
          styles.menuLink,
          checkIsActive(location, route.url) && styles.active
        )}
        onClick={() => setExpand(!expand)}
        ref={navRef}
      >
        <div className={styles.titleWrapper}>
          {route.icon}
          {route.title}
        </div>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={pakaiClass(styles.chevron, expand && styles.active)}
        />
      </div>
      <div className={styles.listGroup}>
        <div
          className={styles.listGroupWrapper}
          style={{ marginTop: expand ? 10 : 0 }}
        >
          <div
            className={styles.line}
            style={{ height: expand && listGroupRef.current ? listGroupRef.current.scrollHeight : 0 }}
          ></div>
          <ul
            className={styles.menuGroup}
            ref={listGroupRef}
            style={{ height: expand && listGroupRef.current ? listGroupRef.current.scrollHeight : 0 }}
          >
            {Children.map(children, (child, i) =>
              child ?
                cloneElement(child, {
                  index: i,
                  location: child.props?.location,
                  route: child.props?.route,
                  setOpen: child.props?.setOpen,
                  isSublist: Boolean(children)
                })
                :
                null
            )}
          </ul>
        </div>
      </div>
    </li>
  )
}
