import React from 'react'
import MainButton from '../components/templates/MainButton'
import { toAbsoluteUrl } from '../utils/helpers/pathHelper'
import registerStyles from './Auth/auth.module.scss'
import layoutStyles from '../layout/layout.module.scss'

const UpdatePasswordSuccess = () => {
  return (
    <div className={layoutStyles.authLayout}>
      <div>
        <p className="font-size-20 text-primary font-500">Sandi Baru Anda sudah terbuat!</p>
        <img className={registerStyles.successImg} src={toAbsoluteUrl('/assets/media/others/success.png')} alt="" />
        <p className={registerStyles.successText}>
          Silahkan masuk ke akun kamu menggunakan kata sandi baru
        </p>
        <div className={registerStyles.formWrapper}>
          <div className={registerStyles.form}>
            <MainButton href="/login">
              Kembali ke Login
            </MainButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdatePasswordSuccess
