import pakaiClass from 'pakai-class'
import React, { cloneElement } from 'react'
import Button from '../Button'
import styles from './templates.module.scss'

const ButtonIcon = ({
  icon,
  className,
  color,
  ...props
}) => {
  return (
    <Button
      {...props}
      className={pakaiClass(styles.buttonIcon, color && styles[color], className)}
    >
      {icon && cloneElement(icon, {
        size: 18
      })}
    </Button>
  )
}

export default ButtonIcon
