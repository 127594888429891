import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { authActions } from "../../redux/actions/authActions";

const Logout = () => {
  const dispatch = useDispatch();
  const { admin } = useSelector(state => state.auth)

  useEffect(() => {
    dispatch(authActions.logout())
  }, [dispatch])

  return admin ? 'loading' : <Redirect to="/login" />;
}

export default Logout
