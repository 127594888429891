export const IMAGE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
];

export const PDF_FORMATS = [
  'application/pdf'
]

export const XLS_FORMAT = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
]
