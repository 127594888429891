import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../../components/Table'
import SimpleCard from '../../../components/templates/SimpleCard'
import { userActions } from '../../../redux/actions/userActions'
import UserStatuses from '../../User/UserStatuses'
import UserStepStatus from '../../User/StepStatus'
import { UserRoleTypes } from '../../../utils/enums/userRoleTypes'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const UserList = ({ index, item }) => {
  const dispatch = useDispatch();
  const {
    items,
    tableConfig: {
      isLoading,
      page,
      totalData,
      limit,
      sort,
      filter
    }
  } = useSelector(state => state.user);

  const load = useCallback(
    () => {
      const tConfig = {
        limit,
        page,
        filter: {
          ...filter,
          companyId: index
        }
      }
      return dispatch(userActions.loadRequested(tConfig))
    },
    [dispatch, limit, page, filter, index],
  )

  const setTableConfig = useCallback(
    key => value => dispatch(userActions.setTableConfig(key, value)),
    [dispatch]
  )

  useEffect(() => {
    load()
  }, [load])



  const columns = useMemo(() => ([
    { title: 'Nama', key: 'fullName' },
    { title: 'No Handphone', key: 'phoneNumber' },
    { title: 'Email', key: 'email' },
    { title: 'Peran', key: 'role', render: v => UserRoleTypes.getStr(v) },
    { title: 'Step',  render: (v, row) => <UserStatuses status={v} /> },
    { title: 'Status', render: (v, row) => <UserStepStatus status={v} /> },
    { title: 'Detail', render: (v, row) => <Link to={`/user/list?triger=true&data=${JSON.stringify(v)}`}>Detail</Link>}
  ]), []);

  const excelColumns = [
    { title: 'Nama', key: 'fullName' },
    { title: 'No Handphone', key: 'phoneNumber' },
    { title: 'Email', key: 'email' },
    { title: 'Peran', key: 'role', render: v => UserRoleTypes.getStr(v) },
    { title: 'Status', key: 'status', render: (v, row) => <UserStatuses status={v} forExcel /> }
  ];

  return (
    <div>
      <SimpleCard>
        <Table
          data={items}
          excel={{
            columns: excelColumns,
            filename: `Daftar User Perushaan ${item?.company?.name}`
          }}
          onReload={load}
          onChangePage={setTableConfig('page')}
          onSort={setTableConfig('sort')}
          config={{
            loading: isLoading,
            columns,
            total: totalData,
            limit,
            sort,
            currentPage: page,
            showRender: (from, to, total) => `${from}-${to} dari ${total} User`
          }}
        >
        </Table>
      </SimpleCard>
    </div >
  )
}

export default UserList
