import axios from "axios";
import paginate from "../../utils/helpers/paginate";

export const LIST_URL = "companies/list-root";
export const DETAIL_URL = id => `/companies/${id}/detail`;
export const APPROVE_URL = id => `/companies/${id}/approve-validation`;
export const REJECT_URL = id => `/companies/${id}/reject-validation`;
export const SET_PAYLATER_URL = id => `/companies/${id}/set-paylater`;
export const VERIFY_ID_CARD = id => `/users/${id}/id-card-verify`;

const corporateService = {
  list: ({ page, limit, sort, search }) => {
    let url = `${LIST_URL}?${paginate(limit, page, sort)}`;
    if (search) {
      url += `&search=${search}`;
    }
    return axios.get(url)
  },
  detail: (id) => axios.get(DETAIL_URL(id)),
  approve: (id) => axios.get(APPROVE_URL(id)),
  reject: (data, id) => axios.post(REJECT_URL(id), data),
  setPaylater: (data, id) => axios.post(SET_PAYLATER_URL(id), data),
  verifyId: (id) => axios.get(VERIFY_ID_CARD(id))
}

export default corporateService
