import axios from "axios";
import paginate from "../../utils/helpers/paginate";

export const LIST_URL = "/injection/list";
export const CREATE_URL = "/injection";
export const DETAIL_URL = id => `/injection/${id}/detail`;
export const APPROVE_URL = id => `/injection/${id}/approve`;
export const REJECT_URL = id => `/injection/${id}/reject`;

const injectionService = {
  list: ({ page, limit, sort }) => {
    let url = `${LIST_URL}?${paginate(limit, page, sort)}`;
    return axios.get(url)
  },
  create: (data) => {
    return axios.post(CREATE_URL, data)
  },
  detail: (id) => axios.get(DETAIL_URL(id)),
  approve: (id) => axios.post(APPROVE_URL(id)),
  reject: (remark, id) => {
    let url = REJECT_URL(id);
    if (remark) {
      url += `?rejectReason=${remark}`;
    }
    return axios.post(url)
  },
}

export default injectionService
