import { AdminRole } from "../enums/adminRoles";
// import { UserRoleTypes } from "../enums/userRoleTypes";

export const ROUTE_DASHBOARD = 'ROUTE_DASHBOARD';
export const ROUTE_EXAMPLE = 'ROUTE_EXAMPLE';
export const ROUTE_USER = 'ROUTE_USER';
export const ROUTE_ADMIN_APPROVAL = 'ROUTE_ADMIN_APPROVAL';
export const ROUTE_ADMIN = 'ROUTE_ADMIN';
export const ROUTE_TRANSACTION = 'ROUTE_TRANSACTION';
export const ROUTE_ORDERS = 'ROUTE_ORDERS';
export const ROUTE_AUDIT_LOG = 'ROUTE_AUDIT_LOG';
export const ROUTE_INJECTION = 'ROUTE_INJECTION';
export const ROUTE_DEDUCTION = 'ROUTE_DEDUCTION';
export const ROUTE_BANKS = 'ROUTE_BANKS';
export const ROUTE_CORPORATE = 'ROUTE_CORPORATE';
export const ROUTE_LOGOUT = 'ROUTE_LOGOUT';
export const ROUTE_DTTOT = 'ROUTE_DTTOT';
export const ROUTE_REFUND = 'ROUTE_REFUND';
export const ROUTE_MUTATION = 'ROUTE_MUTATION';
export const ROUTE_DEPOSIT_TUTORIALS = 'ROUTE_DEPOSIT_TUTORIALS';

class roleHelper {
  static getRoles(routeKey) {
    let allAccess = false, roles;
    switch (routeKey) {
      case ROUTE_DASHBOARD:
        allAccess = true
        break;
      case ROUTE_EXAMPLE:
        allAccess = true
        break;
      case ROUTE_BANKS:
        roles = ['OPERATION'];
        break;
      // case ROUTE_INJECTION:
      //   roles = ['FINANCE', 'SUPERVISOR'];
      //   break;
      // case ROUTE_DEDUCTION:
      //   roles = ['FINANCE', 'SUPERVISOR'];
      //   break;
      case ROUTE_USER:
        roles = ['RISK', 'OPERATION', 'SUPERVISOR', 'MARKETING'];
        break;
      case ROUTE_ADMIN_APPROVAL:
        roles = ['SUPERVISOR'];
        break;
      case ROUTE_TRANSACTION:
        roles = ['RISK', 'FINANCE', 'OPERATION', 'SUPERVISOR', 'MARKETING'];
        break;
      case ROUTE_ORDERS:
        roles = ['RISK', 'FINANCE', 'OPERATION', 'SUPERVISOR', 'MARKETING'];
        break;
      case ROUTE_AUDIT_LOG:
        roles = ['RISK'];
        break;
      case ROUTE_CORPORATE:
        roles = ['RISK', 'FINANCE', 'OPERATION', 'SUPERVISOR', 'MARKETING'];
        break;
      case ROUTE_DTTOT:
        roles = ['RISK'];
        break;
      case ROUTE_REFUND:
        roles = ['RISK', 'FINANCE', 'OPERATION', 'SUPERVISOR', 'MARKETING'];
        break;
      case ROUTE_MUTATION:
        roles = ['RISK', 'FINANCE', 'OPERATION', 'SUPERVISOR'];
        break;
      case ROUTE_DEPOSIT_TUTORIALS:
        roles = [];
        break;
      case ROUTE_LOGOUT:
        allAccess = true
        break;
      default:
        roles = [];
        break;
    }
    return { roles, allAccess };
  }

  static hasAccess(role, routeKey) {
    if (!routeKey) return false
    const { allAccess, roles } = this.getRoles(routeKey)
    if (allAccess) return true
    else if (role === AdminRole.SUPER_ADMIN) return true
    return roles.includes(role);
  }
}

export default roleHelper;
