import pakaiClass from 'pakai-class'
import React from 'react'
import styles from './loadings.module.scss'

const LoadingBar = ({
  className,
  position
}) => {
  return (
    <div style={{ top: position === 'bottom' ? 'calc(100% - 2px)' : 0 }} className={pakaiClass(styles.progressRoot, className)}>
      <div className={styles.progressBar}></div>
    </div>
  )
}

export default LoadingBar
