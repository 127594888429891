import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pakaiClass from 'pakai-class';
import React from 'react';
import styles from './table.module.scss'

const Th = ({ col, sort, onSort, className }) => {
  const sortable = col?.sortable === false ? false : true;
  const [key, direction] = sort.split('|');

  const getClass = () => {
    let classList;
    if (sortable) {
      classList = pakaiClass(className, styles.sortable)
      if (col.key === key) {
        classList = pakaiClass(className, styles.sortable, styles[direction], styles.active);
      }
    }

    return classList;
  }

  const doSort = () => {
    if (!sortable) return

    let v = `${col.key}|`;
    let newDirection = '';

    if (key === col.key) {
      newDirection = direction === 'asc' ? 'desc' : 'asc';
    } else {
      newDirection = 'asc';
    }
    v += newDirection;
    onSort(v)
  }

  return (
    <th
      name={col.name}
      onClick={doSort}
      className={getClass()}
    >
      <span className={pakaiClass(col.key === key && "mr-8")}>{col.title}</span>
      {col.key === key &&
        <FontAwesomeIcon icon={faAngleUp} />
      }
    </th>
  )
}

export default Th
