import React, { Component } from 'react';
import styles from "./timeline.module.scss";
import AllStatus from '../../utils/enums/status';

const Dot = (props) => {
  // const { warningType, successType, dangerType } = props;
  const getColor = status => {
    let className = '';
    if (
      status === AllStatus.UPDATED ||
      status === AllStatus.ACCEPTED_TNC
    ) {
      className = 'badge-primary'
    } else if (
      status === AllStatus.CREATED ||
      status === AllStatus.ACTIVED ||
      status === AllStatus.APPROVED ||
      status === AllStatus.DUKCAPIL_VERIFIED ||
      status === AllStatus.PASSWORD_SET ||
      status === AllStatus.VERIFIED
    ) {
      className = 'badge-success'
    } else if (
      status === AllStatus.PENDING
    ) {
      className = 'badge-warning'
    } else if (
      status === AllStatus.REJECTED
    ) {
      className = 'badge-danger'
    } else {
      className = 'badge-light'
    }
    return className
  }

  return (
    <ul>
      <li key={props.date} className="d-flex">
        <span className="font-size-14 text-dark-gray mr-8">{props.date}</span>
        <div className={styles.timelineIndicator}>
          <i
            className={`badge badge-dot asbolute ${getColor(props.title)}`}
            style={{ marginLeft: '-5px' }}
          />
        </div>
        <div className="pb-24">
          <h4 className="font-700 font-size-16 mb-6">{AllStatus.getStrIdn(props.title)}</h4>
          <p className="font-size-14 text-dark-gray">{props.desc}</p>
        </div>
      </li>
    </ul>
  )
}

class Timeline extends Component {
  static Dot = Dot;

  render() {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

export default Timeline
