import { put, takeLatest, call, delay, takeEvery } from "redux-saga/effects";
import orderService from "../../axios/services/orderService";
import { combineBy } from "../../utils/helpers/combineBy";
import { closeTab, createTab } from "../../utils/helpers/tabHelper";
import { authActionTypes } from "../actions/authActions";
import { orderActions, orderTypes } from "../actions/orderActions";

const initialState = {
  activeTabKey: 0,
  tabs: [],
  items: [],
  totalAmount: '',
  totalFee: '',
  tableConfig: {
    isLoading: false,
    totalData: 0,
    limit: 10,
    sort: "createdAt|desc",
    page: 1,
    filter: {
      search: '',
      status: '',
      companyId: ''
    }
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case orderTypes.LOAD_REQUESTED: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          isLoading: true,
        }
      }
    }
    case orderTypes.LOADED: {
      return {
        ...state,
        items: action.payload.data.orders,
        totalAmount: action.payload.data.totalAmount,
        totalFee: action.payload.data.totalFee,
        tableConfig: {
          ...state.tableConfig,
          totalData: action.payload.data.count,
          isLoading: false,
        }
      };
    }
    case orderTypes.SET_TABLE_CONFIG: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          [action.key]: action.value
        }
      };
    }
    case orderTypes.SET_TABLE_CONFIG_FILTER: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          filter: {
            ...state.tableConfig.filter,
            [action.key]: action.value
          },
          page: 1
        }
      };
    }
    case orderTypes.CHANGE_TAB:
      return {
        ...state,
        activeTabKey: action.key,
      }
    case orderTypes.CLOSE_TAB: {
      const { newTabs, nextKey } = closeTab(state.tabs, action.key, state.activeTabKey);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: nextKey
      }
    }
    case orderTypes.CREATE_TAB: {
      const newTabs = createTab(state.tabs, action.key, action.title);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: action.key
      }
    }
    case orderTypes.GET_DETAIL_REQUESTED: {
      const clonedTabs = [...state.tabs];
      if (clonedTabs[action.index]) clonedTabs[action.index].loading = true;
      return {
        ...state,
        tabs: clonedTabs
      };
    }
    case orderTypes.GET_DETAIL_LOADED: {
      const clonedTabs = [...state.tabs];
      if (clonedTabs[action.index]) {
        clonedTabs[action.index].loading = false;
        clonedTabs[action.index].item = action.payload.data.transactions;
        clonedTabs[action.index].totalAmount = action.payload.data.totalAmount;
        clonedTabs[action.index].totalFee = action.payload.data.totalFee;
      }
      return {
        ...state,
        tabs: clonedTabs
      };
    }
    case authActionTypes.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export function* saga() {
  yield takeLatest(orderTypes.LOAD_REQUESTED, function* loadData({ tableConfig }) {
    yield delay(300);
    try {
      const search = combineBy([
        tableConfig.filter && typeof tableConfig.filter === 'object' && tableConfig.filter.status ? `status|${tableConfig.filter.status}` : null,
        tableConfig.filter.search ? `code|${tableConfig.filter.search}` : null
      ], ',');
      const companyId = tableConfig.filter.companyId;

      const { data } = yield call(orderService.list, {
        page: tableConfig.page,
        limit: tableConfig.limit,
        sort: tableConfig.sort,
        search,
        companyId
      });
      yield put(orderActions.loaded(data));
    } catch (error) {
      // console.log('error reached', error);
    }
  });

  yield takeEvery(orderTypes.GET_DETAIL_REQUESTED, function* getDetail({ id, index }) {
    yield delay(300);
    try {
      const { data } = yield call(orderService.detail, id);
      yield put(orderActions.getDetailLoaded(data, index));
    } catch (error) {
      // console.log('error reached', error);
    }
  });
}
