import React from 'react'
import {
  faKey,
  faUser,
  faShield
} from "@fortawesome/free-solid-svg-icons";
import RoundBadge from '../../components/Badge/RoundBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';

const AdminStep = ({ status = {}, forExcel }) => {
  const getPasswordSet = () => (
    forExcel
      ? (status?.isPasswordSet ? <span>Password sudah di buat, </span> : <span>Password belum di buat, </span>)
      :
      <RoundBadge
        title={status?.isPasswordSet ? `Password sudah di buat` : 'Password belum di buat'}
        active={status.isPasswordSet}
        variant="warning"
        icon={<FontAwesomeIcon icon={faKey} size="xs" />}
      />
  );

  const getActive = () => (
    forExcel
      ? (status?.active ? <span>Aktif, </span> : <span>Tidak Aktif, </span>)
      :
      <RoundBadge
        title={status?.active ? `Aktif` : 'Tidak Aktif'}
        active={status.active}
        variant="success"
        icon={<FontAwesomeIcon icon={faUser} size="xs" />}
      />
  );

  const getVerified = () => (
    forExcel
      ? (status?.verifiedAt && status?.rejectReason ? <span>Ditolak: {status?.rejectReason}, </span> : status?.verifiedAt ? <span>Diverifikasi pada: {format(new Date(status?.verifiedAt), 'yyyy-MM-dd HH:mm:ss')}, </span> : <span>Belum Diverifikasi</span>)
      :
        status?.active ?
        <RoundBadge
          title={!status?.verifiedAt && status?.rejectReason ? `Ditolak: ${status?.rejectReason}` : status?.verifiedAt ? `Diverifikasi pada: ${format(new Date(status?.verifiedAt), 'yyyy-MM-dd HH:mm:ss')}` : 'Belum Diverifikasi'}
          active={true}
          variant="primary"
          icon={<FontAwesomeIcon icon={faShield} size="sm" />}
        />
        :
        <RoundBadge
          title={!status?.verifiedAt && status?.rejectReason ? `Ditolak: ${status?.rejectReason}` : status?.verifiedAt ? `Diverifikasi pada: ${format(new Date(status?.verifiedAt), 'yyyy-MM-dd HH:mm:ss')}` : 'Belum Diverifikasi'}
          active={status.verifiedAt}
          variant="primary"
          icon={<FontAwesomeIcon icon={faShield} size="sm" />}
        />
  );

  return (
    <div className="d-flex align-items-center justify-content-center">
      {getPasswordSet()}
      {getVerified()}
    </div>
  )
}

export default AdminStep