import { put, takeLatest, call, delay, takeEvery } from "redux-saga/effects";
import corporateService from "../../axios/services/corporateService";
import { closeTab, createTab } from "../../utils/helpers/tabHelper";
import { authActionTypes } from "../actions/authActions";
import { corporateActions, corporateTypes } from "../actions/corporateActions";

const initialState = {
  activeTabKey: 0,
  tabs: [],
  items: [],
  tableConfig: {
    isLoading: false,
    totalData: 0,
    limit: 10,
    sort: "createdAt|desc",
    page: 1,
    filter: {
      search: ''
    }
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case corporateTypes.LOAD_REQUESTED: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          isLoading: true,
        }
      }
    }
    case corporateTypes.LOADED: {
      return {
        ...state,
        items: action.payload.data.companies,
        tableConfig: {
          ...state.tableConfig,
          totalData: action.payload.data.count,
          isLoading: false,
        }
      };
    }
    case corporateTypes.SET_TABLE_CONFIG: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          [action.key]: action.value
        }
      };
    }
    case corporateTypes.SET_TABLE_CONFIG_FILTER: {
      return {
        ...state,
        tableConfig: {
          ...state.tableConfig,
          filter: {
            ...state.tableConfig.filter,
            [action.key]: action.value
          },
          page: 1
        }
      };
    }
    case corporateTypes.CHANGE_TAB:
      return {
        ...state,
        activeTabKey: action.key,
      }
    case corporateTypes.CLOSE_TAB: {
      const { newTabs, nextKey } = closeTab(state.tabs, action.key, state.activeTabKey);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: nextKey
      }
    }
    case corporateTypes.CREATE_TAB: {
      const newTabs = createTab(state.tabs, action.key, action.title);
      return {
        ...state,
        tabs: newTabs,
        activeTabKey: action.key
      }
    }
    case corporateTypes.GET_DETAIL_REQUESTED: {
      const clonedTabs = [...state.tabs];
      if (clonedTabs[action.index]) clonedTabs[action.index].loading = true;
      return {
        ...state,
        tabs: clonedTabs
      };
    }
    case corporateTypes.GET_DETAIL_LOADED: {
      const clonedTabs = [...state.tabs];
      if (clonedTabs[action.index]) {
        clonedTabs[action.index].loading = false;
        clonedTabs[action.index].item = action.payload.data;
      }
      return {
        ...state,
        tabs: clonedTabs
      };
    }
    case authActionTypes.LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export function* saga() {
  yield takeLatest(corporateTypes.LOAD_REQUESTED, function* loadData({ tableConfig }) {
    yield delay(300);
    try {
      const search = tableConfig.filter.search ? `code|${tableConfig.filter.search},name|${tableConfig.filter.search}` : null;
      const { data } = yield call(corporateService.list, {
        page: tableConfig.page,
        limit: tableConfig.limit,
        sort: tableConfig.sort,
        search
      });
      yield put(corporateActions.loaded(data));
    } catch (error) {
      // console.log('error reached', error);
    }
  });

  yield takeEvery(corporateTypes.GET_DETAIL_REQUESTED, function* getDetail({ id, index }) {
    yield delay(300);
    try {
      const { data } = yield call(corporateService.detail, id);
      yield put(corporateActions.getDetailLoaded(data, index));
    } catch (error) {
      // console.log('error reached', error);
    }
  });
}
