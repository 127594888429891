import React, { useEffect } from 'react'
import TextField from '../../components/Forms/TextField'
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MainButton from '../../components/templates/MainButton';
import CompanySelect from '../../components/CompanySelect';

const schema = yup.object().shape({
  company: yup.string().required('Perusahaan Bank tidak boleh kosong'),
  amount: yup.string().required('Nominal tidak boleh kosong'),
  description: yup.string().required('Deskripsi tidak boleh kosong')
})

const Form = ({ onSubmit }) => {
  const { register, unregister, handleSubmit, setValue, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    register("company");

    return () => {
      unregister("company")
    }
  }, [register, unregister])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CompanySelect
        className="mb-24"
        onChange={(e) => setValue("company", e?.value, { shouldValidate: true })}
        error={errors.company?.message}
        helperText={errors.company?.message}
      />
      <TextField
        className="mb-24"
        label="Nominal"
        ref={register}
        name="amount"
        error={errors.amount?.message}
        helperText={errors.amount?.message}
      />
      <TextField
        className="mb-24"
        label="Deskripsi"
        ref={register}
        name="description"
        multiline
        error={errors.amount?.message}
        helperText={errors.amount?.message}
      />
      <div>
        <MainButton
          type="submit"
        >
          Buat
        </MainButton>
      </div>
    </form>
  )
}

export default Form
