import { authActions } from "../redux/actions/authActions";
import { toast } from 'react-toastify';
import { REFRESH_TOKEN_URL, ME_URL } from "./services/authService";

export default function setupAxios(axios, store) {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_API;
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(
    response => response.data,
    async err => {
      // Set message if no response returned from Request
      if (!err?.response) err.response = {
        body: {
          message: "Connection Timed Out"
        }
      }

      if (err.response?.config?.url === ME_URL) {
        store.dispatch(authActions.logout())
      }

      // Refresh user token
      if (err.response?.status === 401 && !err.response?.config?.stored && err.response?.config?.url !== REFRESH_TOKEN_URL) {
        const { auth: { refreshToken } } = store.getState();

        if (refreshToken) {
          return new Promise((resolve, reject) => {
            // Store API to REDUX for re-call after success refreshing token
            const config = {
              ...err.response.config,
              stored: true
            }
            store.dispatch(authActions.storeApiRequest(config, resolve, reject))

            // EMIT REQUEST REFRESH TOKEN
            store.dispatch(authActions.requestRefreshToken(refreshToken))
          })
        } else {
          store.dispatch(authActions.logout())
        }
      } else {
        // put here to disable toast error by url
        toast(
          typeof err?.response?.data?.message === 'string' ?
            err.response.data.message
            :
            Array.isArray(err?.response?.data?.message) ?
              (
                err.response.data.message[0].constraints ?
                  err.response.data.message[0].constraints[Object.keys(err.response.data.message[0].constraints)[0]]
                  :
                  typeof err.response.data.message[0] === 'string'
                    ?
                    err.response.data.message[0]
                    :
                    "Something went wrong"
              )
              :
              "Something went wrong",
          { type: 'error' }
        )
      }

      // Return Error
      return Promise.reject(err)
    }
  );
}
