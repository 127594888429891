export function checkIsActive(location, url) {
    if (!location.pathname || !url) {
        return false;
    }
    if (location.pathname === url) {
        return true;
    }

    return false;
}
