import React from 'react'
import { Spinner } from '../../assets/icons'
import styles from './loadings.module.scss'

const LoadingSpinner = ({ className, size, strokeWidth }) => {
  return (
    <div className={className}>
      <Spinner
        className={styles.loadingSpinnerRoot}
        size={size}
        strokeWidth={strokeWidth}
      />
    </div>
  )
}

export default LoadingSpinner
