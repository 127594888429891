import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '../../../components/Tabs/Tabs'
import { depositTutorialsActions } from '../../../redux/actions/depositTutorialsActions';
import CreateTab from './CreateTab';
import MainTab from './MainTab'
// import UpdateTab from './UpdateTab';

const DepositTutorialsPage = () => {
  const dispatch = useDispatch();
  const { activeTabKey, tabs } = useSelector(state => state.depositTutorials);

  return (
    <div>
      <Tabs
        activeKey={activeTabKey}
        onClick={key => dispatch(depositTutorialsActions.changeTab(key))}
      >
        <Tab title="List">
          <MainTab />
        </Tab>
        <Tab title="Buat">
          <CreateTab />
        </Tab>
        {/* {tabs.map((row, key) =>
          <Tab
            key={row.key}
            title={row.title}
            isLoading={row.loading}
            onClose={() => dispatch(depositTutorialsActions.closeTab(row.key))}
            onClick={() => dispatch(depositTutorialsActions.changeTab(row.key))}
            active={activeTabKey === row.key}
          >
            <UpdateTab index={key} />
          </Tab>
        )} */}
      </Tabs>
    </div>
  )
}

export default DepositTutorialsPage

