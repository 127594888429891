import React, { useMemo, memo } from 'react';
import styles from './table.module.scss';

const Show = memo(({ limit, onLimit, total, currentPage, showRender }) => {
  const from = useMemo(() => {
    let res = ((currentPage - 1) * limit) + 1
    if (res > total) return total;
    return res
  }, [currentPage, limit, total])

  const to = useMemo(() => {
    let res = currentPage * limit;
    if (res > total) return total;
    return res
  }, [currentPage, limit, total])

  const handleChange = e => {
    onLimit(Number(e.target.value))
  }

  return (
    <div className={styles.show}>
      <span className={styles.showText}>
        {
          typeof showRender === "function" ?
            showRender(from, to, total)
            :
            `Showing ${from} to ${to} of ${total}`
        }
      </span>
      {
        onLimit &&
        <select value={limit} onChange={handleChange}>
          <option value="10">10</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value={total}>All</option>
        </select>
      }
    </div>
  )
})

export default Show;
