import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '../../components/Tabs/Tabs';
import { dttotActions } from '../../redux/actions/dttotActions';
import MainTab from './MainTab';

const DttotPage = () => {
  const dispatch = useDispatch();
  const { activeTabKey } = useSelector(state => state.dttot);

  return (
    <div>
      <Tabs
        activeKey={activeTabKey}
        onClick={key => dispatch(dttotActions.changeTab(key))}
      >
        <Tab title="DTTOT">
          <MainTab />
        </Tab>
      </Tabs>
    </div>
  )
}

export default DttotPage
