import React, { memo, useState } from 'react'
import objectPath from 'object-path';
import styles from './table.module.scss';
import pakaiClass from 'pakai-class';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const Data = memo(({ data = [], columns, withIndex, collapse }) => {
  return (
    data?.length > 0 ?
      <>
        {
          data.map((row, key) => (
            <DataRow
              key={key}
              columns={columns}
              row={row}
              index={key + 1}
              withIndex={withIndex}
              collapse={collapse}
            />
          ))
        }
      </>
      :
      <tr>
        <td className={styles.noData} colSpan={withIndex ? columns.length + 1 : columns.length}>Belum ada data</td>
      </tr>
  )
})

const DataRow = ({ columns, row, withIndex, index, collapse }) => {
  const [collapseKeys, setCollapseKeys] = useState([]);

  const setCollapse = (key) => {
    const shownState = Array.from(collapseKeys);
    const index = shownState.indexOf(key);

    if (index >= 0) {
      shownState.splice(index, 1)
      setCollapseKeys(shownState)
    } else {
      shownState.push(key)
      setCollapseKeys(shownState)
    }
  }

  return (
    <>
      <tr>
        {collapse ?
          Render({ row, col: collapse }) ?
            <td onClick={() => setCollapse(row?.collapseKey)}>
              {collapseKeys.includes(row?.collapseKey) ?
                <FontAwesomeIcon icon={faChevronUp} /> :
                <FontAwesomeIcon icon={faChevronDown} />
              }
            </td> :
            <td></td>
          : null
        }
        {withIndex && <td>{index}</td>}
        {columns.map((col, key) =>
          <td
            key={key}
            className={pakaiClass(col.align && styles[col.align])}
          >
            <Render row={row} col={col} />
          </td>
        )}
      </tr>
      {collapseKeys.includes(row.collapseKey) &&
        <Render row={row} col={collapse} /> &&
        <tr className={styles.collapse}>
          <td colSpan={columns.length + 1}>
            <Render row={row} col={collapse} />
          </td>
        </tr>
      }
    </>
  )
}

const Render = ({ row, col }) => {
  const keyValue = objectPath.get(row, col.key) ?? undefined;

  if (typeof col.render === "function") {
    return col.render(keyValue, row)
  }

  return keyValue ?? null
}

export default Data
