import axios from "axios";
import jsonToFormData from "../../utils/helpers/jsonToFormData";
import paginate from "../../utils/helpers/paginate";
export const LIST_URL = "topup-tutorial/list";
export const CREATE_URL = "topup-tutorial/create";
export const DETAIL_URL = id => `topup-tutorial/${id}`;
export const DELETE_URL = (id) => `topup-tutorial/${id}`;
export const UPDATE_URL = (id) => `topup-tutorial/${id}/update`;

const depositTutorialsService = {
  list: ({ page, limit, sort }) => {
    let url = `${LIST_URL}?${paginate(limit, page, sort)}`;
    return axios.get(url)
  },
  detail: (id) => {
    axios.get(DETAIL_URL(id))
  },
  create: (data) => {
    return axios.post(CREATE_URL, jsonToFormData(data))
  },
  update: (data, id) => {
    return axios.patch(UPDATE_URL(id), jsonToFormData(data))
  },
  delete: (id) => {
    return axios.delete(DELETE_URL(id))
  },
}

export default depositTutorialsService
