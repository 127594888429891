import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '../../../components/Tabs/Tabs'
import { auditLogActions } from '../../../redux/actions/auditLogActions';
import MainTab from './MainTab'

const ErrorLogPage = () => {
  const dispatch = useDispatch();
  const { activeTabKey } = useSelector(state => state.auditLog);

  return (
    <div>
      <Tabs
        activeKey={activeTabKey}
        onClick={key => dispatch(auditLogActions.changeTab(key))}
      >
        <Tab title="Error Log">
          <MainTab />
        </Tab>
      </Tabs>
    </div>
  )
}

export default ErrorLogPage
