import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../components/Table'
import SimpleCard from '../../components/templates/SimpleCard'
import { transactionActions } from '../../redux/actions/transactionActions'
import { format } from 'date-fns'
import toIDR from '../../utils/helpers/toIDR'
import Button from '../../components/Button'
import styles from './transaction.module.scss'
import Modal, { ModalWrapper } from '../../components/Modal'
import pakaiClass from 'pakai-class'
import refundService from '../../axios/services/refundService'
import CompanySelect from '../../components/CompanySelect'
import TextField from '../../components/Forms/TextField'
import BanksSelect from '../../components/BanksSelect'

const MainTab = () => {
  const [show, setShow] = useState(false);
  const [isRefundLoading, setIsRefundLoading] = useState(false);
  const [id, setId] = useState("");
  const dispatch = useDispatch();

  const {
    items,
    totalAmount,
    totalFee,
    tableConfig: {
      isLoading,
      page,
      totalData,
      limit,
      sort,
      filter
    }
  } = useSelector(state => state.transaction);

  const load = useCallback(
    () => {
      const tConfig = {
        limit,
        page,
        sort,
        filter
      }
      return dispatch(transactionActions.loadRequested(tConfig))
    },
    [dispatch, limit, page, sort, filter],
  )

  const setTableConfig = useCallback(
    key => value => dispatch(transactionActions.setTableConfig(key, value)),
    [dispatch]
  )

  const setTableConfigFilter = useCallback(
    key => value => dispatch(transactionActions.setTableConfigFilter(key, value)),
    [dispatch]
  )

  const requestRefund = (key) => {
    setIsRefundLoading(true)
    refundService.requestRefund(key)
      .then(() => {
        load()
      })
      .catch(() => { })
      .finally(() => setIsRefundLoading(false))
  }

  useEffect(() => {
    load()
  }, [load])

  const columns = useMemo(() => ([
    {
      title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm')
    },
    {
      title: 'Bank', key: 'bank'
    },
    {
      title: 'No Rekening', key: 'bankAccount'
    },
    {
      title: 'Nomor Transaksi', key: 'metadata.order.code'
    },
    {
      title: 'Perusahaan', key: 'metadata.order.company', render: v => v?.badanUsaha + " " + v?.name
    },
    {
      title: 'Nama', key: 'fullName'
    },
    {
      title: 'Nominal (Rp)', key: 'amount', render: v => toIDR(v ?? 0)
    },
    {
      title: 'Email', key: 'email'
    },
    {
      title: 'Berita', key: 'description'
    },
    {
      title: 'Status', key: 'status', render: v => v == "PROCESSING" ? "Sedang diproses" :  v == "COMPLETED" ? "Berhasil" : "Gagal"
    },
  ]), [isRefundLoading])

  const excelColumns = [
    { title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm') },
    { title: 'Bank', key: 'bank' },
    { title: 'No Rekening', key: 'bankAccount' },
    { title: 'Nama', key: 'fullName' },
    { title: 'Nominal (Rp)', key: 'amount', render: v => toIDR(v ?? 0) },
    { title: 'Email', key: 'email' },
    { title: 'Berita', key: 'description' },
    { title: 'Status', key: 'status' },
  ]

  return (
    <div>
      <SimpleCard>
        <Table
          data={items}
          excel={{
            columns: excelColumns,
            filename: 'Table Transaction'
          }}
          onReload={load}
          onChangePage={setTableConfig('page')}
          onSort={setTableConfig('sort')}
          config={{
            loading: isLoading,
            columns,
            total: totalData,
            limit,
            sort,
            currentPage: page,
            showRender: (from, to, total) => `${from}-${to} dari ${total} Transaksi`
          }}
          renderTotal={() => (
            <ul className={styles.total}>
              <li>
                Total Nominal: <b>{toIDR(totalAmount)}</b>
              </li>
              <li>
                Total Biaya Transfer: <b>{toIDR(totalFee)}</b>
              </li>
              <li>
                Total: <b>{toIDR(totalAmount + totalFee)}</b>
              </li>
            </ul>
          )}
        >
          <div className="row mb-12">
            <div className="col-md-3">
              <TextField
                variant="outlined"
                label="Cari"
                placeholder="Ketik Sesuatu..."
                value={filter.search}
                onChange={e => setTableConfigFilter('search')(e.target.value)}
              />
            </div>
            <div className="col-md-3">
              <BanksSelect
                variant="outlined"
                label="Filter Bank"
                // value={filter.banj}
                onChange={v => {
                  setTableConfigFilter('bank')(v?.value)
                }}
              />
            </div>
            <div className="col-md-3">
              <CompanySelect
                variant="outlined"
                label="Filter Perusahaan"
                value={filter.companyId}
                onChange={v => setTableConfigFilter('companyId')(v?.value)}
              />
            </div>
          </div>
        </Table>
      </SimpleCard>
      <Modal onClose={() => setShow(false)} in={show}>
        <ModalWrapper>
          <div className="text-center mb-12">
            <h3>Apakah anda yakin ingin melanjutkan permintaan ini?</h3>
          </div>
          <div>
            <Button
              className={pakaiClass(styles.confirmButton, "mb-8")}
              onClick={() => {
                requestRefund(id)
                setShow(false)
              }}
            >Ya</Button>
            <Button
              className={styles.cancelButton}
              onClick={() => setShow(false)}
            >Batal</Button>
          </div>
        </ModalWrapper>
      </Modal>
    </div >
  )
}

export default MainTab
