import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Layout from "./layout";
// import DashboardPage from './views/Dashboard'
import roleHelper, {
  ROUTE_CORPORATE,
  // ROUTE_DASHBOARD,
  // ROUTE_EXAMPLE,
  ROUTE_TRANSACTION,
  ROUTE_USER,
  ROUTE_BANKS,
  ROUTE_INJECTION,
  ROUTE_DEDUCTION,
  ROUTE_ADMIN,
  ROUTE_AUDIT_LOG,
  ROUTE_DTTOT,
  ROUTE_REFUND,
  ROUTE_ORDERS,
  ROUTE_MUTATION,
  ROUTE_ADMIN_APPROVAL,
  ROUTE_DEPOSIT_TUTORIALS
} from "./utils/helpers/roleHelper";
import { useSelector } from "react-redux";
// import ExamplePage from "./views/Example";
import UserPage from "./views/User";
import OrderPage from "./views/Order";
import TransactionPage from "./views/Transaction";
import CorporatePage from "./views/Corporate";
import Banks from "./views/Banks";
import InjectionPage from "./views/Injection";
import DeductionPage from "./views/Deduction";
import AdminPage from "./views/Admin";
import AuditLogPage from "./views/Log/AuditLog";
import DttotPage from "./views/DTTOT";
import RefundPage from "./views/Refund";
import MutationPage from "./views/Mutation";
import AdminApprovalPage from "./views/AdminApproval";
import DepositTutorialsPage from "./views/InternalConfiguration/DepositTutorials";
import ErrorLogPage from "./views/Log/ErrorLog";

export default function MainLayout() {
  const { admin } = useSelector(state => state.auth);
  return (
    <Layout>
      <Switch>
        {/* <AuthRoute routeKey={ROUTE_DASHBOARD} role={admin.role} exact path="/dashboard" component={DashboardPage} /> */}
        {/* <AuthRoute routeKey={ROUTE_EXAMPLE} role={admin.role} path="/example" component={ExamplePage} /> */}
        <AuthRoute routeKey={ROUTE_TRANSACTION} role={admin.role} path="/transaction/list" component={TransactionPage} />
        <AuthRoute routeKey={ROUTE_ORDERS} role={admin.role} path="/transaction/orders" component={OrderPage} />
        <AuthRoute routeKey={ROUTE_INJECTION} role={admin.role} path="/transaction/injection" component={InjectionPage} />
        <AuthRoute routeKey={ROUTE_DEDUCTION} role={admin.role} path="/transaction/deduction" component={DeductionPage} />
        <AuthRoute routeKey={ROUTE_REFUND} role={admin.role} path="/transaction/refund" component={RefundPage} />
        <AuthRoute routeKey={ROUTE_USER} role={admin.role} path="/user/list" component={UserPage} />
        <AuthRoute routeKey={ROUTE_ADMIN_APPROVAL} role={admin.role} path="/user/admin-approval" component={AdminApprovalPage} />
        <AuthRoute routeKey={ROUTE_DEPOSIT_TUTORIALS} role={admin.role} path="/internal-configuration/deposit-tutorials" component={DepositTutorialsPage} />
        <AuthRoute routeKey={ROUTE_ADMIN} role={admin.role} exact path="/admin" component={AdminPage} />
        <AuthRoute routeKey={ROUTE_AUDIT_LOG} role={admin.role} path="/log/audit-log" component={AuditLogPage} />
        <AuthRoute routeKey={ROUTE_AUDIT_LOG} role={admin.role} path="/log/error-log" component={ErrorLogPage} />
        <AuthRoute routeKey={ROUTE_CORPORATE} role={admin.role} path="/corporate" component={CorporatePage} />
        <AuthRoute routeKey={ROUTE_BANKS} role={admin.role} path="/bank" component={Banks} />
        <AuthRoute routeKey={ROUTE_DTTOT} role={admin.role} path="/dttot" component={DttotPage} />
        <AuthRoute routeKey={ROUTE_MUTATION} role={admin.role} path="/mutation" component={MutationPage} />
        <Redirect exact from="/" to="/admin" />
        <Redirect exact from="/login" to="/admin" />
        <Redirect to="/error" />
      </Switch>
    </Layout >
  );
}

const AuthRoute = ({ role, routeKey, ...rest }) => {
  return roleHelper.hasAccess(role, routeKey)
    ? <Route {...rest} />
    : <Route {...rest} />
}
