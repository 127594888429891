import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '../../components/Tabs/Tabs';
import { mutationActions } from '../../redux/actions/mutationActions';
import MainTab from './MainTab';

const MutationPage = () => {
  const dispatch = useDispatch();
  const { activeTabKey } = useSelector(state => state.mutation);

  return (
    <div>
      <Tabs
        activeKey={activeTabKey}
        onClick={key => dispatch(mutationActions.changeTab(key))}
      >
        <Tab title="List">
          <MainTab />
        </Tab>
      </Tabs>
    </div>
  )
}

export default MutationPage
