import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OpenInNew } from '../../assets/icons'
import TextField from '../../components/Forms/TextField'
import Table from '../../components/Table'
import ButtonIcon from '../../components/templates/ButtonIcon'
import SimpleCard from '../../components/templates/SimpleCard'
import { orderActions } from '../../redux/actions/orderActions'
import templateStyles from '../../components/templates/templates.module.scss'
import { format } from 'date-fns'
import { OrderStatusOpts, OrderStatusTypes } from '../../utils/enums/orderStatusTypes'
import toIDR from '../../utils/helpers/toIDR'
import SelectField from '../../components/Forms/SelectField'
import styles from './detail.module.scss'
import CompanySelect from '../../components/CompanySelect'

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    items,
    totalAmount,
    totalFee,
    tableConfig: {
      isLoading,
      page,
      totalData,
      limit,
      sort,
      filter
    }
  } = useSelector(state => state.order);

  const load = useCallback(
    () => {
      const tConfig = {
        limit,
        page,
        sort,
        filter
      }
      return dispatch(orderActions.loadRequested(tConfig))
    },
    [dispatch, limit, page, sort, filter],
  )

  const setTableConfig = useCallback(
    key => value => dispatch(orderActions.setTableConfig(key, value)),
    [dispatch]
  )

  const setTableConfigFilter = useCallback(
    key => value => dispatch(orderActions.setTableConfigFilter(key, value)),
    [dispatch]
  )

  useEffect(() => {
    load()
  }, [load])



  const columns = useMemo(() => ([
    {
      title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm')
    },
    {
      title: 'Kode Perusahaan', key: 'code',
    },
    {
      title: 'Perusahaan', key: 'company', render: v => `${v.name} | ${v.uniqueId}`
    },
    {
      title: 'Status Transaksi', key: 'status', render: v => (
        <div className={v === OrderStatusTypes.COMPLETED ? "text-success" : v === OrderStatusTypes.CANCELED ? "text-danger" : "text-primary"}>{OrderStatusTypes.getStr(v)}</div>
      )
    },
    {
      title: 'Total Nominal', key: 'totalAmount', render: (v) => toIDR(v)
    },
    {
      title: '', key: '_id', render: (v, row) => (
        <div className={templateStyles.buttonGroup}>
          <ButtonIcon
            icon={<OpenInNew />}
            onClick={() => dispatch(orderActions.createTab(v, row.code))}
          />
        </div>
      )
    }
  ]), [dispatch]);

  const excelColumns = [
    { title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm') },
    { title: 'Code', key: 'code' },
    { title: 'Perusahaan', key: 'company', render: v => `${v.name} | ${v.uniqueId}` },
    {
      title: 'Status Transaksi', key: 'status', render: v => (
        <div className={v === OrderStatusTypes.COMPLETED ? "text-success" : v === OrderStatusTypes.CANCELED ? "text-danger" : "text-primary"}>{OrderStatusTypes.getStr(v)}</div>
      )
    },
    { title: 'Total Nominal', key: 'totalAmount', render: (v) => toIDR(v) },
  ]
  return (
    <div>
      <SimpleCard>
        <Table
          data={items}
          excel={{
            columns: excelColumns,
            filename: 'Table Orders'
          }}
          onReload={load}
          onChangePage={setTableConfig('page')}
          onSort={setTableConfig('sort')}
          config={{
            loading: isLoading,
            columns,
            total: totalData,
            limit,
            sort,
            currentPage: page,
            showRender: (from, to, total) => `${from}-${to} dari ${total} Transaksi`
          }}
          renderTotal={() => (
            <ul className={styles.total}>
              <li>
                Total Nominal: <b>{toIDR(totalAmount)}</b>
              </li>
              <li>
                Total Biaya Transfer: <b>{toIDR(totalFee)}</b>
              </li>
              <li>
                Total: <b>{toIDR(totalAmount + totalFee)}</b>
              </li>
            </ul>
          )}
        >
          <div className="row mb-12">
            <div className="col-md-3">
              <TextField
                variant="outlined"
                label="Cari"
                placeholder="Ketik Sesuatu..."
                value={filter.search}
                onChange={e => setTableConfigFilter('search')(e.target.value)}
              />
            </div>
            <div className="col-md-3">
              <SelectField
                variant="outlined"
                label="Filter Status"
                options={OrderStatusOpts}
                value={filter.status}
                onChange={v => setTableConfigFilter('status')(v?.value)}
                isClearable
              />
            </div>
            <div className="col-md-3">
              <CompanySelect
                variant="outlined"
                label="Filter Perusahaan"
                value={filter.companyId}
                onChange={v => setTableConfigFilter('companyId')(v?.value)}
              />
            </div>
          </div>
        </Table>
      </SimpleCard>
    </div >
  )
}

export default MainTab
