import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '../../components/Tabs/Tabs';
import { injectionActions } from '../../redux/actions/injectionActions';
import CreateTab from './CreateTab';
import DetailTab from './DetailTab';
import MainTab from './MainTab';

const InjectionPage = () => {
  const dispatch = useDispatch();
  const { activeTabKey, tabs } = useSelector(state => state.injection);

  return (
    <div>
      <Tabs
        activeKey={activeTabKey}
        onClick={key => dispatch(injectionActions.changeTab(key))}
      >
        <Tab title="List">
          <MainTab />
        </Tab>
        <Tab title="Buat">
          <CreateTab />
        </Tab>
        {tabs.map((row, key) =>
          <Tab
            key={row.key}
            title={row.title}
            isLoading={row.loading}
            onClose={() => dispatch(injectionActions.closeTab(row.key))}
            onClick={() => dispatch(injectionActions.changeTab(row.key))}
            active={activeTabKey === row.key}
          >
            <DetailTab index={key} />
          </Tab>
        )}
      </Tabs>
    </div>
  )
}

export default InjectionPage
