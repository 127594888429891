import React from 'react'

const ExportExcelToast = ({ filename, progress }) => (
  <div>
    <p className="mb-0">Export Excel</p>
    <p className="mb-0 font-size-md font-weight-bold">{filename}</p>
    <p className="mb-0">{Math.round(progress * 100)}%</p>
  </div>
)

export default ExportExcelToast
