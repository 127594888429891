export default function getPropByString(obj, propString) {
  if (!obj) return '';
  if (!propString) return obj;

  let prop, props = propString.split('.');
  let i = 0;
  let iLen = props.length - 1;

  for (i; i < iLen; i++) {
    prop = props[i];

    const candidate = obj[prop];
    if (candidate) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
}
