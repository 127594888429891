import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OpenInNew } from '../../assets/icons'
import TextField from '../../components/Forms/TextField'
import Table from '../../components/Table'
import ButtonIcon from '../../components/templates/ButtonIcon'
import SimpleCard from '../../components/templates/SimpleCard'
import { corporateActions } from '../../redux/actions/corporateActions'
import templateStyles from '../../components/templates/templates.module.scss'
import { format } from 'date-fns'
import CorporateStatuses from './CorporateStatuses'
import CorporateStep from './CorporateStep'

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    items,
    tableConfig: {
      isLoading,
      page,
      totalData,
      limit,
      sort,
      filter
    }
  } = useSelector(state => state.corporate);

  const load = useCallback(
    () => {
      const tConfig = {
        limit,
        page,
        sort,
        filter
      }
      return dispatch(corporateActions.loadRequested(tConfig))
    },
    [dispatch, limit, page, sort, filter],
  )

  const setTableConfig = useCallback(
    key => value => dispatch(corporateActions.setTableConfig(key, value)),
    [dispatch]
  )

  const setTableConfigFilter = useCallback(
    key => value => dispatch(corporateActions.setTableConfigFilter(key, value)),
    [dispatch]
  )

  useEffect(() => {
    load()
  }, [load])



  const columns = useMemo(() => ([
    {
      title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm')
    },
    { title: 'Nama Perusahaan', key: 'name' },
    { title: 'Kode Perusahaan', key: 'code' },
    { title: 'Step', key: 'status', render: (v, row) => <CorporateStep status={v} /> },
    { title: 'Status', key: 'status', render: (v, row) => <CorporateStatuses status={v} /> },
    {
      title: 'Terakhir diperbarui', key: 'updatedAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm')
    },
    {
      title: '', key: '_id', render: (v, row) => (
        <div className={templateStyles.buttonGroup}>
          <ButtonIcon
            icon={<OpenInNew />}
            onClick={() => dispatch(corporateActions.createTab(v, row.code))}
          />
        </div>
      )
    }
  ]), [dispatch]);

  const excelColumns = [
    { title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm') },
    { title: 'Nama Perusahaan', key: 'name' },
    { title: 'Kode Perusahaan', key: 'code' },
    { title: 'Status', key: 'status', render: (v, row) => <CorporateStatuses status={v} forExcel /> },
    { title: 'Terakhir diperbarui', key: 'updatedAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm') },
  ];

  return (
    <div>
      <SimpleCard>
        <Table
          data={items}
          excel={{
            columns: excelColumns,
            filename: 'Table Perusahaan'
          }}
          onReload={load}
          onChangePage={setTableConfig('page')}
          onSort={setTableConfig('sort')}
          config={{
            loading: isLoading,
            columns,
            total: totalData,
            limit,
            sort,
            currentPage: page,
            showRender: (from, to, total) => `${from}-${to} dari ${total} Perusahaan`
          }}
        >
          <div className="row mb-12">
            <div className="col-md-3">
              <TextField
                variant="outlined"
                label="Cari"
                placeholder="Ketik Sesuatu..."
                value={filter.search}
                onChange={e => setTableConfigFilter('search')(e.target.value)}
              />
            </div>
          </div>
        </Table>
      </SimpleCard>
    </div >
  )
}

export default MainTab
