import React from 'react'
import { Dot } from '../../assets/icons';
import { useSelector } from "react-redux";
import styles from './sidebar.module.scss';
import { toAbsoluteUrl } from '../../utils/helpers/pathHelper';
import { AdminRole } from '../../utils/enums/adminRoles';

const UserCard = () => {
  const { admin } = useSelector(state => state.auth);

  return (
    <div className={styles.userCard}>
      <div className={styles.avatar}>
        <img
          src={toAbsoluteUrl('/assets/media/imageField/avatar.jpg')}
          alt=""
        />
      </div>
      <div className="overflow-hidden">
        <div className={`${styles.primaryText} mb-6 ellipsis-text`}>{admin.fullName}</div>
        <div className="d-flex align-items-center">
          <div className={`${styles.secondaryText} flex-shrink-0`}>{AdminRole.getStr(admin.role)}</div>
          <Dot className={`${styles.dot} flex-shrink-0`} />
          <div className={`${styles.secondaryText} ellipsis-text`}>{admin.email}</div>
        </div>
      </div>
    </div>
  )
}

export default UserCard
