import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './sass/main.scss';
import { store, persistor } from './redux/store'
import axios from "axios";
import setupAxios from './axios/setupAxios'
// import mockAxios from './axios/mockAxios'

const { PUBLIC_URL } = process.env;

// mockAxios(axios);
setupAxios(axios, store);

ReactDOM.render(
  <App store={store} persistor={persistor} basename={PUBLIC_URL} />,
  document.getElementById('root')
);
