import pakaiClass from "pakai-class";
import { Children, cloneElement, useState } from "react"
import styles from './menubars.module.scss'

const MenuBars = ({
  children
}) => {
  const [activeKey, setActiveKey] = useState(0);

  return (
    <div className={styles.menubars}>
      <ul>
        {Children.map(children, (child, i) =>
          child ?
            cloneElement(child, {
              index: i,
              onClick: child.props?.onClick ?? setActiveKey,
              active: child.props?.active ?? activeKey === i
            })
            :
            null
        )}
      </ul>
      {Children.map(children, (child, i) =>
        child ?
          activeKey === i ?
            <div className={styles.content}>{child.props.children}</div>
            : null
          :
          null
      )}
    </div>
  )
}

const MenuList = ({
  onClick,
  index,
  label,
  active,
  disable
}) => {
  return (
    <li key={label} className={pakaiClass([active && styles.active, disable && styles.disable])}>
      <button
        onClick={() => !disable && onClick(index)}
      >
        {label}
      </button>
    </li>
  )
}

export { MenuBars, MenuList }
