import axios from "axios";
import paginate from "../../utils/helpers/paginate";

export const TRANSACTION_LIST_URL = "transactions/list";

const transactionService = {
  list: ({ page, limit, sort, search, bank, companyId }) => {
    let url = `${TRANSACTION_LIST_URL}?${paginate(limit, page, sort)}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (bank) {
      url += `&bankId=${bank}`;
    }
    if (companyId) {
      url += `&companyId=${companyId}`;
    }
    return axios.get(url)
  }
}

export default transactionService
