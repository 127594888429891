import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '../../components/Tabs/Tabs';
import { refundActions } from '../../redux/actions/refundActions';
import DetailTab from './DetailTab';
import MainTab from './MainTab';

const RefundPage = () => {
  const dispatch = useDispatch();
  const { tabs, activeTabKey } = useSelector(state => state.refund);

  return (
    <div>
      <Tabs
        activeKey={activeTabKey}
        onClick={key => dispatch(refundActions.changeTab(key))}
      >
        <Tab title="List">
          <MainTab />
        </Tab>
        {tabs.map((row, key) =>
          <Tab
            key={row.key}
            title={row.title}
            isLoading={row.loading}
            onClose={() => dispatch(refundActions.closeTab(row.key))}
            onClick={() => dispatch(refundActions.changeTab(row.key))}
            active={activeTabKey === row.key}
          >
            <DetailTab index={key} />
          </Tab>
        )}
      </Tabs>
    </div>
  )
}

export default RefundPage
