import axios from "axios";

export const LIST_URL = "/example/list";
export const DETAIL_URL = id => `/example/${id}/detail`;

const exampleService = {
  list: () => axios.get(LIST_URL),
  detail: (id) => axios.get(DETAIL_URL(id))
}

export default exampleService
