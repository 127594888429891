import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { corporateActions } from "../redux/actions/corporateActions";
import SelectField from "./Forms/SelectField";

const CompanySelect = ({
  className,
  onChange,
  disabled,
  label,
  variant,
  value
}) => {
  const [options, setOptions] = useState([]);

  const dispatch = useDispatch();
  const {
    items,
    tableConfig: {
      isLoading,
      page,
      limit,
      sort,
      filter
    }
  } = useSelector(state => state.corporate);

  const load = useCallback(
    () => {
      const tConfig = {
        limit,
        page,
        sort,
        filter
      }
      return dispatch(corporateActions.loadRequested(tConfig))
    },
    [dispatch, limit, page, sort, filter],
  )

  const handleScrollToBottom = () => {
    const newPage = page + 1;
    if ((newPage * limit) < items) {

      const tConfig = {
        limit,
        newPage,
        sort,
        filter
      }
      return dispatch(corporateActions.loadRequested(tConfig))
    }
  }

  const generateOpts = companies => {
    return companies.map((el, i) => (
      {
        value: el._id,
        label: `${el.name} | ${el.uniqueId}`
      }
    ))
  }

  useEffect(() => {
    load();
  }, [load])

  useEffect(() => {
    if (items) {
      setOptions(generateOpts(items))
    }
  }, [items])

  return (
    <div>
      <SelectField
        label={label || "Perusahaan"}
        options={options}
        className={className}
        value={value}
        onChange={onChange}
        variant={variant}
        componentProps={{
          isLoading: isLoading,
          onMenuScrollToBottom: handleScrollToBottom,
          isDisabled: disabled ? disabled : isLoading
        }}
        isClearable
      />
    </div>
  )
}

export default CompanySelect
