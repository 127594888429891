import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../components/Table'
import SimpleCard from '../../components/templates/SimpleCard'
import { adminApprovalActions } from '../../redux/actions/adminApprovalActions'
import format from 'date-fns/format'
import ButtonIcon from '../../components/templates/ButtonIcon'
import { OpenInNew } from '../../assets/icons'
import templateStyles from '../../components/templates/templates.module.scss'
import AllStatus from '../../utils/enums/status'

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    items,
    tableConfig: {
      isLoading,
      page,
      totalData,
      limit,
      sort,
      filter
    }
  } = useSelector(state => state.adminApproval);

  const load = useCallback(
    () => {
      const tConfig = {
        limit,
        page,
        sort,
        filter
      }
      return dispatch(adminApprovalActions.loadRequested(tConfig))
    },
    [dispatch, limit, page, sort, filter],
  )

  const setTableConfig = useCallback(
    key => value => dispatch(adminApprovalActions.setTableConfig(key, value)),
    [dispatch]
  )

  useEffect(() => {
    load()
  }, [load])

  const columns = useMemo(() => ([
    {
      title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm:ss')
    },
    {
      title: 'Kategori', key: 'data', render: v => v.category
    },
    {
      title: 'Status', key: 'status', render: v => AllStatus.getStrIdn(v)
    },
    {
      title: '', key: '_id', render: (v, row) => (
        <div className={templateStyles.buttonGroup}>
          <ButtonIcon
            icon={<OpenInNew />}
            onClick={() => dispatch(adminApprovalActions.createTab(v, v))}
          />
        </div>
      )
    }
  ]), [dispatch]);

  const excelColumns = [
    { title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm:ss') },
    { title: 'Kategori', key: 'data', render: v => v.category },
    { title: 'Status', key: 'status', render: v => AllStatus.getStrIdn(v) },
  ]

  return (
    <div>
      <SimpleCard>
        <Table
          data={items}
          excel={{
            columns: excelColumns,
            filename: 'Table Admin Approval'
          }}
          onReload={load}
          onChangePage={setTableConfig('page')}
          onSort={setTableConfig('sort')}
          config={{
            loading: isLoading,
            columns,
            total: totalData,
            limit,
            sort,
            currentPage: page,
            showRender: (from, to, total) => `${from}-${to} dari ${total} Admin Approval`
          }}
        >
        </Table>
      </SimpleCard>
    </div >
  )
}

export default MainTab
