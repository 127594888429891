import React, { useMemo } from 'react'
import styles from './sidebar.module.scss'
import { GroupMenuList, Li, MenuList } from './MenuList'
import { useLocation } from 'react-router'
import routes from '../../Routes'
import pakaiClass from 'pakai-class'
import { useSelector } from 'react-redux'
import roleHelper from '../../utils/helpers/roleHelper'
import UserCard from './UserCard'
import { toAbsoluteUrl } from '../../utils/helpers/pathHelper'

const Sidebar = ({ open, setOpen }) => {
  const { admin } = useSelector(state => state.auth);
  const location = useLocation()

  const {
    mainMenuRender,
    bottomMenuRender
  } = useMemo(() => {
    let mainMenuRender = []
    let bottomMenuRender = []

    routes.forEach((route, index) => {
      if (route.type === 'MAIN') mainMenuRender.push(
        roleHelper.hasAccess(admin.role, route?.routeKey)
        &&
        <Li
          location={location}
          route={route}
          key={index}
          setOpen={setOpen}
        />
      )
      else if (route.type === 'GROUP') mainMenuRender.push(
        route.children
        &&
        <GroupMenuList
          location={location}
          route={route}
          key={index}
        >
          {
            route.children.map((subRoute, idx) => {
              return roleHelper.hasAccess(admin.role, subRoute?.routeKey)
                &&
                <Li
                  location={location}
                  route={subRoute}
                  key={idx}
                  setOpen={setOpen}
                />
            })
          }
        </GroupMenuList>
      )
      else if (route.type === 'BOTTOM') bottomMenuRender.push(
        roleHelper.hasAccess(admin.role, route?.routeKey)
        &&
        <Li
          location={location}
          route={route}
          key={index}
          setOpen={setOpen}
        />
      )
    });

    return {
      mainMenuRender,
      bottomMenuRender
    }
  }, [location, setOpen, admin])

  return (
    <div className={pakaiClass(
      styles.sidebar,
      open && styles.open
    )}>
      <div>
        <div className={styles.header}>
          <div className={styles.logo}>
            <img src={toAbsoluteUrl("/assets/media/logos/logo-dark.png")} alt="" />
          </div>
          <UserCard />
        </div>
        <MenuList>
          {mainMenuRender}
        </MenuList>
      </div>
      <MenuList>
        {bottomMenuRender}
      </MenuList>
    </div>
  )
}

export default Sidebar
