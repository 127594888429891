import axios from "axios";
import paginate from "../../utils/helpers/paginate";

export const LIST_URL = "/users/list-root";
export const DETAIL_URL = id => `/users/${id}/detail`;
export const APPROVE_URL = id => `/users/${id}/approve-validation`;
export const REJECT_URL = id => `/users/${id}/reject-validation`;

const userService = {
  list: ({ page, limit, sort, search, companyId }) => {
    let url = `${LIST_URL}?${paginate(limit, page, sort)}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (companyId) {
      url += `&companyId=${companyId}`;
    }
    return axios.get(url)
  },
  detail: (id) => axios.get(DETAIL_URL(id)),
  approve: (id) => axios.get(APPROVE_URL(id)),
  reject: (id, data) => axios.post(REJECT_URL(id), data)
}

export default userService
