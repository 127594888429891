import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../../components/Table'
import SimpleCard from '../../../components/templates/SimpleCard'
import { auditLogActions } from '../../../redux/actions/auditLogActions'
import format from 'date-fns/format'
import DateField from '../../../components/Forms/DateField'
import locale from 'react-json-editor-ajrm/locale/en';
import JSONInput from 'react-json-editor-ajrm';
import styles from './auditlog.module.scss'

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    items,
    tableConfig: {
      isLoading,
      page,
      totalData,
      limit,
      sort,
      filter
    }
  } = useSelector(state => state.auditLog);

  const load = useCallback(
    () => {
      const tConfig = {
        limit,
        page,
        sort,
        type: "AUDIT_LOG",
        filter
      }
      return dispatch(auditLogActions.loadRequested(tConfig))
    },
    [dispatch, limit, page, sort, filter],
  )

  const setTableConfig = useCallback(
    key => value => dispatch(auditLogActions.setTableConfig(key, value)),
    [dispatch]
  )

  const setTableConfigFilter = useCallback(
    key => value => dispatch(auditLogActions.setTableConfigFilter(key, value)),
    [dispatch]
  )

  useEffect(() => {
    load()
  }, [load])

  const columns = useMemo(() => ([
    {
      title: 'Waktu Dibuat', key: 'time', render: v => v ? format(new Date(v), 'd MMM yyyy, HH:mm:ss') : "", sort: true
    },
    {
      title: <b><i>Hostname</i></b>, key: 'hostname', 
    },
    {
      title: 'URL', key: 'url', render: (v, row) => row?.req?.params[0] ? row.req.params[0] : "-"
    },
    {
      title: 'Method', key: 'method', render: (v, row) => row?.req?.method ? row.req.method : "-"
    },
    {
      title: 'Pesan', key: 'msg'
    },
  ]), []);

  const excelColumns = [
    { title: 'Waktu Dibuat', key: 'time', render: v => v ? format(new Date(v), 'd MMM yyyy, HH:mm:ss') : "", sort: true },
    { title: 'Hostname', key: 'hostname' },
    { title: 'URL', key: 'req', render: v => v ? v?.url : "-" },
    { title: 'Method', key: 'req', render: v => v ? v?.method : "-" },
    { title: 'Pesan', key: 'msg' },
  ];

  return (
    <div>
      <SimpleCard>
        <Table
          data={items}
          excel={{
            columns: excelColumns,
            filename: 'Table Audit Log'
          }}
          onReload={load}
          onChangePage={setTableConfig('page')}
          onSort={setTableConfig('sort')}
          config={{
            loading: isLoading,
            columns,
            total: totalData,
            limit,
            sort,
            currentPage: page,
            showRender: (from, to, total) => `${from}-${to} dari ${total} log`,
            collapse: {
              key: 'collapse',
              render: (v, row) => {
                return row?.req?.method === 'POST' ?
                  <div className={styles.collapse}>
                    {row.req.body &&
                      <div className={styles.item}>
                        <p>Request Body :</p>
                        <JSONInput
                          label="tes"
                          locale={locale}
                          placeholder={row.req.body}
                          theme="light_mitsuketa_tribute"
                          width="100%"
                          height="300px"
                          viewOnly
                          confirmGood={false}
                          style={{ outerBox: { border: '1px solid #ced4da', height: 'auto', borderRadius: '4px', overflow: 'hidden' } }}
                        />
                      </div>
                    }
                    {row.res.body &&
                      <div className={styles.item}>
                        <p>Response Body :</p>
                        <JSONInput
                          label="tes"
                          locale={locale}
                          placeholder={row.res.body}
                          theme="light_mitsuketa_tribute"
                          width="100%"
                          height="300px"
                          viewOnly
                          confirmGood={false}
                          style={{ outerBox: { border: '1px solid #ced4da', height: 'auto', borderRadius: '4px', overflow: 'hidden' } }}
                        />
                      </div>
                    }
                  </div>
                  : null
              }
            },
          }}
        >
          <div className="row mb-12">
            <div className="col-md-3">
              <DateField
                variant="outlined"
                label="Dari Tanggal"
                onChange={e => setTableConfigFilter('fromDate')(e)}
                value={filter.fromDate}
              />
            </div>
            <div className="col-md-3">
              <DateField
                variant="outlined"
                label="Sampai Tanggal"
                value={filter.toDate}
                onChange={e => setTableConfigFilter('toDate')(e)}
              />
            </div>
          </div>
        </Table>
      </SimpleCard>
    </div >
  )
}

export default MainTab
