import React from 'react'
import {
  faClipboardCheck,
  faShield,
  faListCheck
} from "@fortawesome/free-solid-svg-icons";
import RoundBadge from '../../components/Badge/RoundBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';

const CorporateStep= ({ status = {}, forExcel }) => {
  const getAcceptedTnc = () => (
    forExcel
      ? (status.acceptTncAt ? <span>S&K Disetujui, </span> : <span>S&K Belum Disetujui, </span>)
      :
      <RoundBadge
        title={status.acceptTncAt ? `S&K Disetujui` : 'S&K Belum Disetujui'}
        active={status.acceptTncAt}
        variant="success"
        icon={<FontAwesomeIcon icon={faClipboardCheck} size="sm" />}
      />
  );

  const getFormFilled = () => (
    forExcel
      ? (status.updatedAt ? <span>Form Diisi Pada: {format(new Date(status?.updatedAt), "yyyy-MM-dd HH:mm:ss")}, </span> : <span>Form Belum Diisi, </span>)
      :
      <RoundBadge
        title={status.updatedAt ? `Form Diisi Pada: ${format(new Date(status?.updatedAt), "yyyy-MM-dd HH:mm:ss")}` : 'Form Belum Diisi'}
        active={status.updatedAt}
        variant="warning"
        icon={<FontAwesomeIcon icon={faListCheck} size="xs" />}
      />
  );

  const getVerified = () => (
    forExcel
      ? (status?.verifiedAt && status?.rejectReason ? <span>Ditolak: {status?.rejectReason}, </span> : status?.verifiedAt ? <span>Diverifikasi pada: {format(new Date(status?.verifiedAt), 'yyyy-MM-dd HH:mm:ss')},  </span> : <span>Belum Diverifikasi, </span>)
      :
      <RoundBadge
        title={!status?.verifiedAt && status?.rejectReason ? `Ditolak: ${status?.rejectReason}` : status?.verifiedAt ? `Diverifikasi pada: ${format(new Date(status?.verifiedAt), 'yyyy-MM-dd HH:mm:ss')}` : 'Belum Diverifikasi'}
        active={status.verifiedAt || status?.rejectReason}
        variant={status?.verifiedAt ? "primary" : status?.rejectReason && "primary"}
        icon={<FontAwesomeIcon icon={faShield} size="sm" />}
      />
  );

  return (
    <div className="d-flex align-items-center justify-content-center">
      {getFormFilled()}
      {getAcceptedTnc()}
      {getVerified()}
    </div>
  )
}

export default CorporateStep;