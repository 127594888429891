import { configureStore } from "@reduxjs/toolkit";
import { reducers, sagas } from "./reducers";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { exportExcelMiddleware } from "./middleware/middleware";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, exportExcelMiddleware]

export const store = configureStore({
  reducer: reducers,
  middleware: middlewares,
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
