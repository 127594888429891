import {
  faBook,
  faBuilding,
  faFileInvoiceDollar,
  faFlag,
  faLandmark,
  faMasksTheater,
  faRightFromBracket,
  faUser,
  faUsers,
  faAnglesRight,
  faAnglesLeft,
  faArrowRotateLeft,
  faClipboardList,
  faRightLeft,
  faGear,
  faBuildingColumns,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ROUTE_BANKS,
  ROUTE_CORPORATE,
  // ROUTE_DASHBOARD,
  ROUTE_LOGOUT,
  ROUTE_TRANSACTION,
  ROUTE_USER,
  ROUTE_INJECTION,
  ROUTE_ADMIN,
  ROUTE_AUDIT_LOG,
  ROUTE_DTTOT,
  ROUTE_REFUND,
  ROUTE_DEDUCTION,
  ROUTE_ORDERS,
  ROUTE_MUTATION,
  ROUTE_ADMIN_APPROVAL,
  ROUTE_DEPOSIT_TUTORIALS,
} from "./utils/helpers/roleHelper";

const routes = [
  {
    type: 'GROUP',
    title: 'Transaksi',
    icon: <FontAwesomeIcon size="lg" icon={faFileInvoiceDollar} />,
    url: '/transaction',
    children: [
      {
        title: 'Transaksi',
        url: '/transaction/list',
        routeKey: ROUTE_TRANSACTION,
        icon: <FontAwesomeIcon size="lg" icon={faRightLeft} />,
      },
      {
        title: 'Order',
        url: '/transaction/orders',
        routeKey: ROUTE_ORDERS,
        icon: <FontAwesomeIcon size="lg" icon={faClipboardList} />,
      },
      // {
      //   title: 'Refund',
      //   url: '/transaction/refund',
      //   routeKey: ROUTE_REFUND,
      //   icon: <FontAwesomeIcon size="lg" icon={faArrowRotateLeft} />,
      // },
      // {
      //   title: 'Injeksi',
      //   url: '/transaction/injection',
      //   routeKey: ROUTE_INJECTION,
      //   icon: <FontAwesomeIcon size="lg" icon={faAnglesRight} />,
      // },
      // {
      //   title: 'Deduksi',
      //   url: '/transaction/deduction',
      //   routeKey: ROUTE_DEDUCTION,
      //   icon: <FontAwesomeIcon size="lg" icon={faAnglesLeft} />,
      // }
    ]
  },
  {
    type: 'MAIN',
    title: 'Perusahaan',
    icon: <FontAwesomeIcon size="lg" icon={faBuilding} />,
    url: '/corporate',
    routeKey: ROUTE_CORPORATE
  },
  {
    type: 'MAIN',
    title: 'Pengguna',
    icon: <FontAwesomeIcon size="lg" icon={faUser} />,
    url: '/user/list',
    routeKey: ROUTE_USER
  },
 
  // {
  //   type: 'MAIN',
  //   title: 'Admins',
  //   icon: <FontAwesomeIcon size="lg" icon={faUser} />,
  //   url: '/admin',
  //   routeKey: ROUTE_ADMIN
  // },
  // {
  //   type: 'MAIN',
  //   title: 'Mutasi',
  //   icon: <FontAwesomeIcon size="lg" icon={faFlag} />,
  //   url: '/mutation',
  //   routeKey: ROUTE_MUTATION
  // },
  {
    type: 'MAIN',
    title: 'Bank',
    icon: <FontAwesomeIcon size="lg" icon={faLandmark} />,
    url: '/bank',
    routeKey: ROUTE_BANKS
  },
  {
    type: 'MAIN',
    title: 'DTTOT',
    icon: <FontAwesomeIcon size="lg" icon={faMasksTheater} />,
    url: '/dttot',
    routeKey: ROUTE_DTTOT
  },
  {
    type: 'GROUP',
    title: 'Admin',
    icon: <FontAwesomeIcon size="lg" icon={faUsers} />,
    url: '/admin',
    children: [
      {
        title: 'Admin',
        icon: <FontAwesomeIcon size="lg" icon={faUser} />,
        url: '/admin',
        routeKey: ROUTE_ADMIN
      },
      {
        title: 'Persetujuan Admin',
        url: '/user/admin-approval',
        routeKey: ROUTE_ADMIN_APPROVAL,
        icon: <FontAwesomeIcon size="lg" icon={faClipboardList} />,
      }
    ]
  },

  {
    type: 'GROUP',
    title: 'Log',
    icon: <FontAwesomeIcon size="lg" icon={faBook} />,
    url: '/log',
    children: [
      {
        title: 'Audit Log',
        url: '/log/audit-log',
        routeKey: ROUTE_AUDIT_LOG,
        icon: <FontAwesomeIcon size="lg" icon={faRightLeft} />,
      },
      {
        title: 'Eror Log',
        url: '/log/error-log',
        routeKey: ROUTE_AUDIT_LOG,
        icon: <FontAwesomeIcon size="lg" icon={faRightLeft} />,
      },
    ]
  },
 
  // {
  //   type: 'GROUP',
  //   title: 'Internal Configuration',
  //   icon: <FontAwesomeIcon size="lg" icon={faGear} />,
  //   url: '/internal-configuration',
  //   children: [
  //     {
  //       title: 'Deposit Tutorials',
  //       url: '/internal-configuration/deposit-tutorials',
  //       routeKey: ROUTE_DEPOSIT_TUTORIALS,
  //       icon: <FontAwesomeIcon size="lg" icon={faBuildingColumns} />,
  //     },
  //   ]
  // },
  {
    type: 'BOTTOM',
    title: 'Keluar',
    icon: <FontAwesomeIcon size="lg" icon={faRightFromBracket} />,
    url: '/logout',
    routeKey: ROUTE_LOGOUT
  }
]

export default routes
