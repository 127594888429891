class AllStatus {
  static CREATED = 'CREATED'
  static UPDATED = 'UPDATED'
  static ACTIVED = 'ACTIVED'
  static PENDING = 'PENDING'
  static APPROVED = 'APPROVED'
  static REJECTED = 'REJECTED'
  static PASSWORD_SET = 'PASSWORD_SET'
  static VERIFIED = 'VERIFIED'
  static ACCEPTED_TNC = 'ACCEPTED_TNC'
  static DUKCAPIL_VERIFIED = 'DUKCAPIL_VERIFIED'

  static getStr(en) {
    switch (en) {
      case AllStatus.CREATED:
        return 'Created'
      case AllStatus.UPDATED:
        return 'Updated'
      case AllStatus.PENDING:
        return 'Pending'
      case AllStatus.APPROVED:
        return 'Approved'
      case AllStatus.REJECTED:
        return 'Rejected'
      case AllStatus.ACTIVED:
        return 'Aktif'
      case AllStatus.PASSWORD_SET:
        return 'Set Password'
      case AllStatus.VERIFIED:
        return 'Verified'
      case AllStatus.ACCEPTED_TNC:
        return 'S&K disetujui'
      case AllStatus.DUKCAPIL_VERIFIED:
        return 'Data diverifikasi dukcapil'
      default:
        return 'Unknown'
    }
  }

  static getStrIdn(en) {
    switch (en) {
      case AllStatus.CREATED:
        return 'Dibuat'
      case AllStatus.UPDATED:
        return 'Diperbarui'
      case AllStatus.PENDING:
        return 'Tertunda'
      case AllStatus.APPROVED:
        return 'Disetujui'
      case AllStatus.REJECTED:
        return 'Ditolak'
      case AllStatus.ACTIVED:
        return 'Aktif'
      case AllStatus.PASSWORD_SET:
        return 'Mengatur Password'
      case AllStatus.VERIFIED:
        return 'Diverifikasi'
      case AllStatus.ACCEPTED_TNC:
        return 'S&K disetujui'
      case AllStatus.DUKCAPIL_VERIFIED:
        return 'Terverifikasi Dukcapil'
      default:
        return 'Unknown'
    }
  }
}

export default AllStatus
