import React, { forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import styles from './imageField.module.scss'
import pakaiClass from 'pakai-class';
import { toAbsoluteUrl } from '../../../utils/helpers/pathHelper';
import { IMAGE_FORMATS } from '../../../utils/helpers/fileFormats';

const defaultImage = toAbsoluteUrl('/assets/media/imageField/avatar.jpg')

const readAsDataURL = (file, cb) => {
  let reader = new FileReader();
  reader.onloadend = () => {
    let res = reader.result;
    cb(res);
  };
  reader.readAsDataURL(file)
}

const ImageField = forwardRef((
  {
    // error,
    // helperText,
    name,
    className,
    previousImageUrl,
    onChange
  },
  ref
) => {
  const [file, setFile] = useState();
  const [imagePreviewUrl, setImagePreviewUrl] = useState();
  const inputImageRef = useRef();

  const selectedImage = useMemo(() => {
    if (imagePreviewUrl) return imagePreviewUrl
    if (previousImageUrl) return previousImageUrl
    return defaultImage;
  }, [imagePreviewUrl, previousImageUrl])

  const handleImageChange = useCallback((e) => {
    const file = e.target.files[0];
    if (!file || !IMAGE_FORMATS.includes(file.type)) {
      inputImageRef.current.value = "";
      return
    }

    setFile(file)
    readAsDataURL(file, base64Res => setImagePreviewUrl(base64Res));
    if (typeof onChange === 'function') onChange(file);
  }, [onChange])

  return (
    <div className={pakaiClass(
      styles.imageField,
      className
    )}>
      <div className={styles.imageWrapper}>
        <img src={selectedImage} alt={file?.name} />
      </div>
      <button type="button" className={styles.button} onClick={_ => inputImageRef?.current?.click()}>{previousImageUrl ? 'Ubah' : 'Unggah'} gambar</button>
      <input
        id={name}
        name={name}
        type="file"
        onChange={handleImageChange}
        ref={el => {
          inputImageRef.current = el;
          if (typeof ref === 'function') ref(el);
        }}
        accept="image/*"
      />
    </div>
  )
})

export default ImageField
