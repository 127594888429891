
import React from "react";
import getPropByString from "../../utils/helpers/getPropByString";

export const ExcelTable = ({ columns, items }) => {
  return (
    <table border="1">
      <thead>
        <tr style={{ textAlign: "center" }}>
          <th>#</th>
          {columns.map((col, i) => {
            return <th key={i}>{col.title}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {
          items.map((row, i) => {
            return (
              <tr key={i}>
                <td>{i + 1}</td>
                {columns.map((col, key) =>
                  <td align={col.align} key={key}>
                    {
                      typeof col.render === "function" ?
                        col.render(getPropByString(row, col.key), row)
                        :
                        getPropByString(row, col.key)
                    }
                  </td>
                )}
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}
