import pakaiClass from 'pakai-class'
import React from 'react'
import { Checked, Times } from '../../assets/icons'
import Button from '../Button'
import styles from './templates.module.scss'

const VerificationAction = ({
  onApprove = () => { },
  onReject = () => { }
}) => {
  return (
    <div className={styles.verificationAction}>
      <Button
        className={pakaiClass(styles.verifyButton, styles.verify)}
        onClick={onApprove}
      >
        <Checked />
        <span>Verifikasi</span>
      </Button>
      <Button
        className={pakaiClass(styles.verifyButton, styles.reject)}
        onClick={onReject}
      >
        <Times />
        <span>Tolak</span>
      </Button>
    </div>
  )
}

export default VerificationAction
