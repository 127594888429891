import ExportExcelToast from '../../components/templates/ExportExcelToast'
import { EXPORT_EXCEL_ALL } from '../../utils/constants/actionTypes'
// import ReactDOMServer from 'react-dom/server';
// import { ExcelTable } from '../../components/Table/ExcelTable';
import { toast } from 'react-toastify';
// import auditLogService from '../../axios/services/auditLogService';

// const base64 = (s) => window.btoa(unescape(encodeURIComponent(s)))
// const format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p])

const exportExcelMiddleware = store => next => action => {
  new Promise((resolve, reject) => {
    if (action.type === EXPORT_EXCEL_ALL) {
      // toast initial with 0 progress
      const toastId = toast(<ExportExcelToast filename={action.filename} progress={0} />, {
        progress: 0,
        autoClose: false,
        closeButton: false,
        draggable: false,
        closeOnClick: false,
        type: "info"
      })

      // iteration call api
      let items = []
      const recursiveAPICall = (currentPage, totalPage) => {
        let progress = currentPage / totalPage

        store.dispatch({
          type: action.api.service.type,
          tableConfig: { ...action.api.service.tableConfig, page: currentPage }
        })

        toast.update(toastId, {
          render: <ExportExcelToast filename={action.filename} progress={progress} />,
          progress: progress
        })

        items = [...items, ...store.getState()[action.api.apiResponseKey].items]
        let newPage = currentPage + 1

        if (currentPage < totalPage) recursiveAPICall(newPage, totalPage)
        else {
          // const html = ReactDOMServer.renderToStaticMarkup(ExcelTable({ columns: action.columns, items }))
          // const excelHref = 'data:application/vnd.ms-excel;base64,'
          // const template =
          //   '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
          //   'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
          //   'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
          //   'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
          //   '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
          //   'xml><![endif]--></head><body>{html}</body></html>';
          // const context = {
          //   worksheet: 'Worksheet',
          //   html,
          // };
          // auditLogService
          //   .create(action.filename, 'GET', 'Export Excel').finally(() => {
          //     let a = document.createElement('a')
          //     a.href = excelHref + base64(format(template, context));
          //     a.download = action.filename
          //     a.click()
          //   })
        }
      }
      recursiveAPICall(1, action.totalPage)
    } else {
      resolve(action)
      next(action)
    }
  })
}

export { exportExcelMiddleware }