import pakaiClass from 'pakai-class'
import React from 'react'
import styles from './forms.module.scss'

const FormField2 = ({
  className,
  error,
  label,
  helperText,
  children
}) => {
  return (
    <div
      className={pakaiClass(
        styles.formGroup2,
        error && styles.formGroupError,
        className
      )}
    >
      {
        label &&
        <label
          className={styles.formLabel}
        >
          {label}
        </label>
      }
      {children}
      {
        helperText &&
        <small className={styles.helperText}>{helperText}</small>
      }
    </div>
  )
}

export default FormField2
