import format from 'date-fns/format';
import pakaiClass from 'pakai-class';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Times } from '../../assets/icons';
import injectionService from '../../axios/services/injectionService';
import Button from '../../components/Button';
import StatusHistory from '../../components/StatusHistory';
import RejectDialog from '../../components/templates/RejectDialog';
import SimpleCard from '../../components/templates/SimpleCard';
import VerificationAction from '../../components/templates/VerificationAction';
import { injectionActions } from '../../redux/actions/injectionActions';
import toIDR from '../../utils/helpers/toIDR';
import styles from './injection.module.scss'

const DetailTab = ({ index }) => {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { key, item } = useSelector(state => state.injection.tabs[index]);

  const getDetail = useCallback(
    () => dispatch(injectionActions.getDetail(key, index)),
    [dispatch, key, index]
  )

  const verifyInjection = () => {
    setIsLoading(true)
    injectionService.approve(key)
      .then((res) => {
        getDetail()
      })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }

  const rejectInjection = ({ rejectReason }) => {
    setIsLoading(true)
    injectionService.reject(rejectReason, key)
      .then((res) => {
        getDetail()
        setShowDialog(false)
      })
      .catch(() => { })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    getDetail()
  }, [getDetail])

  return !item ? null
    :
    <div className="row">
      <div className="col-md-6">
        <SimpleCard className="mb-24" >
          <p className="font-500 mb-12">Detail Injection</p>
          <hr />
          <div className={styles.detail}>
            <div className={styles.field}>
              <span>Nominal</span>
              <p>{toIDR(item?.amount)}</p>
            </div>
            <div className={styles.field}>
              <span>Date</span>
              <p>{format(new Date(item?.createdAt), 'dd MMM HH:mm:ss')}</p>
            </div>
            <div className={styles.field}>
              <span>Deskripsi</span>
              <p>{item?.description ?? '-'}</p>
            </div>
            <div className={styles.field}>
              <span>Status</span>
              <p>{item?.status}</p>
            </div>
          </div>


          {
            item.status !== "APPROVED"
            &&
            <div className="mt-32">
              <p className="font-500 mb-12">Setujui Permintaan</p>
              <hr />
              {
                item.rejectRemark ?
                  <div>
                    <div className="d-flex justify-content-between flex-wrap mb-16 font-size-14">
                      <p className="mr-16 text-dark-gray">Alasan Penolakan :</p>
                      <p>{item.rejectRemark}</p>
                    </div>
                    <Button
                      disabled
                      className={pakaiClass(styles.verifyButton, styles.rejected)}
                    >
                      <Times />
                      <span>Pengajuan ditolak</span>
                    </Button>
                  </div> :
                  <VerificationAction
                    onApprove={verifyInjection}
                    onReject={() => setShowDialog(true)}
                  />
              }
              <RejectDialog
                show={showDialog}
                onClose={() => setShowDialog(false)}
                onCancel={() => setShowDialog(false)}
                onSubmit={rejectInjection}
                modalTitle="Tolak Pengajuan Perusahaan"
                loading={isLoading}
              />
            </div>
          }
        </SimpleCard>
        <SimpleCard className="mb-24" >
          <p className="font-500 mb-12">Penerima</p>
          <hr />
          <div className={styles.detail}>
            <div className={styles.field}>
              <span>Nama Perusahaan</span>
              <p>{item?.company?.name}</p>
            </div>
            <div className={styles.field}>
              <span>Kode Perusahaan</span>
              <p>{item?.company?.code}</p>
            </div>
            <div className={styles.field}>
              <span>Unique Id</span>
              <p>{item?.company?.uniqueId}</p>
            </div>
          </div>
        </SimpleCard>
      </div>
      <div className="col-md-6">
        <StatusHistory
          statusHistories={item.statusHistories}
        />
      </div>
    </div>
}

export default DetailTab
