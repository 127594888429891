import axios from "axios";
import paginate from "../../utils/helpers/paginate";

export const LIST_URL = "/admins/list";
export const CREATE_URL = "admins/create";
export const DETAIL_URL = id => `/admins/${id}/detail`;
export const UPDATE_URL = (id) => `admins/${id}/update`;
export const APPROVE_URL = id => `/users/${id}/approve-validation`;
export const REJECT_URL = id => `/users/${id}/reject-validation`;
export const VERIFY_URL = token => `/admins/verify/email/${token}`;

const adminService = {
  list: ({ page, limit, sort, search }) => {
    let url = `${LIST_URL}?${paginate(limit, page, sort)}`;
    if (search) {
      url += `&search=${search}`;
    }
    return axios.get(url)
  },
  detail: (id) => axios.get(DETAIL_URL(id)),
  create: (data) => {
    return axios.post(CREATE_URL, data)
  },
  update: (data, id) => {
    return axios.put(UPDATE_URL(id), data)
  },
  approve: (id) => axios.get(APPROVE_URL(id)),
  reject: (id, data) => axios.post(REJECT_URL(id), data),
  verify: (token) => axios.post(VERIFY_URL(token))
}

export default adminService
