import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../components/Table'
import SimpleCard from '../../components/templates/SimpleCard'
import { injectionActions } from '../../redux/actions/injectionActions'
import { format } from 'date-fns'
import toIDR from '../../utils/helpers/toIDR'
import ButtonIcon from '../../components/templates/ButtonIcon'
import { OpenInNew } from '../../assets/icons'

const MainTab = () => {
  const dispatch = useDispatch();
  const {
    items,
    tableConfig: {
      isLoading,
      page,
      totalData,
      limit,
      sort,
      filter
    }
  } = useSelector(state => state.injection);

  const load = useCallback(
    () => {
      const tConfig = {
        limit,
        page,
        sort,
        filter
      }
      return dispatch(injectionActions.loadRequested(tConfig))
    },
    [dispatch, limit, page, sort, filter],
  )

  const setTableConfig = useCallback(
    key => value => dispatch(injectionActions.setTableConfig(key, value)),
    [dispatch]
  )

  useEffect(() => {
    load()
  }, [load])



  const columns = useMemo(() => ([
    {
      title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm')
    },
    {
      title: 'Nominal', key: 'amount', render: v => toIDR(v)
    },
    {
      title: 'Tujuan', key: 'company', render: v => `${v.name} - ${v.uniqueId}`
    },
    {
      title: 'Deskripsi', key: 'description'
    },
    {
      title: 'Status', key: 'status'
    },
    {
      title: '', key: '_id', render: (v, row) => (
        <ButtonIcon
          icon={<OpenInNew />}
          onClick={() => dispatch(injectionActions.createTab(v, row._id))}
        />
      )
    }
  ]), [dispatch]);

  const excelColumns = [
    { title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm') },
    { title: 'Nominal', key: 'amount', render: v => toIDR(v) },
    { title: 'Tujuan', key: 'company', render: v => `${v.name} - ${v.uniqueId}` },
    { title: 'Deskripsi', key: 'description' },
    { title: 'Status', key: 'status' },
  ]

  return (
    <div>
      <SimpleCard>
        <Table
          data={items}
          excel={{
            columns: excelColumns,
            filename: 'Table Injection'
          }}
          onReload={load}
          onChangePage={setTableConfig('page')}
          onSort={setTableConfig('sort')}
          config={{
            loading: isLoading,
            columns,
            total: totalData,
            limit,
            sort,
            currentPage: page,
            showRender: (from, to, total) => `${from}-${to} dari ${total} Transaksi`
          }}
        >
        </Table>
      </SimpleCard>
    </div >
  )
}

export default MainTab
