import axios from "axios";
import paginate from "../../utils/helpers/paginate";

export const LIST_URL = "/dttot/list";
export const IMPORT_URL = "/dttot/upload";

const dttotService = {
  list: ({ page, limit, sort }) => {
    let url = `${LIST_URL}?${paginate(limit, page, sort)}`;
    return axios.get(url)
  },
  import: (file) => {
    let formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return axios.post(IMPORT_URL, formData, config)
  }
}

export default dttotService
