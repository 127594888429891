import React, { useState } from 'react'
import Sidebar from './Sidebar'
import styles from './layout.module.scss'
import HamburgerMenu from './HamburgerMenu'
import useWindowSize from '../components/hooks/useWindowSize'
import MainAppHeader from './MainAppHeader'

const Layout = ({ children }) => {
  const [openSidebar, setOpenSidebar] = useState(false)
  const { width } = useWindowSize()
  return (
    <div className={styles.mainLayout}>
      <Sidebar open={openSidebar} setOpen={setOpenSidebar} />
      {
        width <= 992 &&
        <HamburgerMenu active={openSidebar} onClick={() => setOpenSidebar(prev => !prev)} />
      }
      <div className={styles.mainApp}>
        <MainAppHeader />
        <div className={styles.mainAppContent}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Layout
