import { useState, useCallback, useEffect } from 'react'
import useMountedState from './useMountedState';

const useAsync = (
  service,
  immediateArgs,
  customOpts = {}
) => {
  const [options] = useState({
    resetSuccess: false,
    resetError: true,
    ...customOpts
  })
  const [parameters] = useState(immediateArgs)

  const [pending, setPending] = useState(false);
  const [value, setValue] = useState();
  const [error, setError] = useState();
  const isMounted = useMountedState();

  const execute = useCallback(
    (...args) => {
      setPending(true);
      if (options.resetSuccess) setValue(undefined);
      if (options.resetError) setError(undefined);

      return service(...args)
        .then(res => {
          if (isMounted()) {
            setValue(res);
            return res
          }
        })
        .catch(err => {
          if (isMounted()) {
            setError(err);
            throw err;
          }
        })
        .finally(() => {
          if (isMounted()) setPending(false);
        })
    },
    [service, isMounted, options],
  )

  useEffect(() => {
    if (parameters) execute(parameters);
  }, [execute, parameters])

  return {
    execute,
    pending,
    value,
    error
  }
}

export default useAsync
