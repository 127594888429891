import axios from "axios";
import paginate from "../../utils/helpers/paginate";

export const ORDER_LIST_URL = "orders/list-root";
export const DETAIL_URL = id => `/transactions/${id}/list`;

const orderService = {
  list: ({ page, limit, sort, search, companyId }) => {
    let url = `${ORDER_LIST_URL}?${paginate(limit, page, sort)}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (companyId) {
      url += `&companyId=${companyId}`;
    }
    return axios.get(url)
  },
  detail: (id) => axios.get(DETAIL_URL(id))
}

export default orderService
