class TransactionTypes {
  static ORDER = "ORDER";
  static TOPUP = "TOPUP";
  static INJECTION = "INJECTION";
  static DEDUCTION = "DEDUCTION";

  static getStr(en) {
    switch (en) {
      case TransactionTypes.ORDER:
        return 'Order';
      case TransactionTypes.TOPUP:
        return 'Top Up';
      case TransactionTypes.INJECTION:
        return 'Injection';
      case TransactionTypes.DEDUCTION:
        return 'Deduction';
      default:
        return 'Unknown';
    }
  }
}

const TransactionOpts = [
  {
    value: TransactionTypes.ORDER, label: TransactionTypes.getStr(TransactionTypes.ORDER)
  },
  {
    value: TransactionTypes.TOPUP, label: TransactionTypes.getStr(TransactionTypes.TOPUP)
  },
  {
    value: TransactionTypes.INJECTION, label: TransactionTypes.getStr(TransactionTypes.INJECTION)
  },
  {
    value: TransactionTypes.DEDUCTION, label: TransactionTypes.getStr(TransactionTypes.DEDUCTION)
  }
];

export {
  TransactionTypes, TransactionOpts
};
