import { MenuBars, MenuList } from "../../../components/MenuBars"
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { corporateActions } from '../../../redux/actions/corporateActions';
import BasicInfo from "./BasicInfo";
import Statuses from "./Statuses";
import Supervisor from "./Supervisor";
import UserList from "./UserList";

const DetailTab = ({
  index
}) => {
  const dispatch = useDispatch();
  const { key, item } = useSelector(state => state.corporate.tabs[index]);

  const getDetail = useCallback(
    () => dispatch(corporateActions.getDetail(key, index)),
    [dispatch, key, index]
  )

  useEffect(() => {
    getDetail()
  }, [getDetail]);

  return !item ? null
    :
    <div className="mt-16">
      <MenuBars>
        <MenuList
          label="Informasi Manajer"
        >
          <Supervisor
            item={item}
            getDetail={getDetail}
            index={key} 
          />
        </MenuList>
        
        <MenuList
          label="Informasi Perusahaan"
          disable={!item?.user?.identificationArchive?.idCard?.verifiedAt}
        >
          <BasicInfo item={item} index={key}  getDetail={getDetail}/>
        </MenuList>
        {/* <MenuList
          label="Status"
        >
          <Statuses
            index={key}
            item={item}
            getDetail={getDetail}
          />
        </MenuList> */}
      
        <MenuList
          label="Daftar Pengguna"
          disable={!item.company.status.verifiedAt}
        >
          <UserList
            index={key}
            item={item}
          />
        </MenuList>
      </MenuBars>
    </div>
}

export default DetailTab
