export default function jsonToFormData(inJSON, inFormData, parentKey) {
  // http://stackoverflow.com/a/22783314/260665
  // Raj: Converts any nested JSON to formData.
  var formData = inFormData || new FormData();
  for (var key in inJSON) {
    // 1. If it is a recursion, then key has to be constructed like "parent[child]" where parent JSON contains a child JSON
    // 2. Perform append data only if the value for key is not a JSON, recurse otherwise!
    var constructedKey = key;
    if (parentKey) {
      constructedKey = `${parentKey}[${key}]`;
      // constructedKey = parentKey + "[" + key + "]";
    }

    var value = inJSON[key];
    if (value && (value.constructor === {}.constructor || value.constructor === [].constructor)) {
      // This is a JSON, we now need to recurse!
      jsonToFormData(value, formData, constructedKey);
    } else {
      formData.append(constructedKey, inJSON[key]);
    }
  }
  return formData;
}
