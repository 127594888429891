import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../components/Table'
import SimpleCard from '../../components/templates/SimpleCard'
import { dttotActions } from '../../redux/actions/dttotActions'
import { format } from 'date-fns'
import dttotService from '../../axios/services/dttotService'
import * as yup from 'yup';
import styles from './dttot.module.scss'

const schema = yup.mixed().test(
  "fileFormat",
  "Unsupported Format",
  value => value ? value.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : true
);

const MainTab = () => {
  const [isUploading, setIsUploading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const fileInputRef = useRef()
  const dispatch = useDispatch();
  const {
    items,
    tableConfig: {
      isLoading,
      page,
      totalData,
      limit,
      sort,
      filter
    }
  } = useSelector(state => state.dttot);

  const load = useCallback(
    () => {
      const tConfig = {
        limit,
        page,
        sort,
        filter
      }
      return dispatch(dttotActions.loadRequested(tConfig))
    },
    [dispatch, limit, page, sort, filter],
  )

  const setTableConfig = useCallback(
    key => value => dispatch(dttotActions.setTableConfig(key, value)),
    [dispatch]
  )

  useEffect(() => {
    load()
  }, [load])



  const columns = useMemo(() => ([
    {
      title: 'Waktu Dibuat', key: 'createdAt', render: v => format(new Date(v), 'd MMM yyyy, HH:mm')
    },
    {
      title: 'Nama', key: 'name'
    },
    {
      title: 'Kewarganegaraan', key: 'nationality', render: v => v ? v : "-"
    },
    {
      title: 'Tempat Lahir', key: 'birthPlace', render: v => v ? v : "-"
    },
    {
      title: 'Tanggal Lahir', key: 'birtDate', render: v => v ? v : "-"
    },
    {
      title: 'Alamat Sekarang', key: 'address'
    },
    {
      title: 'Densus Kode', key: 'densusCode', render: v => <b><i>{v}</i></b>
    },
    {
      title: 'Deskripsi', key: 'description'
    },
  ]), []);

  const handleFileChange = e => {
    e.preventDefault();
    let file = e.target.files[0];

    if (file) {
      schema.validate(file).then(file => {
        setErrorMessage(null)
        setIsUploading(true)
        dttotService.import(file)
          .then(() => {
            load()
          })
          .catch(() => { })
          .finally(() => {
            setIsUploading(false)
          });
      }).catch(err => {
        if (err && err.name === 'ValidationError') setErrorMessage(err.message)
      });
    }
  }

  const showFileUpload = () => { if (fileInputRef) fileInputRef.current.click() };

  return (
    <div>
      <SimpleCard>
        <div className={styles.import}>
          <button
            className={styles.importBtn}
            onClick={showFileUpload}
          >
            <i>Import</i> Excel Dari PPATK
          </button>
          {!isUploading &&
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              style={{ display: 'none' }}
            />
          }
          <span className={styles.error}>{errorMessage}</span>
        </div>
        <Table
          data={items}
          onChangePage={setTableConfig('page')}
          onSort={setTableConfig('sort')}
          config={{
            loading: isLoading,
            columns,
            total: totalData,
            limit,
            sort,
            currentPage: page,
            showRender: (from, to, total) => `${from}-${to} dari ${total} Transaksi`
          }}
        >
        </Table>
      </SimpleCard>
    </div >
  )
}

export default MainTab
