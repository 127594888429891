import React, { useState } from 'react'
import LoadingDots from '../../../components/Loadings/LoadingDots'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import MainButton from '../../../components/templates/MainButton';
import authStyles from '../auth.module.scss'
import TextField from '../../../components/Forms/TextField';
import authService from '../../../axios/services/authService';
import { authActions } from '../../../redux/actions/authActions';
import { useDispatch } from "react-redux";
import ForgotPassword from '../ForgotPassword'
// import { toast } from 'react-toastify';

const schema = yup.object().shape({
  email: yup.string().required('Email tidak boleh kosong'),
  password: yup.string().required('Password tidak boleh kosong'),
})

const Login = () => {
  const dispatch = useDispatch()
  const [showPopUpForgotPassword, setShowPopUpForgotPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      // email: 'rooten@dipay.id',
      // password: 'rooten',
    }
  });

  const onSubmit = (values) => {
    setLoading(true)
    authService
      .login(values)
      .then(({ data: { admin, accessToken, refreshToken } }) => {
        dispatch(authActions.login(admin, accessToken, refreshToken));
      })
      .catch(({ response: { data } }) => {
        setLoading(false)
      })
  }

  return (
    <>
      <div>
        <h1 className="mb-16">Selamat datang di Piro</h1>
        <p className="text-dark-gray mb-48">Nikmati kemudahan manajemen transfer untuk bisnis Anda</p>
        <div className={authStyles.formWrapper}>
          <form onSubmit={handleSubmit(onSubmit)} className={authStyles.form}>
            <TextField
              className="mb-24"
              placeholder="e.g example@mail.com"
              ref={register}
              name="email"
              label="Email"
              error={errors.email?.message}
              helperText={errors.email?.message}
            />
            <TextField
              password
              className="mb-48"
              placeholder="e.g 123xxx"
              ref={register}
              name="password"
              label="Kata Sandi"
              error={errors.password?.message}
              helperText={errors.password?.message}
            />
            <MainButton
              type="submit"
              className="mb-32"
            >
              Masuk
            </MainButton>
          </form>
        </div>
        <button
          onClick={() => setShowPopUpForgotPassword(!showPopUpForgotPassword)}
          className="text-primary font-size-14 font-500 mb-32"
        >
          Lupa Kata Sandi?
        </button>
        <ForgotPassword
          show={showPopUpForgotPassword}
          setShow={setShowPopUpForgotPassword}
        />
      </div>
      {
        loading &&
        <LoadingDots className={authStyles.loadingRoot} />
      }
    </>
  )
}

export default Login
