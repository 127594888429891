import React, { useEffect, useCallback, useRef } from 'react';
import styles from './modal.module.scss'
import ReactDOM from 'react-dom'
import { Transition } from 'react-transition-group';
import pakaiClass from 'pakai-class';
import { Close } from '../../assets/icons';

export const ModalHead = ({
  title,
  className,
  onClose
}) => {
  return (
    <div className={pakaiClass(styles.modalHead, className)}>
      <div className={styles.wrapper}>
        <p>{title}</p>
        <button onClick={onClose}>
          <Close />
        </button>
      </div>
    </div>
  )
}

export const ModalBody = ({
  children,
  className
}) => {
  return (
    <div className={pakaiClass(styles.ModalBody, className)}>
      {children}
    </div>
  )
}

export const ModalWrapper = ({ children, className }) => {
  return (
    <div className={pakaiClass(styles.modalWrapper, className)}>
      {children}
    </div>
  )
}

const duration = 300

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  visibility: 'hidden'
}

const transitionStyles = {
  entering: { opacity: 1, visibility: 'visible' },
  entered: { opacity: 1, visibility: 'visible' },
  exiting: { opacity: 0, visibility: 'visible' },
  exited: { opacity: 0, visibility: 'hidden' },
};

const Modal = ({
  onClose,
  in: inProp,
  children
}) => {
  const nodeRef = useRef();
  const body = document.body

  const handleClose = useCallback(() => {
    if (typeof onClose === 'function') onClose();
  }, [onClose])

  const onEscKeyDown = useCallback(e => {
    if (e.key !== "Escape") return;
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (inProp) window.addEventListener("keydown", onEscKeyDown, false);
    return () => {
      if (inProp) window.removeEventListener("keydown", onEscKeyDown, false);
    }
  }, [inProp, onEscKeyDown])

  return ReactDOM.createPortal(
    <Transition nodeRef={nodeRef} in={inProp} timeout={duration} unmountOnExit={true}>
      {state => (
        <div ref={nodeRef} className={styles.modal} style={{
          ...defaultStyle,
          ...transitionStyles[state]
        }}>
          {children}
          <div className={styles.backdrop} onClick={handleClose}></div>
        </div >
      )}
    </Transition>,
    body
  )
}

export default Modal
