import { passwordRegex, phoneNumberRegex } from '../../utils/constants/regexTypes';
import * as yup from 'yup';

export const schemaCreate = yup.object().shape({
  fullName: yup.string().required('Nama Lengkap tidak boleh kosong'),
  phoneNumber: yup.string().matches(phoneNumberRegex, 'Nomor Handphone tidak valid').required('Nomor Handphone tidak boleh kosong'),
  email: yup.string().required('Email tidak boleh kosong'),
  role: yup.string().required()
})

export const schemaUpdate = yup.object().shape({
  fullName: yup.string().required('Nama Lengkap tidak boleh kosong'),
  phoneNumber: yup.string().matches(phoneNumberRegex, 'Nomor Handphone tidak valid').required('Nomor Handphone tidak boleh kosong'),
  email: yup.string().required('Email tidak boleh kosong'),
  role: yup.string().required(),
})

export const schemaChangePassword = yup.object().shape({
  password: yup.string().matches(passwordRegex, 'Kata Sandi harus mengandung angka, huruf besar, huruf kecil, dan karakter spesial').required('Kata sandi baru tidak boleh kosong'),
  confirmPassword: yup.string().when('password', {
    is: value => value && value.length > 0,
    then: yup.string()
      .required('Konfirmasi kata sandi.')
      .oneOf([yup.ref('password'), null], 'Kata sandi harus sesuai.'),
    otherwise: yup.string()
  }),
})