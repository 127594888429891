import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '../../components/Tabs/Tabs';
import { userActions } from '../../redux/actions/userActions';
import DetailTab from './DetailTab';
import MainTab from './MainTab';
import { useHistory, useLocation } from 'react-router-dom'

const UserPage = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const history = useHistory()
  const { activeTabKey, tabs } = useSelector(state => state.user);
 
  return (
    <div>
      <Tabs
        activeKey={activeTabKey}
        onClick={key => {
          dispatch(userActions.changeTab(key))
          history.replace({
            search: "",
          })
        }}
      >
        <Tab title="Pengguna">
          <MainTab />
        </Tab>
        {tabs.map((row, key) =>
          <Tab
            key={row.key}
            title={row.title}
            isLoading={row.loading}
            onClose={() => {
              dispatch(userActions.closeTab(row.key))
              history.replace({
                search: "",
              })
            }}
            onClick={() => {
              dispatch(userActions.changeTab(row.key))
              history.replace({
                search: "",
              })
            }}
            active={activeTabKey === row.key}
          >
            <DetailTab index={key} />
          </Tab>
        )}
      </Tabs>
    </div>
  )
}

export default UserPage
