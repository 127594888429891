import pakaiClass from 'pakai-class';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { ChevronDown, Close } from '../../../assets/icons';
import FormField from '../FormField';
import FormField2 from '../FormField2';
import styles from './selectField.module.scss'

const SelectField = ({
  className,
  label,
  error,
  helperText,
  onChange,
  onFocus,
  onBlur,
  options = [],
  value: simpleValue,
  isMulti = false,
  getOptionValue = opt => opt.value,
  isLoading,
  componentProps,
  variant,
  placeholder,
  isClearable,
  isDisabled
}) => {
  const value = getValue(options, simpleValue, getOptionValue, isMulti);
  const [focus, setFocus] = useState(false)
  const [hasValue, setHasValue] = useState(false)

  const FormFieldTag = variant === 'outlined' ? FormField2 : FormField

  return (
    <FormFieldTag
      className={className}
      label={label}
      error={error}
      helperText={helperText}
      shrink={hasValue ? false : !focus}
      animate
    >
      <Select
        {...componentProps}
        components={{
          Control,
          ValueContainer,
          IndicatorsContainer: () => null,
          Placeholder: () => null,
          Input,
          Menu
        }}
        isDisabled={isDisabled}
        isMulti={isMulti}
        value={value}
        options={options}
        onChange={onChange}
        onBlur={(e) => {
          setFocus(false)
          if (typeof onBlur === 'function') onBlur(e)
        }}
        onFocus={e => {
          setFocus(true)
          if (typeof onFocus === 'function') onFocus(e)
        }}
        className={pakaiClass(styles.customSelect, variant === 'outlined' && styles.outlined)}
        setHasValue={setHasValue}
        error={error}
        isLoading={isLoading}
        placeholder={placeholder}
        variant={variant}
        isClearable={isClearable}
      />
    </FormFieldTag>
  )
}

const Control = (props) => {
  const { children, selectProps: { isClearable }, hasValue, clearValue } = props;
  const handleClickClose = (e) => {
    e.stopPropagation();
    clearValue()
  }
  return (
    <components.Control
      {...props}
      className={pakaiClass(styles.selectControl, (isClearable && hasValue) && styles.isClearable)}
    >
      <>
        {children}
        {
          (isClearable && hasValue) &&
          <button type="button" onClick={handleClickClose} className={styles.closeIcon}>
            <Close />
          </button>
        }
        <div className={styles.arrowDown}>
          <ChevronDown size={24} />
        </div>
      </>
    </components.Control>
  )
};

const Menu = (props) => {
  const { children } = props;
  return (
    <components.Menu
      {...props}
      className={styles.customMenu}
    >
      {children}
    </components.Menu>
  )
};

const ValueContainer = (props) => {
  const {
    children,
    getValue,
    hasValue,
    selectProps: { setHasValue, error, placeholder, variant }
  } = props;
  useEffect(() => setHasValue(hasValue))
  return (
    <components.ValueContainer {...props} className={pakaiClass(styles.selectValueContainer, error && styles.error)}>
      <>
        {
          Boolean(children[0]) &&
          <p className={pakaiClass(!hasValue && styles.placeholder)}>{hasValue ? getValue()[0].label : (variant ? placeholder : null)}</p>
        }
        {
          children[1]
        }
      </>
    </components.ValueContainer>
  )
}

const Input = (props) => (
  <components.Input {...props} className={styles.selectInputWrapper}>
  </components.Input>
);

// Method for Custom Select
function flatten(arr) {
  return arr.reduce((acc, val) => (Array.isArray(val.options)
    ? acc.concat(flatten(val.options))
    : acc.concat(val)
  ), []);
}

function getValue(opts, val, getOptVal, isMulti) {
  if (val === undefined) return undefined;

  const options = flatten(opts);
  const value = isMulti
    ? options.filter(o => val.includes(getOptVal(o)))
    : options.find(o => getOptVal(o) === val);

  return value;
}

export default SelectField;
