import React, { memo, useEffect, useCallback, useState } from 'react'
import ReactDOMServer from 'react-dom/server';
import styles from './table.module.scss';
import Show from './Show';
import Pagination from './Pagination';
import Data from './Data';
import pakaiClass from 'pakai-class'
import LoadingBar from '../Loadings/LoadingBar';
import Tooltip from '../Tooltip'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import PopUp from '../PopUp';
import SimpleCard from '../templates/SimpleCard';
import MainButton from '../templates/MainButton';
import { ExcelTable } from './ExcelTable';
import Button from '../Button';
import Th from './Th';
import { useDispatch } from 'react-redux';
import { EXPORT_EXCEL_ALL } from '../../utils/constants/actionTypes';
import Reload from './Reload';
// import { EXPORT_EXCEL_ALL } from '../../utils/constants/actionTypes';

const base64 = (s) => window.btoa(unescape(encodeURIComponent(s)))
const format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p])

const Table = memo(({
  data,
  excel,
  onReload,
  renderTotal,
  config: {
    columns,
    withIndex,
    total,
    limit,
    currentPage,
    showRender,
    pagination = true,
    loading,
    sort,
    collapse
  },
  onSort,
  onChangePage = function () { },
  onLimit,
  children
}) => {
  const dispatch = useDispatch()
  const [showExcelAction, setShowExcelAction] = useState(false);
  const [modTableData, setModTableData] = useState([]);

  const exportExcel = useCallback(({ allData, exportType, index } = {}) => {
    const generatedTable = ExcelTable({ columns: excel.columns, items: data })
    const html = ReactDOMServer.renderToStaticMarkup(generatedTable);

    const excelHref = 'data:application/vnd.ms-excel;base64,';
    const filename = ((Math.ceil(total / limit) > 1) ? `${excel?.filename}(${currentPage} of ${Math.ceil(total / limit)})` : excel?.filename) + '.xls'
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
      'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
      'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
      'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
      '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
      'xml><![endif]--></head><body>{html}</body></html>';
    const context = {
      worksheet: 'Worksheet',
      html,
    };

    if (allData || exportType === "allPage") {
      const totalPage = Math.ceil(total / limit);
      dispatch({
        type: EXPORT_EXCEL_ALL,
        columns: excel.columns,
        totalPage: totalPage,
        filename: excel.filename,
        limit: limit,
        api: {
          apiResponseKey: excel.api.apiResponseKey,
          service: excel.api.service,
          queryParams: excel.api.queryParams
        },
      })
      setShowExcelAction(false)
    } else if (!allData || exportType === "currentPage") {
      let a = document.createElement('a')
      a.href = excelHref + base64(format(template, context));
      a.download = filename
      if (a.download.split(".").filter(el => el.includes("xls")).length > 1) {
        a.download = a.download.replace(".xls", "")
      }
      a.click()
      setShowExcelAction(false)
    }
  }, [data, excel, currentPage, limit, total, dispatch]);

  useEffect(() => {
    if (collapse) {
      setModTableData(data.map((row, i) => {
        return {
          ...row,
          collapseKey: `${currentPage}${i}`
        }
      }))
    } else {
      setModTableData(data)
    }
  }, [data, collapse, currentPage])

  return (
    <div>
      <div className={styles.topControl}>
        {
          children &&
          <div className={styles.filterWrapper}>
            {children}
          </div>
        }
      </div>
      <div className={styles.topBottomControl}>
        {
          onReload &&
          <Reload onReload={onReload} />
        }
        {
          excel &&
          <div>
            <Tooltip
              content="Download Excel"
              direction="bottom"
            >
              <button
                onClick={() => setShowExcelAction(true)}
                className={styles.excel}
              >
                <FontAwesomeIcon icon={faFileExcel} />
              </button>
            </Tooltip>
            <PopUp
              in={showExcelAction}
              onClose={() => setShowExcelAction(false)}
            >
              <SimpleCard>
                <div className='mb-12'>
                  <p className='font-700'>Export Excel</p>
                </div>
                <div className='mt-32'>
                  {/* <Button onClick={() => exportExcel({ allData: true })} className="mt-8 bg-primary text-white p-12 w-100 rounded-sm font-700 font-size-14">All Page</Button> */}
                  <Button onClick={() => exportExcel({ allData: false })} className="mt-8 bg-gray text-white p-12 w-100 rounded-sm font-700 font-size-14">Current Page</Button>
                </div>
                <div className="card-footer mt-32">
                  <MainButton
                    color='secondary'
                    onClick={() => setShowExcelAction(false)}
                    className="mt-32"
                  >
                    Close
                  </MainButton>
                </div>
              </SimpleCard>
            </PopUp>
          </div>
        }
      </div>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              {
                collapse && modTableData.length > 0
                  ? <th></th>
                  : null
              }
              {
                withIndex &&
                <th>No</th>
              }
              {
                columns.map((col, key) =>
                  <Th
                    key={key}
                    className={pakaiClass(col.align && styles[col.align])}
                    col={col}
                    sort={sort}
                    onSort={onSort}
                  />
                )
              }
            </tr>
            {
              loading &&
              <tr className={styles.loadingRow}>
                <td colSpan={columns.length}>
                  <LoadingBar position="bottom" />
                </td>
              </tr>
            }
          </thead>
          <tbody>
            <Data
              data={modTableData}
              columns={columns}
              withIndex={withIndex}
              collapse={collapse}
              currentPage={currentPage}
            />
          </tbody>
        </table>
      </div>
      {
        typeof renderTotal === 'function' && renderTotal()
      }
      {
        pagination &&
        <div className={styles.bottomControl}>
          <Show
            total={total}
            limit={limit}
            onLimit={onLimit}
            currentPage={currentPage}
            showRender={showRender}
          />
          <Pagination
            total={total}
            limit={limit}
            currentPage={currentPage}
            onChangePage={onChangePage}
          />
        </div>
      }
    </div>
  )
})

export default Table
