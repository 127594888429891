import React, { memo } from 'react'
import styles from './forms.module.scss'
import pakaiClass from 'pakai-class'
import camelCaseToTitleCase from '../../utils/helpers/camelCaseToTitle'
import LoadingDots from '../Loadings/LoadingDots'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper';

const FormField = memo(({
  id,
  className,
  label,
  children,
  error,
  helperText,
  shrink,
  animate,
  isPassword,
  onClickLabel = () => { },
  disabled,
  loading,
  tooltip,
  showTooltip,
  popperPlacement
}) => {
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: popperPlacement ?? 'right-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 16],
        },
      },
    ]
  });

  return (
    <div
      className={pakaiClass(
        styles.formGroup,
        error && styles.formGroupError,
        isPassword && styles.formGroupPassword,
        disabled && styles.disabled,
        className
      )}
      ref={setReferenceElement}
    >
      <div className={styles.formGroupWrapper}>
        {
          label &&
          <label
            onClick={onClickLabel}
            className={pakaiClass(
              styles.formLabel,
              animate && styles.animate,
              shrink && styles.shrink,
              'ellipsis-text'
            )}
            htmlFor={id}
          >
            {label}
          </label>
        }
        {children}
        {
          helperText &&
          <small className={styles.helperText}>{camelCaseToTitleCase(helperText)}</small>
        }
        {
          loading &&
          <LoadingDots className={styles.loading} size="10" />
        }
      </div>
      {
        tooltip &&
        ReactDOM.createPortal(
          <div
            className={pakaiClass(styles.tooltip, showTooltip && styles.showTooltip)}
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}
          >
            {tooltip}
          </div>,
          document.querySelector('body')
        )
      }
    </div>
  )
})

export default FormField
