import React from 'react'
import styles from './layout.module.scss'

const HamburgerMenu = ({ active, onClick }) => (
  <button onClick={onClick} className={styles.hamburgerWrapper}>
    <div className={`${styles.hamburgerMenu}${active ? ' ' + styles.active : ''}`}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </button>
)

export default HamburgerMenu
