import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import routes from '../Routes'
import styles from './layout.module.scss'

const MainAppHeader = () => {
  const { pathname } = useLocation()

  const renderView = useMemo(() => {
    const route = routes.find(el => el.url === pathname)
    const routeChildren = routes.find(el => el.url === `/${pathname.split('/')[1]}`)?.children?.find(el => el.url === pathname)

    return route || routeChildren ?
      <div className={styles.mainAppHeader}>
        <div className={styles.icon}>
          {route?.icon || routeChildren?.icon}
          {/* {cloneElement(route?.icon || routeChildren?.icon)} */}
        </div>
        <div>
          <h1>{route?.title || routeChildren?.title}</h1>
        </div>
      </div>
      :
      null
  }, [pathname])

  return renderView
}

export default MainAppHeader
