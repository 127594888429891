// import React, { useEffect, useMemo, useState } from 'react'
import TextField from '../../../components/Forms/TextField'
import { IMAGE_FORMATS } from '../../../utils/helpers/fileFormats';
import * as yup from 'yup';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './depositTutorials.module.scss'
import MainButton from '../../../components/templates/MainButton';
import ImageField from '../../../components/Forms/ImageField';
// import depositTutorialsService from '../../../axios/services/depositTutorialsService';
import Button from '../../../components/Button';
import pakaiClass from 'pakai-class';

const tutorialsInitialValue = {
  name: '',
  step: ['']
}

const schema = yup.object().shape({
  logo: yup.mixed()
    .test(
      "fileFormat",
      "Unsupported Format",
      value => value?.[0] ? IMAGE_FORMATS.includes(value[0].type) : true
    ),
  name: yup.string().required('Nama Bank tidak boleh kosong'),
  minimum: yup.number().typeError('Harus berupa angka').required('Minimum deposit tidak boleh kosong'),
  prefix: yup.number().typeError('Harus berupa angka').required('Nomor Virual Akun tidak boleh kosong'),
  fee: yup.number().typeError('Harus berupa angka').required('Fee tidak boleh kosong'),
  tutorials: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Nama Metode tidak boleh kosong'),
      step: yup.array()
    })
  )
})

const Form = ({ onSubmit, initialValue, previousImageUrl, bankId }) => {
  const { register, handleSubmit, errors, control, watch, setValue, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      tutorials: [tutorialsInitialValue],
      initialValue
    },
  });

  const {
    name,
    minimum,
    prefix,
    fee,
    tutorials,
  } = watch(["name", "minimum", "prefix", "fee", "tutorials"])

  const { fields, remove, append, insert } = useFieldArray(
    {
      control,
      name: "tutorials"
    }
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row mb-12">
        <div className="col-md-3">
          <h4>Infomasi Dasar</h4>
        </div>
        <div className="col-md-9">
          <ImageField
            ref={register}
            className="mb-24"
            name="logo"
            previousImageUrl={previousImageUrl}
          />
          <br />
          <TextField
            label="Nama Bank"
            ref={register}
            name="name"
            error={errors.name?.message}
            helperText={errors.name?.message}
          />
          <br />
          <TextField
            label="Minimum Deposit"
            ref={register}
            name="minimum"
            error={errors.minimum?.message}
            helperText={errors.minimum?.message}
          />
          <br />
          <TextField
            label="Nomor Virtual Akun"
            ref={register}
            name="prefix"
            error={errors.prefix?.message}
            helperText={errors.prefix?.message}
          />
          <br />
          <TextField
            className="mb-24"
            label="Biaya"
            ref={register}
            name="fee"
            error={errors.fee?.message}
            helperText={errors.fee?.message}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <h4>Metode Deposit</h4>
        </div>
        <div className="col-md-9">
          {
            fields.map((metode, index) => (
              <div key={metode?.id} className={styles.metode}>
                <TextField
                  className="mb-12"
                  label="Nama Metode"
                  ref={register}
                  name={`tutorials[${index}].name`}
                  error={errors.tutorials?.[index]?.name?.message}
                  helperText={errors.tutorials?.[index]?.name?.message}
                />
                {
                  fields[index]?.step.map((val, k) => (
                    <div key={k} className="row">
                      <div className='col-md-9'>
                        <div className={styles.stepInput}>
                          <div className={styles.number}>
                            {k + 1}
                          </div>
                          <input
                            className={styles.input}
                            type="text"
                            placeholder="Tahap"
                            ref={register}
                            name={`tutorials[${index}].step[${k}]`}
                          />
                        </div>
                      </div>
                      <div className={pakaiClass(styles.stepButton, 'col-md-3')}>
                        {
                          metode.step.length > 1 &&
                          <Button
                            type="button"
                            className={styles.remove}
                            // onClick={() => fields[index].step.splice(k, 1)}
                            onClick={() => {
                              setValue(`tutorials[${index}]`, { step: fields[index].step.splice(k) })
                            }}
                          >
                            Hapus
                            {k}
                          </Button>
                        }
                      </div>
                    </div>
                  ))
                }
                <div>
                  <Button
                    type="button"
                    className={styles.addStep}
                    onClick={() => {
                      setValue(`tutorials[${index}]`, { step: fields[index].step.push('') })
                    }}
                  >
                    Tambah Tahap
                  </Button>
                </div>
                {
                  index >= 1 &&
                  <div className={styles.removeMetode}>
                    <Button onClick={() => remove(index)}>
                      Hapus Metode {index}
                    </Button>
                  </div>
                }
              </div>
            ))
          }
        </div>
      </div>
      <div className={styles.addMetode}>
        <Button type="button" onClick={() => append({ name: '', step: [] })}>
          + Tambah Metode
        </Button>
      </div>
      <div className={styles.btnSubmit}>
        <MainButton
          type="submit"
          disabled={!(name && minimum && tutorials[0].name && tutorials[0].step[0] && fee && prefix)}
        >
          {bankId ? 'Edit' : 'Buat'}
        </MainButton>
      </div>
    </form>
  )
}

export default Form