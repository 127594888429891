import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import deductionService from '../../axios/services/deductionService';
import SimpleCard from '../../components/templates/SimpleCard';
import { deductionActions } from '../../redux/actions/deductionActions';
import Form from './Form';


const CreateTab = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = (values) => {
    setIsLoading(true)
    deductionService.create(values)
      .then(() => dispatch(deductionActions.changeTab(0)))
      .catch(() => setIsLoading(false))
  }

  return (
    <SimpleCard loading={isLoading}>
      <p className="font-500 mb-12">Buat Deduction</p>
      <hr />
      <Form onSubmit={onSubmit} />
    </SimpleCard>
  )
}

export default CreateTab
