import axios from "axios";
import paginate from "../../utils/helpers/paginate";

export const LIST_URL = "/audit/list";
export const LIST_URL_ERROR = "/audit/list-error";
export const CREATE_URL = "/audit/create";

const auditLogService = {
  list: ({ page, limit, sort, fromDate, toDate }) => {
    let url = `${LIST_URL}?${paginate(limit, page, sort)}`;
    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    return axios.get(url)
  },
  listError: ({ page, limit, sort, fromDate, toDate }) => {
    let url = `${LIST_URL_ERROR}?${paginate(limit, page, sort)}`;
    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    return axios.get(url)
  }
}
export default auditLogService
