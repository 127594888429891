import pakaiClass from 'pakai-class'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import Ripple from '../Ripple'
import styles from './button.module.scss'

const Button = ({
  children,
  className,
  href,
  onClick,
  ...props
}) => {
  const rippleRef = useRef()
  const ActionTag = href ? Link : 'button';

  const addRipple = (e) => {
    if (rippleRef.current) rippleRef.current.addRipple(e)
    if (typeof onClick === 'function') onClick(e)
  }

  return (
    <ActionTag
      {...props}
      className={pakaiClass(
        styles.buttonRoot,
        className
      )}
      onClick={addRipple}
      to={href}
    >
      {children}
      <Ripple ref={rippleRef} />
    </ActionTag>
  )
}

export default Button
