import React from "react";
import { Redirect, Route, Switch } from "react-router";
import styles from './layout/layout.module.scss'
import Login from "./views/Auth/Login";
import NewPassword from "./views/Auth/NewPassword";

export default function AuthLayout() {
  return (
    <div className={styles.authLayout}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/admin/new-password" component={NewPassword} />
        <Redirect to="/login" />
      </Switch>
    </div>
  );
}
