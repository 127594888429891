export const setActiveTabKey = (active, length, targetKey, staticTabLength) => {
  const target = targetKey + staticTabLength;
  const l = length + staticTabLength;
  if (l === staticTabLength) return 0
  if (active > target) {
    return active - 1
  } else if (active === target && active === l) {
    return l - 1
  } else {
    return active
  }
}

export const closeTab = (tabs, key, currentActiveKey) => {
  const newTabs = tabs.filter(row => row.key !== key);
  const activeIndex = tabs.findIndex(row => row.key === currentActiveKey);
  const targetIndex = tabs.findIndex(row => row.key === key);

  let nextKey = currentActiveKey;
  if (newTabs.length === 0) {
    nextKey = 0;
  } else if (activeIndex === targetIndex) {
    nextKey = newTabs[targetIndex]?.key ?? newTabs[targetIndex - 1]?.key
  }

  return {
    newTabs,
    nextKey
  }
}

export const createTab = (tabs, key, title, restData = {}) => {
  const newTabs = [...tabs];
  const existTab = newTabs.findIndex(row => row.key === key);
  if (existTab > -1) {
    newTabs[existTab] = {
      ...newTabs[existTab],
      title: title
    }
  } else {
    newTabs.push({
      loading: false,
      key: key,
      title: title,
      ...restData
    })
  }

  return newTabs
}
