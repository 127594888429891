import React from 'react'
import SimpleCard from '../SimpleCard'
import styles from './history.module.scss'

export const StatusHistoryCard = ({ title, children, className, withFooter }) => (
  <SimpleCard className={`mb-24 ${className ? + ' ' + className : ''}`}>
    <div className='mb-12'>
      <p className='font-700'>{title}</p>
    </div>
    <br />
    <div className={styles.historyBody}>
      <div className={styles.historyContainer}>
        {
          children?.length ?
            <div className={styles.timeline}>
              {children}
            </div>
            :
            <div className="text-center py-3">
              No Data
            </div>
        }
      </div>
    </div>
    {
      withFooter && <div className="card-footer mt-32">
        {withFooter}
      </div>
    }
  </SimpleCard>
)
